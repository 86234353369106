<template>
  <new-bs-box
    :bs="{
      marginBottom: '0px',
      justifyContent: 'flex-start',
      display: 'flex',
      alignItems: 'center',
    }"
  >
    <new-bs-box
      :bs="{ mb: 4, fontSize: 12, marginLeft: '10px', marginTop: '15px' }"
    >
      <span
        style="font-weight: bold; font-size: 14px; margin-right: 5px"
      >任务状态:</span>
      <ButtonGroup
        v-model="state.activeItem"
        :options="list"
        @update:modelValue="changeStatus"
      />
    </new-bs-box>
  </new-bs-box>

  <new-bs-box
    :bs="{
      marginBottom: '20px',
      marginTop: '0px',
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
    }"
  >
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
      <single-select
        v-model="state.filters.type"
        placeholder="根据任务类型筛选"
        :options="state.typeoptions"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px' }"
      />
      <single-select
        v-model="state.filters.version"
        placeholder="根据版本号筛选"
        :options="state.versionList"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px' }"
      />
      <SearchInput
        v-model="state.filters.jobName"
        action-position="inner"
        placeholder="根据任务名称搜索"
        @search="handleSearch"
      />
    </new-bs-box>
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
      <Button
        v-if="USERINFO.role !==3"
        icon-before="add-m"
        @click="showCreateModal({})"
      >新建任务</Button>
      <!-- <Button
        icon-before="add-m"
        @click="test({})"
      >测试</Button> -->
    </new-bs-box>
    <!-- <new-bs-box :bs="{ padding: 8 }">
      <qrcode-vue :value="state.testurl" :size="130" level="H" />
    </new-bs-box> -->
  </new-bs-box>
  <BeerTable
    class="data-table"
    size="s"
    border-style="none"
    :columns="state.columns"
    :data="state.jobList"
  >
    <template #action="{ rowData }">
      <Button
        style="min-width: 30px"
        size="s"
        variant="outline"
        @click="jumpToDetail(rowData)"
      >查看</Button>

      <Button
        v-if="USERINFO.role !==3"
        style="min-width: 20px"
        size="s"
        :disabled="rowData.status !== 1"
        @click="showEditModal(rowData)"
      >编辑</Button>

      <Button
        v-if="USERINFO.role !==3"
        size="s"
        modifier="danger"
        style="min-width: 20px"
        :disabled="rowData.status === 2"
        @click.stop="handleDelete(rowData)"
      >删除</Button>
      <Button
        v-if="USERINFO.role !==3"
        style="margin-right: 0;min-width: 20px"
        size="s"
        @click="jumpToReport(rowData)"
      >报告</Button>
    </template>
    <template #whiteId="{ rowData }">
      <Button
        v-if="USERINFO.role !==3"
        style="min-width: 30px"
        size="s"
        variant="outline"
        @click="handleOpen(rowData)"
      >查看</Button>
      <Drawer
        v-model:visible="drawvisible"
        outside-closeable
      >

        <template #title>
          <Text
            :icon="{ icon: ExperimentOne, theme: 'filled', color: 'red-6' }"
            type="h4"
            bold
          >
            {{ statewhite.whiteiddata.jobName }}
          </Text>
        </template>
        <Text>
          <Tag
            v-for="(item, index) in statewhite.whiteiddata.whiteId"
            :key="`${index}`"
            color="teal"
            style="margin-top:10px;margin-bottom:10px"
            @click="jumpToExperiment(item)"
          > {{ index+1 }}、  https://experiment.devops.xiaohongshu.com/#/lumos/detail/{{ item }}</Tag>
        </Text>
      </Drawer>
    </template>
    <template #send="{ rowData }">
      <Button
        v-if="USERINFO.role !==3"
        style="min-width: 30px"
        size="s"
        @click="sentToAll(rowData)"
      >群发</Button>
    </template>
    <template #isonline="{ rowData }">
      <Button
        v-if="USERINFO.role !==3"
        :disabled="rowData.status === 3"
        style="min-width: 30px"
        size="s"
        :modifier="rowData.status === 2?'success':'warn'"
        @click="isOnline(rowData)"
      >{{ rowData.status===2?'下线':'上线' }}</Button>
    </template>
    <template #showTitle="{ column }">
      <NewBsBox>{{ USERINFO.role!==3?column.title:'' }}</NewBsBox>
    </template>

    <!-- <template #action2="{ rowData }">
      <div style="width: 150px; margin-right: 0">
        <Button
          style="margin-right: 0; min-width: 60px; padding: 3px"
          variant="link"
          :disabled="rowData.status !== 2"
          @click="showCreateBugModal(rowData)"
        >PC入口</Button>
        <span>|</span>
        <Popover
          trigger="hover"
          placement="top"
        >
          <Button
            style="margin-left: 0; min-width: 60px; padding: 3px"
            variant="link"
            :disabled="rowData.status !== 2"
          >手机入口</Button>
          <template #popover>
            <new-bs-box :bs="{ padding: 8 }">
              <qrcode-vue
                :value="
                  state.submitBugUrl +
                    '?id=' +
                    rowData.id +
                    '&version=' +
                    rowData.version +
                    '&platform=' +
                    rowData.type
                "
                :size="130"
                level="H"
              />
            </new-bs-box>
            <span style="font-size:8px">请使用【小红书APP】扫码</span>
          </template>
        </Popover>
      </div>
    </template> -->
  </BeerTable>
  <div class="pagination-table">
    <Pagination
      v-model="state.pagination"
      align="flex-end"
      @update:modelValue="handlePagination"
    />
  </div>
  <EditJob
    :show="jobVisible"
    :data="jobDesc"
    :mode="mode"
    @close="hideModal"
    @submit="handleSubmit"
  />
  <!-- <EditBug
    :show="bugVisible"
    :data="bugdatainfo"
    :mode="bugmode"
    @close="hideBugModal"
    @submit="handleBugSubmit"
  /> -->
</template>

<script setup lang="ts">
  import {
    reactive, watch, onMounted, ref,
  } from 'vue'
  // import QrcodeVue from 'qrcode.vue'
  // import { useStore } from 'vuex'
  // import { useRouter } from 'vue-router'

  import {
    BeerTable,
    Button,
    NewBsBox,
    Pagination,
    toaster,
    SearchInput,
    SingleSelect,
    ButtonGroup,
    showModal,
    // Popover,
  } from '@xhs/yam-beer'
  import { Drawer, Text, Tag } from '@xhs/delight'
  import { ExperimentOne } from '@xhs/delight/icons'
  import {
    getJobList,
    getVersionList,
    postApiJob,
    postApiJobById,
    deleteJob,
    updateStatus,
    pushgroup,
    // mocktest,
  } from '../../services/Job'
  // import { postApiBug } from '../../services/Bug'
  import { useModalForm } from '../../composables/useModalForm'
  // import { useBugModalForm } from '../../composables/useBugModalForm'
  import EditJob from '../../components/Job/EditJob.vue'
  // import EditBug from '../../components/Bug/EditBug.vue'
  import { formatDate, getKey, TYPE_MAP } from '~/utils/common'
  import { USERINFO } from '../../common'

  // const h5url = `${process.env.BUILD_ENV !== 'production' ? 'test.sit.xiaohongshu.com' : 'test.xiaohongshu.com'}`
  const str = window.location.href
  const h5url = `${str.indexOf('.sit.') !== -1 ? 'test.sit.xiaohongshu.com' : 'test.xiaohongshu.com'}`
  const list = [
    { name: '全部', id: -1 },
    { name: '待启动', id: 1 },
    { name: '进行中', id: 2 },
    { name: '已结束', id: 3 },
  ]

  const statusMap1 = {
    全部: -1,
    待启动: 1,
    进行中: 2,
    已结束: 3,
  }

  const statusMap2 = {
    1: '待启动',
    2: '进行中',
    3: '已结束',
  }
  // const store = useStore()
  // const redName = store.state
  // const router = useRouter()
  type IFilterConfig = {
    page: number
    pageSize: number
    status?: number
    version?: string
    jobName?: string
    type?: number
  }

  const drawvisible = ref(false)

  const state = reactive({
    // submitBugUrl: 'xhsdiscover://webview//10.31.170.66:1389/bug/add',
    submitBugUrl: `xhsdiscover://webview//${h5url}/bug/add`,
    versionList: [],
    onlineType: 'warn',
    onlineTxt: '上线',
    filters: {
      version: '',
      jobName: '',
      status: 0,
      type: 0,
    },
    typeoptions: [
      { name: 'Android', value: '1' },
      { name: 'iOS', value: '2' },
      { name: 'web', value: '3' },
      { name: 'H5', value: '4' },
    ],
    columns: [
      {
        title: '任务ID',
        key: 'id',
      },
      {
        title: '任务名称',
        key: 'jobName',
        th: {
          bs: {
            width: 200,
          },
        },
      },
      {
        title: '版本号',
        key: 'version',
      },
      {
        title: '测试开始',
        key: 'startTime',
        formatter: (startTime: any) => formatDate(startTime),
      },
      {
        title: '测试结束',
        key: 'endTime',
        formatter: (endTime: any) => formatDate(endTime),
      },
      {
        title: '创建人',
        key: 'creator',
      },
      {
        title: '类型',
        key: 'type',
        formatter: (type: number) => getKey(TYPE_MAP, type),
      },
      {
        title: '状态',
        key: 'status',
        formatter: (status: number) => getKey(statusMap2, status),
      },
      {
        key: 'action',
        title: '操作',
        td: {
          is: 'action',
        // bs: {
        //   width: "250px",
        // },
        },
        th: {
          bs: {
            width: 300,
          },
        },
      },
      {
        title: '白名单',
        key: 'whiteId',
        td: {
          is: 'whiteId',
        },
        th: {
          bs: {
            width: 100,
          },
        },
      },
      {
        title: '群发',
        key: 'send',
        td: {
          is: 'send',
        },
        th: {
          is: 'showTitle',
          bs: {
            width: 100,
          },
        },
      },
      {
        title: '上线操作',
        key: 'isonline',
        th: {
          is: 'showTitle',
        },
        td: {
          is: 'isonline',
          bs: {
          },
        },
        fixed: 'right',
      },
      // {
      //   title: '缺陷提报',
      //   key: 'action2',
      //   td: {
      //     is: 'action2',
      //     bs: {
      //     },
      //   },
      // },
    ],
    jobList: [],
    // jobList : JSON.parse('[{"creator":"aaa","endTime":"2021-11-21","jobId":123,"jobName":"xxx","startTime":"2021-11-19","status":3,"version":"1.0.0"}]'),
    pagination: {
      pageNo: 1,
      pageSize: 10,
      total: 0,
    },
    activeItem: list[0].name,
  })

  const statewhite = reactive({
    whiteiddata: {},
  })

  function handleOpen(row:any) {
    statewhite.whiteiddata = row
    drawvisible.value = true
  }

  const handleSearch = async () => {
    const {
      version, status, jobName, type,
    } = state.filters
    const params: IFilterConfig = {
      page: state.pagination.pageNo,
      pageSize: state.pagination.pageSize,
    }
    if (version) params.version = version
    if (jobName) params.jobName = jobName
    if (status) params.status = status
    if (type) params.type = type
    try {
      const res = await getJobList(params)
      if (res.totalCount === 0) {
        toaster.warning('暂无数据！')
      }

      state.jobList = res.jobList // 后端进行分页
      state.pagination.total = res.totalCount
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const handlePagination = () => {
    handleSearch()
  }

  const changeStatus = async () => {
    state.filters.status = getKey(statusMap1, state.activeItem)
    handleSearch()
  }

  // watch(() => state.activeItem, value => {
  //        toaster.notify(`you clicked ${state.activeItem} ------${state.activeId}`)
  //       })

  const handleVersionList = async () => {
    try {
      const res = await getVersionList()
      const versionList = res.versionList
      versionList.sort((a, b) => b - a)
      state.versionList = versionList
    } catch (error) {
      toaster.warning(error.message)
    }
  }
  const {
    visible: jobVisible,
    data: jobDesc,
    mode,
    isEditMode,
    showCreateModal,
    showEditModal,
    hideModal,
  } = useModalForm()

  // const {
  //   visible: bugVisible,
  //   data: bugdatainfo,
  //   bugmode,
  //   // isBugEditMode,
  //   showCreateBugModal,
  //   hideBugModal,
  // } = useBugModalForm()

  // 提交任务表单
  function handleSubmit(data: any) {
    const {
      jobName,
      version,
      startTime,
      endTime,
      jobInfo,
      jobUrl,
      buildId,
      whiteId,
      creatorId,
      type,
    } = data

    const payload = {
      jobName,
      version,
      startTime,
      endTime,
      jobInfo,
      jobUrl,
      buildId,
      whiteId,
      creatorId,
      type,
    }
    if (isEditMode.value) {
      postApiJobById({
        jobName,
        version,
        startTime,
        endTime,
        jobInfo,
        jobUrl,
        buildId,
        whiteId,
        operatorId: USERINFO.userId,
        type,
        id: data.id,
      })
        .then(res => {
          if (res === false) {
            toaster.warning('请检查输入内容！')
          } else {
            toaster.success('编辑成功')
            handleSearch()
            hideModal()
          }
        })
        .catch(err => toaster.warning(err))
    }
    if (!isEditMode.value) {
      // console.log('新建任务-----')
      // console.log(payload)
      postApiJob(payload)
        .then((res:any) => {
          if (res.code === 1001) {
            toaster.warning(res.msg)
          } else {
            toaster.success('创建成功')
            handleSearch()
            hideModal()
          }
        })
        .catch(err => toaster.warning(err))
    }
  }
  const handleDelete = async (rowData: { id: string;operatorId:string }) => {
    const jobId = rowData.id
    const operatorId = rowData.operatorId
    showModal({
      props: {
        type: 'dialog',
        title: '确定要删除该条任务么？',
        async handleConfirm({ close }) {
          try {
            const res = await deleteJob({ jobId, operatorId })
            if (res.code === 200) {
              toaster.success('删除成功')
              close()
              handleSearch()
            } else {
              toaster.warning(res.msg)
            }
          } catch (error) {
            toaster.warning(error.message)
          }
        },
      },
    })
  }
  const jumpToDetail = async (rowData: { id: any }) => {
    const id = rowData.id
    // router.push({
    //   name: 'JobDetail',
    //   params: {
    //     id,
    //   },
    // })
    const strurl = window.location.href
    if (strurl.indexOf('.sit.') !== -1) {
      window.open(`http://test.sit.xiaohongshu.com/jobdetail/${id}`)
    } else if (strurl.indexOf('.beta.') !== -1) {
      window.open(`http://test.beta.xiaohongshu.com/jobdetail/${id}`)
    } else {
      window.open(`https://test.xiaohongshu.com/jobdetail/${id}`)
    }
  }

  const jumpToReport = async (rowData: { id: any }) => {
    const id = rowData.id
    const strurl = window.location.href
    // window.open(`http://172.24.20.52:1388/jobreport/${id}`)
    // sit环境和线上环境
    if (strurl.indexOf('.sit.') !== -1) {
      window.open(`http://test.sit.xiaohongshu.com/jobreport/${id}`)
    } else if (strurl.indexOf('.beta.') !== -1) {
      window.open(`https://test.beta.xiaohongshu.com/jobreport/${id}`)
    } else {
      window.open(`https://test.xiaohongshu.com/jobreport/${id}`)
    }
  }

  const jumpToExperiment = async (item: any) => {
    const experimenturl = `https://experiment.devops.xiaohongshu.com/#/lumos/detail/${item}`
    window.open(experimenturl)
  }

  const sentToAll = async (rowData: { id: any }) => {
    const params = {
      jobid: rowData.id,
      groupid: -1,
    }
    try {
      const res = await pushgroup(params)
      if (res.code === 200) {
        toaster.success('群发成功！')
      } else {
        toaster.warning(res.msg)
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const isOnline = async (rowData: { id: any;status:any;operatorId:any }) => {
    const params = {
      jobId: rowData.id,
      operatorId: rowData.operatorId,
      status: -1,
    }

    // 后端直接更新status，不需要前端传值
    if (rowData.status === 2) {
      params.status = 3 // 下线操作
    } else if (rowData.status === 1) {
      params.status = 2 // 上线线操作
    }

    try {
      const res = await updateStatus(params)
      if (res.code === 200) {
        toaster.success('操作成功！')
        handleSearch()
      } else {
        toaster.warning(res.msg)
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  // const isOnline(){

  // }
  // // 提交PC端缺陷表单
  // function handleBugSubmit(bugdata:any) {
  //   const {
  //     title, bugInfo, platform, deviceSystem, jobId, version,
  //   } = bugdata
  //   //  if(bugdata.isRecur == ''){
  //   //   var tmpisRecur= 1
  //   //  } else {
  //   //    var tmpisRecur= parseInt(bugdata.isRecur)
  //   //  }
  //   const payload = {
  //     title,
  //     bugInfo,
  //     isRecur: Number(bugdata.isRecur),
  //     platform,
  //     deviceSystem,
  //     jobId,
  //     version,
  //     attachmentList: bugdata.bugfileList,
  //     // reporter: redName.Auth.userInfo.nickName, // 登陆
  //     reporter: USERINFO.nickName,
  //   }
  //   postApiBug(payload)
  //     .then(res => {
  //       if (res === true) {
  //         toaster.success('创建bug成功')
  //         hideBugModal()
  //       } else {
  //         toaster.warning('请检查输入参数！')
  //       }
  //     })
  //     .catch(err => toaster.warning(err))
  // }

  /// / mock测试用
  // function test(){
  //   mocktest().then((res:any) => {
  // console.log(res)
  //   })
  // }

  watch([() => state.filters.version, () => state.filters.type], () => {
    handleSearch()
  })

  onMounted(() => {
    handleVersionList()
    handleSearch()
  })
</script>

<style lang="stylus" scoped>
.data-table {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-table {
  margin-top: 24px;
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>
