<template>
  <new-bs-box
    :bs="{
      marginBottom: '20px',
      marginTop: '10px',
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
    }"
  >
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
      <SearchInput
        v-model="state.filters.whiteGroupId"
        action-position="inner"
        placeholder="根据whiteGroup搜索"
        @search="handleSearch"
      />
    </new-bs-box>
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
      <Button
        icon-before="add-m"
        @click="showCreateWhiteModal({})"
      >新增白名单</Button>

    </new-bs-box>

  </new-bs-box>
  <BeerTable
    class="data-table"
    size="s"
    border-style="none"
    :columns="state.columns"
    :data="state.WhiteList"
  >
    <template #whiteId="{ rowData }">
      <Button
        style="min-width: 30px"
        size="s"
        variant="outline"
        @click="handleOpen(rowData)"
      >查看</Button>
    </template>
    <template #action="{ rowData }">
      <Button
        size="s"
        @click="showWhiteEditModal(rowData)"
      >编辑</Button>
      <Button
        size="s"
        modifier="danger"
        @click.stop="handleDelete(rowData)"
      >删除</Button>
    </template>

  </BeerTable>
  <!-- <div class="pagination-table">
    <Pagination
      v-model="state.pagination"
      align="flex-end"
      @update:modelValue="handlePagination"
    />
  </div> -->
  <EditWhite
    :show="whiteVisible"
    :data="whitedatainfo"
    :mode="whitemode"
    @close="hideWhiteModal"
    @submit="handleWhiteSubmit"
  />

</template>

<script setup lang="ts">
  import { reactive, onMounted } from 'vue'
  import {
    BeerTable,
    Button,
    NewBsBox,
    // Pagination,
    toaster,
    SearchInput,
    showModal,
    // Popover,
  } from '@xhs/yam-beer'

  import { useWhiteModalForm } from '../../composables/useWhiteModalForm'
  import EditWhite from '../../components/Mange/EditWhite.vue'
  import {
    getWhiteList,
    WhiteAdd,
    deleteWhite,
    searchWhiteId,
    WhiteUpdate,
  } from '../../services/White'
  //   import {
  //     getKey, ROLE_MAP, ROLE_OPTIONES,
  //   } from '~/utils/common'
  //   import { USERINFO } from '../../common'
  const {
    visible: whiteVisible,
    data: whitedatainfo,
    whitemode,
    isWhiteEditMode,
    showCreateWhiteModal,
    showWhiteEditModal,
    hideWhiteModal,
  } = useWhiteModalForm()

  //   type IFilterConfig = {
  //     whiteGroupId?: string
  //   }
  const state = reactive({
    filters: {
      whiteGroupId: '',
    },

    columns: [
      {
        title: '序号',
        key: 'id',

      },
      {
        title: 'groupName',
        key: 'groupName',
      },
      {
        title: 'groupId',
        key: 'groupId',

      },
      {
        title: 'whiteGroup',
        key: 'whiteGroup',
      },
      {
        key: 'whiteId',
        title: '实验',
        td: {
          is: 'whiteId',
        },
      },
      {
        key: 'action',
        title: '操作',
        td: {
          is: 'action',
        },
      },

    ],
    WhiteList: [],
    // WhiteList : JSON.parse('[{"id":"1","groupName":"111ffff","groupId":123,"whiteGroup":9999,"status":1}]'),
    // pagination: {
    //   pageNo: 1,
    //   pageSize: 10,
    //   total: 0,
    // },

  })

  const handleOpen = async (row: any) => {
    // console.log(item)
    const experimenturl = `https://experiment.devops.xiaohongshu.com/#/whiteGroup/detail/${row.whiteGroup}`
    window.open(experimenturl)
  }
  const handleSearch = async () => {
    const {
      whiteGroupId,
    } = state.filters
    state.WhiteList = []
    // console.log(whiteGroupId)
    try {
      if (whiteGroupId !== '') {
        const res = await searchWhiteId({ whiteGroupId })
        state.WhiteList = res.data
        // console.log(res.data)
      } else {
        const res = await getWhiteList()
        if (res.data.length === 0) {
          toaster.warning('暂无数据！')
        }

        state.WhiteList = res.data
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const getlist = async () => {
    try {
      const res = await getWhiteList()
      if (res.data.length === 0) {
        toaster.warning('暂无数据！')
      }

      state.WhiteList = res.data // 数据少后端没分页
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  //   const handlePagination = () => {
  //     handleSearch()
  //   }

  function handleWhiteSubmit(data: any) {
    window.console.log(data)
    const {
      groupName, groupId, whiteGroup, id,
    } = data

    const payload = {
      groupName,
      groupId,
      whiteGroup,
    }
    const payloadupdate = {
      groupName,
      groupId,
      whiteGroup,
      id,
    }
    // console.log("9999-----")
    // window.console.log(payloadupdate)

    if (!isWhiteEditMode.value) {
      WhiteAdd(payload)
        .then((res:any) => {
          if (res.code === 200) {
            toaster.success('新增白名单成功！')
            getlist()
            hideWhiteModal()
          } else {
            toaster.warning(res.msg)
          }
        })
        .catch(err => toaster.warning(err))
    } else {
      WhiteUpdate(payloadupdate)
        .then((res:any) => {
          if (res.code === 200) {
            toaster.success('编辑白名单成功！')
            getlist()
            hideWhiteModal()
          } else {
            toaster.warning(res.msg)
          }
        })
        .catch(err => toaster.warning(err))
    }
  }

  const handleDelete = async (rowData: { whiteGroup: any }) => {
    const whiteGroupId = rowData.whiteGroup
    showModal({
      props: {
        type: 'dialog',
        title: '确定要删除该白名单组么？',
        async handleConfirm({ close }) {
          try {
            const res = await deleteWhite({ whiteGroupId })
            if (res.code === 200) {
              toaster.success('删除成功')
              close()
              getlist()
            } else {
              toaster.warning(res.msg)
            }
          } catch (error) {
            toaster.warning(error.message)
          }
        },
      },
    })
  }

  //   watch([() => state.filters.deviceName], () => {
  //     handleSearch()
  //   })

  onMounted(() => {
    // handleSearch()
    getlist()
  })
</script>

<style lang="stylus" scoped>
.data-table {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-table {
  margin-top: 24px;
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>
