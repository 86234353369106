<template>
  <NewBsBox :bs="{ p: 32 }">
    <BeerForm
      v-model="formValue"
      :config="formConfig"
    />
  </NewBsBox>
</template>

<script lang="ts">
  // @ts-nocheck
  import { reactive } from 'vue'
  import { NewBsBox } from '@xhs/yam-beer'
  import BeerForm from '@xhs/beer-formily'

  const formConfig = {
    children: [
      {
        name: 'inputValue',
        label: 'Input example',
      },
      {
        name: 'inputWithUnitValue',
        label: 'InputWithUnit example',
        component: {
          is: 'inputWithUnit',
          rightOptions: ['KB', 'MB', 'GB'],
        },
      },
      {
        name: 'inputWithOptionValue',
        label: 'InputWithOption example',
        component: {
          is: 'inputWithOption',
          options: [
            { name: 'option1', value: 'option1' },
            { name: 'option2', value: 'option2' },
            { name: 'option3', value: 'option3' },
          ],
        },
      },
      {
        name: 'rangeInputValue',
        label: 'RangeInput example',
        component: {
          is: 'rangeInput',
        },
      },
      {
        name: 'comboInputValue',
        label: 'ComboInput example',
        component: {
          is: 'comboInput',
        },
      },
      {
        name: 'textAreaValue',
        label: 'TextArea example',
        component: {
          is: 'textArea',
        },
      },
      {
        name: 'radioGroupValue',
        label: 'RadioGroup example',
        component: {
          is: 'radioGroup',
          options: [
            { value: 'a', name: '崂山' },
            { value: 'b', name: '燕京', disabled: true },
            { value: 'c', name: '山水' },
          ],
        },
      },
      {
        name: 'checkboxValue',
        label: 'Checkbox example',
        component: {
          is: 'checkbox',
          label: '选项1',
        },
      },
      {
        name: 'switchValue',
        label: 'Switch example',
        component: {
          is: 'switch',
        },
      },
      {
        name: 'singleSelectValue',
        label: 'SingleSelect example',
        component: {
          is: 'singleSelect',
          options: [
            { name: '选项A', value: 'a' },
            { name: '选项B', value: 'b', binds: { disabled: true } },
            { name: '选项C', value: 'c' },
          ],
        },
      },
      {
        name: 'multiSelectValue',
        label: 'MultiSelect example',
        component: {
          is: 'multiSelect',
          options: [
            { value: 'a', name: '选项示例' },
            { value: 'b', name: '选项示例2' },
            { value: 'c', name: '选项示例 (disable)', binds: { disabled: true } },
            { value: 'd', name: '选项示例4' },
            { value: 'e', name: '选项示例6' },
          ],
        },
      },
      {
        name: 'cascadeSelectValue',
        label: 'CascadeSelect example',
        component: {
          is: 'cascadeSelect',
          levelNums: 3,
          identKey: 'value',
          getTree({ level, context }) {
            if (level < 2) {
              return Promise.resolve([
                { name: '选项示例 A', value: 'A' },
                { name: '选项示例 B', value: 'B' },
              ])
            }

            return Promise.resolve([
              { name: `${context.value + context.meta.level} - a`, value: 'a' },
              { name: `${context.value + context.meta.level} - b`, value: 'b' },
            ])
          },
        },
      },
      {
        name: 'multiCascadeSelectValue',
        label: 'MultiCascadeSelect example',
        component: {
          is: 'multiCascadeSelect',
          treeProps: {
            levelNums: 3,
            identKey: 'value',
            valueKey: 'value',
            getTree({ level, context }) {
              if (level < 2) {
                return Promise.resolve([
                  { name: '选项示例 A', value: 'A' },
                  { name: '选项示例 B', value: 'B', hasChildren: true },
                  { name: '选项示例 C', value: 'C', hasChildren: true },
                ])
              }

              return Promise.resolve([
                { name: `${context.value + context.meta.level} - a`, value: `${context.value}${level}a` },
                { name: `${context.value + context.meta.level} - b`, value: `${context.value}${level}b`, hasChildren: level < 3 },
                { name: `${context.value + context.meta.level} - c`, value: `${context.value}${level}c` },
              ])
            },
            levelProps: {
              onSearch({ level, keyword }) {
                return Promise.resolve([
                  { name: `${level} - a`, value: `${keyword}${level}a` },
                  { name: `${level} - b`, value: `${keyword}${level}b`, hasChildren: level < 3 },
                  { name: `${level} - c`, value: `${keyword}${level}c` },
                ])
              },
            },
          },
          popoverProps: {
            positionerProps: { isPortal: false },
          },
        },
      },
      {
        name: 'datePickerValue',
        label: 'DatePicker example',
        component: {
          is: 'SingleDatePicker',
        },
      },
      {
        name: 'multiDatePickerValue',
        label: 'MultiDatePicker example',
        component: {
          is: 'multiDatePicker',
        },
      },
      {
        name: 'dateRangePickerValue',
        label: 'DateRangePicker example',
        component: {
          is: 'DateRangePicker',
        },
      },
      {
        name: 'clockSelectValue',
        label: 'ClockSelect example',
        component: {
          is: 'ClockSelect',
        },
      },
      {
        name: 'imageUploaderValue',
        label: 'ImageUploader example',
        component: {
          is: 'ImageUploader',
          bucket: 'picasso',
          bizKey: 'fomily-demo',
        },
      },
      {
        name: 'fileUploaderValue',
        label: 'FileUploader example',
        component: {
          is: 'FileUploader',
          bucket: 'picasso',
          bizKey: 'fomily-demo',
        },
      },
    ],
  }

  export default {
    name: 'HomePage',

    components: {
      NewBsBox,
      BeerForm,
    },
    setup() {
      const formValue = reactive({})
      return {
        formValue,
        formConfig,
      }
    },
  }
</script>
