import { ref, computed } from 'vue'
import { useModal } from './useModal'

export const FORM_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
}

export const useUserModalForm:any = (init?:any):any => {
  const initUserData = init === undefined ? {} : init

  const usermode = ref('')
  const data = ref<any>(initUserData)
  const isUserEditMode = computed(() => usermode.value === FORM_MODE.EDIT)

  const {
    visible,
    // bugvisible,
    toggleModal,
  } = useModal()

  //   function showCreateUserModal(newData: any) {
  //     // 父组件信息处理
  //     const newBugData = {
  //       //   jobName: newData.jobName,
  //       version: newData.version,
  //       jobId: newData.id,
  //       platform: newData.type,
  //     }

  //     data.value = newBugData
  //     usermode.value = FORM_MODE.CREATE
  //     toggleModal(true)
  //   }

  function showUserEditModal(newData: any) {
    // // 测试区间给testTime处理
    // newData.testTime = {
    //   start: formatDate(newData.startTime),
    //   end: formatDate(newData.endTime),
    // }
    console.log(1111111)
    console.log(newData)
    data.value = newData
    usermode.value = FORM_MODE.EDIT
    toggleModal(true)
  }

  function hideUserModal() {
    data.value = initUserData
    toggleModal(false)
  }

  return {
    visible,
    data,
    usermode,
    isUserEditMode,
    showUserEditModal,
    hideUserModal,
  }
}
