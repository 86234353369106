export const PRODUCT_HOST_LIST = [
  'test.xiaohongshu.com',
]
export const BETA_HOST_LIST = ['test.beta.xiaohongshu.com']

const SSO_HOSTS = {
  production: 'https://customer.xiaohongshu.com',
  beta: 'http://customer.beta.xiaohongshu.com',
  sit: 'http://customer.sit.xiaohongshu.com',
  netProduction: 'https://customer.xiaohongshu.net',
}

export const H5HOSTS = {
  development: '10.31.170.66',
  production: 'test.xiaohongshu.com',
  beta: 'test.beta.xiaohongshu.com',
  sit: 'test.sit.xiaohongshu.com',
  test: 'test.sit.xiaohongshu.com',
}

export const USERINFO = {
  avatar: '',
  nickName: '',
  userId: '',
  role: -1,
}
// export const ARR_USERID = ['60f7df8b0000000001004a0f', '5f9f83fd0000000001007db4', '61b9a9950000000010009e65', '5a6ac67de8ac2b0a7fd2aab5', '61138d210000000000007bd2', '617a8d49000000001f03b1bf', '5af72b884eacab380f5cf98d', '6113acf90000000001003960', '5ca4b87a00000000160103f4', '576b781a6a6a69227922e276', '60f7c6dd0000000000006fae', '577f70a850c4b47621062fef', '5c448f700000000006011cf2']
export const ARR_PAGE = [
  // 'jobpage',
  'defectpage',
  'userpage',
]

export const SUPERADMIN_PAGE = [
  'home',
  'jobpage',
  'jobdetail',
  'defectpage',
  'awardlist',
  'awardadd',
  'awardrank',
  'statinfo',
  'userpage',
  'managegift',
  'managebrand',
]
export const ADMIN_PAGE = [
  'home',
  'jobpage',
  'jobdetail',
  'defectpage',
  'awardrank',
]
export const MEMBER_PAGE = [
  'home',
  'jobpage',
  'jobdetail',
  'awardrank',
]

export function getPorchHost(): string {
  let host = window.location.origin.indexOf('.net') > -1
    ? SSO_HOSTS.netProduction
    : SSO_HOSTS.production
  const currentHost = window.location.host
  if (BETA_HOST_LIST.includes(currentHost)) {
    host = SSO_HOSTS.beta
  } else if (!PRODUCT_HOST_LIST.includes(currentHost)) {
    host = SSO_HOSTS.sit
  }
  return host
}
