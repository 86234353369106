<template>
  <new-bs-box :bs="{ p: 32 }">
    <pro-table
      :columns="tableColumns"
      :auto-filters="false"
      :get="getTable"
      :create="createTable"
      :update="updateTable"
      :remove="removeTable"
    />
  </new-bs-box>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue'
  // import { useStore } from 'vuex'
  import { NewBsBox } from '@xhs/yam-beer'
  import ProTable from '@xhs/pro-table'
  // import { IRootState } from '@xhs/launcher-plugin-store'

  import * as tableService from '../../services/list/table'

  export default defineComponent({
    name: 'TablePage',

    components: {
      NewBsBox,
      ProTable,
    },

    setup() {
      // const store = useStore<IRootState>()
      // window.console.log(store.state.user)
      const tableColumns = computed(() => ([
        {
          title: '标题1',
          key: 'title1',
          // 用于 autoFilters，autoCreateForm，autoUpdateForm
          filterable: true,
          inputComponent: {
            is: 'SingleSelect',
            props: {
              options: [
                { name: 'option1', value: 'option1' },
                { name: 'option2', value: 'option2' },
                { name: 'option3', value: 'option3' },
              ],
            },
          },
        },
        {
          title: '标题2',
          key: 'title2',
        },
        {
          title: '标题3',
          key: 'title3',
          filterable: false,
        },
        {
          title: '标题4',
          key: 'title4',
          filterable: false,
        },
        {
          title: '操作',
          td: {
            is: 'buttons',
            buttons: [
              {
                text: '查看',
                onClick: 'show',
              },
              {
                text: '编辑',
                onClick: 'update',
              },
              {
                text: '删除',
                modifier: 'danger',
                onClick: 'remove',
              },
            ],
          },
        },
      ]))

      return {
        ...tableService,
        tableColumns,
        showFilters: true,
        showSearch: true,
        showPagination: true,

        filters: {},
        keywords: '',
        pagination: {
          total: 0,
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
  })
</script>
