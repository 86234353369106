<template>
  <div>
    <Swiper />
    <div>
      <Row class="function-content">
        <div class="function-content-item">
          <img
            class="function-content-item-img"
            src="./../../static/renwu.png"
          >
          <div class="function-content-item-title">
            任务
          </div>
          <div class="function-content-item-desc">
            发布测试任务
          </div>
        </div>

        <div class="function-content-item">
          <img
            class="function-content-item-img"
            src="./../../static/bug.png"
          >
          <div class="function-content-item-title">
            缺陷管理
          </div>
          <div class="function-content-item-desc">
            运营人员对提交bug进行分类管理
          </div>
        </div>

        <div class="function-content-item">
          <img
            class="function-content-item-img"
            src="./../../static/baogao.png"
          >
          <div class="function-content-item-title">
            众测效果
          </div>
          <div class="function-content-item-desc">
            bug数据统计,形成报告
          </div>
        </div>

        <div class="function-content-item">
          <img
            class="function-content-item-img"
            src="./../../static/jiangpai.png"
          >
          <div class="function-content-item-title">
            众测奖励
          </div>
          <div class="function-content-item-desc">
            奖励排行榜
          </div>
        </div>
      </Row>
    </div>
  </div>
</template>

<script>
  import Swiper from '../../components/Home/Swiper.vue'

  export default {
    name: 'Home',
    components: {
      Swiper,
    },
    data() {
      return {

      }
    },
    // mounted() {
    // },

    methods: {
    },
  }
</script>
<style>
  .function-content {
    width: 1290px;
    margin: auto;
    text-align: center;
  }
  .function-content-item {
    margin: 10px 50px;
    display: inline-block;
    background: #fff;
    text-align: center;
    width: 200px;
    height: 320px;
    vertical-align: bottom;
    transition: transform .3s ease-in-out;
    position: relative;
  }
  .function-content-item:hover {
    cursor: pointer;
    transform: translateY(-10px);
  }
  .function-content-item-img {
    width: 125px;
    height: 125px;
    margin: 30px;
  }
  .function-content-item-title {
    font-size: 20px;
    color: #333;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .function-content-item-desc {
    padding: 0 40px;
    font-size: 14px;
    color: #999;
    text-align: center;
    line-height: 24px;
  }

</style>
