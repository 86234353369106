<template>
  <BeerModal
    :title="awardtitle"
    :show="show"
    :bs="{
      width: 800,
    }"
    :handle-close="handleCancel"
    :handle-confirm="handleAwardSubmit"
    :confirm-props="{
      disabled: !canSubmit,
    }"
  >
    <BeerForm
      ref="basicForm"
      v-model="basicFormData"
      :config="basicFormConfig"
      :submit="() => {}"
    >
      <template #submit-button><div /></template>
      <template #reset-button><div /></template>
    </BeerForm>
  </BeerModal>
</template>

<script setup lang="ts">
  import {
    defineProps,
    defineEmits,
    ref,
    reactive,
    computed,
    toRefs,
    watch,
    unref,
  } from 'vue'
  import { BeerModal } from '@xhs/yam-beer'
  import BeerForm from '@xhs/beer-formily'

  const props = defineProps<{
    show: boolean
    data: any
    mode: string
  }>()

  const emit = defineEmits(['close', 'submit'])
  const basicFormData = ref({} as any)
  const basicForm = ref<any>()
  const awardtitle = '奖品信息'

  const { canSubmit, basicFormConfig } = toRefs(
    reactive({
      canSubmit: true,
      basicFormConfig: computed(() => ({
        children: [
          {
            name: 'awardName',
            type: 'string',
            label: '奖品名称',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              props: {
                bs: {
                  width: '400px',
                  placeholder: '请输入奖品名称',
                },
              },
            },
          },
          {
            name: 'num',
            type: 'string',
            label: '奖品数量',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {

              props: {
                bs: {
                  width: '400px',
                  placeholder: '请输入库存',
                },
              },
            },
          },

        ],
      })),
    }),
  )

  function init() {
    const initAwardData = Object.keys(props.data).length ? props.data : {}
    basicFormData.value = {
      awardName: initAwardData.awardName || '',
      num: initAwardData.num || '',
    }
  }

  watch(
    () => props.show,
    () => {
      init()
    },
  )

  function handleCancel() {
    init()
    emit('close')
  }

  function handleAwardSubmit() {
    // 提交数据前处理一下

    basicForm?.value?.validate().then(() => {
      emit('submit', {
        ...unref(basicFormData),
      })
    })
  }
</script>
