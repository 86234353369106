import { ref, computed } from 'vue'
import { useModal } from './useModal'

export const FORM_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
}

export const useAwardModalForm:any = (init?:any):any => {
  const initAwardData = init === undefined ? {} : init

  const awardmode = ref('')
  const data = ref<any>(initAwardData)
  const isAwardEditMode = computed(() => awardmode.value === FORM_MODE.EDIT)

  const {
    visible,
    // bugvisible,
    toggleModal,
  } = useModal()

  function showCreateAwardModal(newData: any) {
    //   // 父组件信息处理
    //   const newBugData = {
    //     //   jobName: newData.jobName,
    //     version: newData.version,
    //     jobId: newData.id,
    //     platform: newData.type,
    //   }

    //   data.value = newBugData
    window.console.log('222')
    window.console.log(JSON.stringify(newData))
    data.value = newData
    awardmode.value = FORM_MODE.CREATE
    toggleModal(true)
  }

  function showAdwardEditModal(newData: any) {
    // // 测试区间给testTime处理
    // newData.testTime = {
    //   start: formatDate(newData.startTime),
    //   end: formatDate(newData.endTime),
    // }

    data.value = newData
    awardmode.value = FORM_MODE.EDIT
    toggleModal(true)
  }

  function hideAwardModal() {
    data.value = initAwardData
    toggleModal(false)
  }

  return {
    visible,
    data,
    awardmode,
    isAwardEditMode,
    showCreateAwardModal,
    showAdwardEditModal,
    hideAwardModal,
  }
}
