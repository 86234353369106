import { ref, reactive } from 'vue'
import {
  withDialog,
} from '@xhs/yam-beer'

export const APP_NAME = ''

// export const TYPE_MAP=[
//   {name:'Android',value:'1'},
//   {name:'iOS',value:'2'},
//   {name:'web',value:'3'},
//   {name:'H5',value:'4'}
//   ],
export const TYPE_MAP = {
  1: 'Android',
  2: 'iOS',
  3: 'web',
  4: 'H5',
}
export const TYPE_OPTIONES = [
  { name: 'Android', value: '1' },
  { name: 'iOS', value: '2' },
  { name: 'web', value: '3' },
  { name: 'H5', value: '4' },
]

export const ROLE_MAP = {
  1: '超级管理员',
  2: '管理员',
  3: '普通用户',
}

export const ROLE_OPTIONES = [
  { name: '超级管理员', value: 1 },
  { name: '管理员', value: 2 },
  { name: '普通用户', value: 3 },

]

export const LEVEL_OPTIONES = {
  1: '空',
  2: '建议',
  3: '提示',
  4: '一般',
  5: '严重',
  6: '致命',
}
export const PRIORITY_OPTIONES = {
  // 1: '空',
  // 2: '无关紧要',
  // 3: '低',
  // 4: '中',
  // 5: '高',
  // 6: '紧急',
  1: '空',
  2: '首页/推荐/附近/频道/搜索',
  3: '消息/私信/群聊/推送',
  4: '发布笔记/创作',
  5: '图文笔记/评论/点赞/关注',
  6: '视频',
  7: '个人页/他人页/个人中心',
  8: '直播',
  9: '购物/电商',
  10: '卡顿/闪退/崩溃',
  11: '建议/产品提议/体验',
  12: '登陆/账号/注册',
  13: '活动/运营活动/创服中心',
  14: '其他',
}

// 列表按key升序
export function sortBykeyAsd(ary, key) {
  return ary.sort((a, b) => {
    const x = a[key]
    const y = b[key]
    if (x < y) {
      return -1
    }
    if (x > y) {
      return 1
    }
    return 0

    // return ((x < y) ? -1 : (x > y) ? 1 : 0)
  })
}
// 列表按key降序
export function sortBykeyDesc(ary, key) {
  return ary.sort((a, b) => {
    const x = a[key]
    const y = b[key]
    if (x > y) {
      return -1
    }
    if (x < y) {
      return 1
    }
    return 0

    // return ((x > y) ? -1 : (x < y) ? 1 : 0)
  })
}

export function jumpToLink(link) {
  window.open(link)
}
export function getKey(obj, key) {
  return obj[key]
}
// 根据value获取对应的key
export function findkey(obj, value, fn = (a, b) => a === b) {
  return Object.keys(obj).find(k => fn(obj[k], value))
}
export function formatDate(date) { // 设置时间转换格式
  const time = new Date(date)
  const y = time.getFullYear() // 获取年
  let m = time.getMonth() + 1 // 获取月
  m = m < 10 ? `0${m}` : m // 判断月是否大于10
  let d = time.getDate() // 获取日
  d = d < 10 ? (`0${d}`) : d // 判断日期是否大10
  return `${y}-${m}-${d}` // 返回时间格式
}

export function datetimeFormat(daterc) {
  const date = new Date(daterc)
  const year = date.getFullYear()
  /* 在日期格式中，月份是从0开始，11结束，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
  // 拼接
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export function getDataTime(n) {
  const now = new Date(n)
  const y = now.getFullYear()
  const m = now.getMonth() + 1
  const d = now.getDate()
  return `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d} ${now.toTimeString().substr(0, 8)}`
}

// 获取N天前的时间
export function beforeDays(num) {
  // 设置日期，当前日期的前num天
  const myDate = new Date() // 获取今天日期
  myDate.setDate(myDate.getDate() - (num - 1))
  const dateArray = []
  let myMonth = myDate.getMonth() + 1
  if (myMonth >= 1 && myMonth <= 9) {
    myMonth = `0${myMonth}`
  }
  let myDates = myDate.getDate()
  if (myDates >= 0 && myDates <= 9) {
    myDates = `0${myDates}`
  }
  let dateTemp
  const flag = 1
  for (let i = 0; i < num; i++) {
    dateTemp = `${myDate.getFullYear()}-${myMonth}-${myDates}`
    dateArray.push(dateTemp)
    myDate.setDate(myDate.getDate() + flag)
  }
  return dateArray[0]
}

// 获取当前时间
export function getCurrentDate() {
  const date = new Date()
  const seperator1 = '-'
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let strDate = date.getDate()
  if (month >= 1 && month <= 9) {
    month = `0${month}`
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = `0${strDate}`
  }
  const currentdate = year + seperator1 + month + seperator1 + strDate
  return currentdate
}

export function genLevelOptions(initLevel, maxLevel, useString = false) {
  let init = initLevel
  const result = []

  while (init <= maxLevel) {
    result.push({
      name: `S${init}`,
      value: useString ? String(init) : init,
    })
    init += 1
  }

  return result
}

export function checkPermission(resourceName, permission, permissions) {
  let permitted = false

  permissions.forEach(p => {
    // 查看整体权限
    if (!p.resource && p.permissions.includes(permission)) {
      permitted = true
    }

    // 查看模块权限
    if (resourceName && p.resource === resourceName && p.permissions.includes(permission)) {
      permitted = true
    }
  })
  return permitted
}

export function camelTransform(str) {
  const ret = str.replace(/([A-Z])/g, '_$1')
  return ret.toLowerCase()
}

export function diffObject(obj1, obj2) {
  let isSame = true
  Object.keys(obj1).forEach(key => {
    if (obj1[key] !== obj2[key]) {
      isSame = false
    }
  })
  return isSame
}
export function checkJson(rule, value, callback) {
  if (!value) {
    callback('请填写json数据')
  }
  try {
    const json = JSON.parse(value)

    if (typeof (json) !== 'object') {
      callback('json数据必须为对象或数组')
    } else {
      callback()
    }
  } catch (e) {
    callback(e)
  }
}
export function transformToArrayForCiPackage(arr) {
  const versionList = []
  const data = []
  arr.forEach(item => {
    if (item.lockVersion && versionList.indexOf(item.lockVersion) >= 0) {
      data[versionList.indexOf(item.lockVersion)].value += 1
      data[versionList.indexOf(item.lockVersion)].projects.push({
        name: item.ciProjects.projectName, type: item.ciProjects.type, parentId: item.ciProjects.parentId, versionRule: item.versionRule, webUrl: item.ciProjects.webUrl,
      })
    } else if (!item.lockVersion) {
      if (versionList.indexOf('null') >= 0) {
        data[versionList.indexOf('null')].value += 1
        data[versionList.indexOf('null')].projects.push({
          name: item.ciProjects.projectName, type: item.ciProjects.type, parentId: item.ciProjects.parentId, versionRule: item.versionRule, webUrl: item.ciProjects.webUrl,
        })
      } else {
        versionList.push('null')
        data.push({
          value: 1,
          name: 'null',
          projects: [{
            name: item.ciProjects.projectName, type: item.ciProjects.type, parentId: item.ciProjects.parentId, versionRule: item.versionRule, webUrl: item.ciProjects.webUrl,
          }],
        })
      }
    } else {
      versionList.push(item.lockVersion)
      data.push({
        value: 1,
        name: item.lockVersion,
        projects: [{
          name: item.ciProjects.projectName, type: item.ciProjects.type, parentId: item.ciProjects.parentId, versionRule: item.versionRule, webUrl: item.ciProjects.webUrl,
        }],
      })
    }
  })
  return { data, versionList }
}

// sort
export function compare(property) {
  return function innerCompare(a, b) {
    const nameA = a[property].toUpperCase() // ignore upper and lowercase
    const nameB = b[property].toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }
}
export function transformToCiStageData(obj) {
  const versionList = []
  const data = []
  Object.keys(obj).forEach(key => {
    obj[key].forEach(item => {
      if (item.version && versionList.indexOf(`${key}@${item.version}`) >= 0) {
        data[versionList.indexOf(`${key}@${item.version}`)].value += 1
        data[versionList.indexOf(`${key}@${item.version}`)].projects.push({
          name: item.projectName, projectId: item.projectId,
        })
      } else if (!item.version) {
        if (versionList.indexOf('null') >= 0) {
          data[versionList.indexOf('null')].value += 1
          data[versionList.indexOf('null')].projects.push({
            name: item.projectName, projectId: item.projectId,
          })
        } else {
          versionList.push('null')
          data.push({
            value: 1,
            name: 'null',
            projects: [{
              name: item.projectName, projectId: item.projectId,
            }],
          })
        }
      } else {
        versionList.push(`${key}@${item.version}`)
        data.push({
          value: 1,
          name: `${key}@${item.version}`,
          projects: [{
            name: item.projectName, projectId: item.projectId,
          }],
        })
      }
    })
  })

  data.sort(compare('name'))
  return data
}

function requiredValidator(value) {
  if (!value) {
    return '字段必填'
  }
  return ''
}

export function getIsValid(getVal, setError, validator) {
  function isValid() {
    const errorMsg = validator(getVal())
    setError(errorMsg)
    return !errorMsg
  }
  return isValid
}

function serializeField(v, value) {
  if (v?.serialize) {
    return v.serialize(value)
  }
  return value
}

function hydrateField(v) {
  if (v?.hydrate) {
    return v.hydrate(v)
  }
  return v
}

/**
 * 简单的 Form hooks
 * 返回
 * - form.data 编辑的数据
 * - form.error 验证的错误
 * - form[fieldKey], 原始配置 + validator
 */
export function useForm(fieldList) {
  const data = reactive({})
  const error = reactive({})
  const form = fieldList.reduce((acc, field) => {
    const {
      key, value, required, isValid, ...rest
    } = field
    let validator = () => ''
    if (required) {
      validator = requiredValidator
    }
    if (isValid) {
      validator = isValid
    }
    data[key] = value
    error[key] = ''
    acc[key] = {
      ...rest,
      isValid: getIsValid(
        () => data[key],
        e => {
          error[key] = e
        },
        validator,
      ),
    }
    return acc
  }, {})

  return {
    data,
    error,
    ...form,
    isValid() {
      const valids = Object.keys(form)
        .map(i => form[i].isValid())
      return valids.every(v => v)
    },
    getData() {
      return Object.keys(form)
        .reduce((acc, key) => {
          acc[key] = hydrateField(data[key])
          return acc
        }, {})
    },
    setData(original) {
      return Object.keys(original)
        .forEach(k => {
          data[k] = serializeField(form[k], original[k])
        })
    },
  }
}

export const showUnSaveDialog = withDialog({
  title: '⚠️ 你有未保存的修改，确认是否退出编辑?',
  okType: 'danger',
  confirmProps: {
    modifier: 'danger',
  },
})

export const useModal = () => {
  const modalVisible = ref(false)

  function showModal() {
    modalVisible.value = true
  }

  function hideModal() {
    modalVisible.value = false
  }
  return { modalVisible, showModal, hideModal }
}

export function checkImportant(num) {
  let value = ''
  if (num >= 50) {
    value = 'danger'
  } else if (num >= 20) {
    value = 'warn'
  } else {
    value = 'info'
  }
  return value
}
