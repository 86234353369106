<template>
  <new-bs-box
    :bs="{
      marginBottom: '20px',
      marginTop: '10px',
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
    }"
  >
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">

      <SearchInput
        v-model="state.filters.awardName"
        action-position="inner"
        placeholder="根据奖品名称搜索"
        @search="handleSearch"
      />
    </new-bs-box>
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
      <Button
        icon-before="add-m"
        @click="showCreateAwardModal({})"
      >新增奖品</Button>

    </new-bs-box>

  </new-bs-box>
  <BeerTable
    class="data-table"
    size="s"
    border-style="none"
    :columns="state.columns"
    :data="state.awardList"
  >
    <template #awardName="{ rowData,rowIndex }">
      <BeerInput
        v-if="state.editawardIndex === rowIndex"
        v-model="state.editawardName"
      />
      <span v-else>{{ rowData.awardName }}</span>

    </template>
    <template #num="{ rowData }">
      <CountInput
        v-model="rowData.num"
        :bs="{ mt: 0 }"
        max="10000"
        @update:modelValue="changeNum(rowData)"
      />
    </template>
    <template #action="{ rowData,rowIndex }">
      <Button
        v-if="state.editawardFlag===false"
        size="s"
        modifier="primary"
        @click.stop="handleEdit(rowData,rowIndex)"
      >编辑</Button>
      <Button
        v-if="state.editawardFlag===true"
        size="s"
        modifier="warn"
        @click.stop="handleSave(rowData)"
      >保存</Button>
      <Button
        v-if="state.editawardFlag===false"
        size="s"
        modifier="danger"
        @click.stop="handleDelete(rowData)"
      >删除</Button>
      <Button
        v-if="state.editawardFlag===true"
        size="s"
        variant="secondary"
        @click.stop="handleCancel()"
      >取消</Button>
    </template>

  </BeerTable>
  <div class="pagination-table">
    <Pagination
      v-model="state.pagination"
      align="flex-end"
      @update:modelValue="handlePagination"
    />
  </div>
  <EditAward
    :show="awardVisible"
    :data="awarddatainfo"
    :mode="awardmode"
    @close="hideAwardModal"
    @submit="handleAwardSubmit"
  />

</template>

<script setup lang="ts">
  import { reactive, onMounted } from 'vue'
  import {
    BeerTable,
    Button,
    NewBsBox,
    Pagination,
    toaster,
    SearchInput,
    BeerInput,
    CountInput,
    showModal,
    // Popover,
  } from '@xhs/yam-beer'

  import { useAwardModalForm } from '../../composables/useAwardModalForm'
  import EditAward from '../../components/Mange/EditAward.vue'
  import {
    getAwardList,
    AwardAdd,
    UpdateAwardNum,
    deleteAward,
    updateAwardName,
  } from '../../services/Award'
  //   import {
  //     getKey, ROLE_MAP, ROLE_OPTIONES,
  //   } from '~/utils/common'
  //   import { USERINFO } from '../../common'
  const {
    visible: awardVisible,
    data: awarddatainfo,
    awardmode,
    isAwardEditMode,
    showCreateAwardModal,
    // showAwardEditModal,

    hideAwardModal,
  } = useAwardModalForm()

  type IFilterConfig = {
    page: number
    pageSize: number
    awardName?: string
  }
  const state = reactive({
    filters: {
      awardName: '',
    },
    editawardIndex: -1,
    editawardName: '',
    editawardFlag: false,
    columns: [
      {
        title: '序号',
        key: 'id',

      },
      {
        title: '奖品名称',
        key: 'awardName',
        td: {
          is: 'awardName',
        },
      },
      {
        title: '奖品库存',
        key: 'num',
        td: {
          is: 'num',
        //   bs: {
        //     width: '80px',
        //   },
        },
      },

      {
        key: 'action',
        title: '操作',
        td: {
          is: 'action',
        },
      },

    ],
    awardList: [],
    // awardList : JSON.parse('[{"creator":"aaa","endTime":"2021-11-21","jobId":123,"jobName":"xxx","startTime":"2021-11-19","status":3,"version":"1.0.0"}]'),
    pagination: {
      pageNo: 1,
      pageSize: 10,
      total: 0,
    },

  })

  const handleSearch = async () => {
    const {
      awardName,
    } = state.filters
    const params: IFilterConfig = {
      page: state.pagination.pageNo,
      pageSize: state.pagination.pageSize,
    }
    if (awardName) params.awardName = awardName

    try {
      const res = await getAwardList(params)
      if (res.data.totalCount === 0) {
        toaster.warning('暂无数据！')
      }

      state.awardList = res.data.awardList // 后端进行分页
      state.pagination.total = res.data.totalCount
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const handlePagination = () => {
    handleSearch()
  }

  function handleAwardSubmit(data: any) {
    const {
      awardName,
    } = data

    const payload = {
      awardName,
      num: Number(data.num),
    }
    // console.log(JSON.stringify(payload))
    // if (isAwardEditMode.value) {
    //   updateRole(payload)
    //     .then(res => {
    //       if (res.code === 200) {
    //         toaster.success('设置角色成功！')
    //         handleSearch()
    //         hideAwardModal()
    //       } else {
    //         toaster.warning(res.msg)
    //       }
    //     })
    //     .catch(err => toaster.warning(err))
    // }
    if (!isAwardEditMode.value) {
      AwardAdd(payload)
        .then((res:any) => {
          if (res.code === 200) {
            toaster.success('新增礼品成功')
            handleSearch()
            hideAwardModal()
          } else {
            toaster.warning(res.msg)
          }
        })
        .catch(err => toaster.warning(err))
    }
  }

  // 更新bug状态
  const changeNum = async (rowData: { id: any;num:any }) => {
    // toaster.notify(`clicked  -----${JSON.stringify(rowData.status)}`)

    const params = {
      awardId: rowData.id,
      num: rowData.num,
    }
    try {
      const res = await UpdateAwardNum(params)
      if (res.code === 200) {
        toaster.success('更新奖品数目成功！')
        handleSearch()
      } else {
        toaster.warning(res.msg)
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const handleEdit = async (rowData: { id: number;awardName:string }, rowIndex:number) => {
    state.editawardIndex = rowIndex
    state.editawardName = rowData.awardName
    state.editawardFlag = true
  }

  const handleCancel = async () => {
    state.editawardIndex = -1
    state.editawardFlag = false
    state.editawardName = ''
  }

  const handleSave = async (rowData: { id: number;awardName:string }) => {
    const params = {
      awardId: rowData.id,
      awardName: state.editawardName,
    }
    try {
      const res = await updateAwardName(params)
      if (res.code === 200) {
        toaster.success('更新奖品名称成功！')
        handleSearch()
      } else {
        toaster.warning(res.msg)
      }
      state.editawardIndex = -1
      state.editawardFlag = false
      state.editawardName = ''
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const handleDelete = async (rowData: { id: string }) => {
    const awardId = rowData.id
    showModal({
      props: {
        type: 'dialog',
        title: '确定要删除该奖品么？',
        async handleConfirm({ close }) {
          try {
            const res = await deleteAward({ awardId })
            if (res.code === 200) {
              toaster.success('删除成功')
              close()
              handleSearch()
            } else {
              toaster.warning(res.msg)
            }
            state.editawardIndex = -1
            state.editawardFlag = false
            state.editawardName = ''
          } catch (error) {
            toaster.warning(error.message)
          }
        },
      },
    })
  }

  //   watch([() => state.filters.awardName], () => {
  //     handleSearch()
  //   })

  onMounted(() => {
    handleSearch()
  })
</script>

<style lang="stylus" scoped>
.data-table {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-table {
  margin-top: 24px;
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>
