import { http } from '@xhs/launcher'

export type BugInfo = {
  title: string
  version: string
  platform: number
  bugInfo: string
  deviceSystem: string
  deviceBrand:string
  deviceId:string
  attachmentList: []
  reporter:string
  reporterId:string
  createTime:string
  level:number
  priority:number
}

// bug列表
export function getBugs(payload: {
  page: number
  pageSize: number
}): Promise<any> {
  return http.post('BUG_LIST', payload)
}

// 新建缺陷
export function postApiBug(payload: {
  title: string
  bugInfo: string
  isRecur: number
  platform: number
  deviceSystem: string
}): Promise<any> {
  return http.post('BUG_ADD', payload, {
    extractData: false,
  })
}

// 转tapd
export function pushToTapd(payload: {
  id:number
  title:string
  bugInfo:string
  version:string
  platform:string
  deviceSystem:string
  operatorId:string
}): Promise<any> {
  return http.post('BUG_ADDTAPD', payload)
}

// 获取详情
export function getBugInfo(payload: {
  id: number
}): Promise<any> {
  return http.get('BUG_INFO', {
    params: payload,
  })
}

// 删除bug
export function deleteBug(payload: {
  bugId: string
}): Promise<any> {
  return http.get('BUG_DELETE', {
    params: payload,
  }, {
    extractData: false,
  })
}
// 更新bug任务状态
export function updateBug(payload: {
  bugId: string
  operatorId:string
}): Promise<any> {
  return http.post('BUG_UPDATE', payload, {
    extractData: false,
  })
}

// 标记重复
export function duplicateBug(payload: {
  bugId: string
  operatorId:string
}): Promise<any> {
  return http.post('BUG_DUPLICATE', payload, {
    extractData: false,
  })
}
// 标记有效
export function validBug(payload: {
  bugId: string
  operatorId:string
}): Promise<any> {
  return http.post('BUG_VAILD', payload, {
    extractData: false,
  })
}

// 获取统计数据分布
export function getbugStat(payload: {
  id: number
}): Promise<any> {
  return http.get('BUG_STAT', {
    params: payload,
  }, {
    extractData: false,
  })
}
// 给用户发消息pushContent
export function pushContent(payload: {
  toUser: string
  content:string
}): Promise<any> {
  return http.post('PUSH_CONTENT', payload, {
    extractData: false,
  })
}

// 获取历史消息
export function chatHistory(payload: {
  chatUid: string
  uid:string
  limit:string
  storeId:string
}): Promise<any> {
  return http.post('CHAT_HISTORY', payload, {
    extractData: false,
  })
}

// // MOCK
// export function getBugs(payload: {
//   page: number
//   pageSize: number
// }): Promise<any> {
//   return http.post('BUG_LIST_MOCK', payload)
// }
// //删除bug
// export function deleteBug(payload: {
//   bugId: string
// }): Promise<any> {
//   return http.get('BUG_DELETE_MOCK', {
//     params: payload,
//   }, {
//     extractData: false,
//   })
// }
// export function getBugInfo(payload: {
//   id: number
// }): Promise<any> {
//   return http.get('BUG_INFO_MOCK', {
//     params: payload,
//   })
// }
// export function chatHistory(payload: {
//   chatUid: string
//   uid:string
//   limit:string
//   storeId:string
// }): Promise<any> {
//   return http.post('CHAT_HISTORY_MOCK', payload, {
//     extractData: false,
//   })
// }
// export function getbugStat(payload: {
//   id: number
// }): Promise<any> {
//   return http.get('BUG_STAT_MOCK', {
//     params: payload,
//   },{
//     extractData: false,
//   })
// }
