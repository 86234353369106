<template>
  <beer-menu
    v-model:active-id="activeMenuId"
    :is-collapsible="true"
    :bs="bs"
    :list="newmenus"
    :handle-click="onClick"
    :init-expaned-on="initExpanedOn"
    :get-node-bs="getNodeBs"
    text-key="title"
    id-key="name"
  />

</template>

<script lang="ts">
  import {
    defineComponent, ref, onMounted, reactive, toRefs,
  } from 'vue'
  import { useRoute, useRouter, RouteRecordName } from 'vue-router'
  import { Menu as BeerMenu } from '@xhs/yam-beer'
  import { nodeType } from '@xhs/yam-beer/utils/transTree'
  import { supermenus, adminmenus, membermenus } from '../config/menus.config'
  // import { getUsers } from '../services/user'
  import {
    USERINFO,
  } from '~/common'

  export default defineComponent({
    name: 'MenuPanel',
    components: {
      BeerMenu,

    },
    props: {
      bs: {
        type: Object,
        default: () => ({}),
      },
      getNodeBs: {
        type: Function,
        default: () => ({}),
      },
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const activeMenuId = ref<RouteRecordName | null>(null)
      const state:any = reactive({
        newmenus: [],
      })

      onMounted(() => {
        if (USERINFO.role === 1) {
          state.newmenus = supermenus
        } else if (USERINFO.role === 2) {
          state.newmenus = adminmenus
          // console.log(JSON.stringify(state.newmenus))
        } else if (USERINFO.role === 3) {
          state.newmenus = membermenus
        }

        activeMenuId.value = route.name || null
        // window.console.log(`菜单======${USERINFO.role}`)
      })

      const onClick = ({ node }:{node: nodeType}) => {
        if (node.isLeaf) {
          router.push({ name: node.name })
        }
      }
      function initExpanedOn(item: any) {
        return item.expaned
      }

      return {
        ...toRefs(state),
        // menus: Object.freeze(state.newmenus),
        activeMenuId,
        onClick,
        initExpanedOn,
      }
    },
  })
</script>
