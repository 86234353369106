<template>
  <Panel
    :bs="{
      width: 800,
      mt: 72,
      mx: 'auto',
    }"
  >
    <Title :bs="{ mb: 32 }">啊哦，出了点问题</Title>
    <NewBsBox
      v-if="errMsg"
      :bs="{ display: 'table' }"
    >
      <TRow>
        <TLabel>错误消息</TLabel>
        <TCell :bs="{ color: 'danger' }">
          {{ errMsg || '-' }}
        </TCell>
      </TRow>
      <TRow>
        <TLabel>错误代码</TLabel>
        <TCell>{{ errMsg || '-' }}</TCell>
      </TRow>
      <TRow v-if="isDev">
        <TLabel>错误stack</TLabel>
        <TCell>{{ errMsg || '-' }}</TCell>
      </TRow>
    </NewBsBox>
    <BeerButton
      :bs="{
        mt: 40,
      }"
      :handle-click="backHome"
      size="l"
    >返回首页</BeerButton>
    <BeerButton
      :bs="{
        mt: 24
      }"
      :handle-click="logout"
      size="l"
      variant="secondary"
    >登出 porch</BeerButton>
  </Panel>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import {
    Panel,
    Title,
    NewBsBox,
    BeerButton,
    WithBsBox,
  } from '@xhs/yam-beer'
  import { logout } from '../../services/user'

  const TRow = WithBsBox({
    bs: {
      display: 'table-row',
    },
  })

  const TLabel = WithBsBox({
    bs: {
      display: 'table-cell',
      mr: 32,
    },
  })

  const TCell = WithBsBox({
    bs: {
      display: 'table-cell',
      pb: 16,
      maxWidth: 300,
      overflow: 'auto',
    },
  })

  export default defineComponent({
    name: 'SiteError',
    components: {
      Panel,
      Title,
      NewBsBox,
      BeerButton,
      TRow,
      TLabel,
      TCell,
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const isDev = computed(() => process.env.NODE_ENV !== 'production')
      const errMsg = computed(() => route.params.error)
      return {
        isDev,
        errMsg,
        logout,
        backHome() {
          router.push('/')
        },
      }
    },

  })
</script>
