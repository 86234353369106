<template>
  <new-bs-box
    :bs="{
      marginBottom: '20px',
      marginTop: '0px',
      justifyContent: 'flex-start',
      display: 'flex',
      alignItems: 'center',
    }"
  >
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
      <span>任务列表：</span>
      <single-select
        v-model="state.filters.jobId"
        placeholder="根据任务筛选"
        :options="state.jobNameList"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px',width:200 }"
      />
    </new-bs-box>

  </new-bs-box>

  <Tabs
    v-model="state.tabvalue"
    :bs="{ marginLeft: '10px'}"
    @update:modelValue="handleSelectTab"
  >
    <TabPane
      name="1"
      label="内网排名"
    >
      <div class="container">
        <Switch
          v-model="state.innerValue"
          autofocus
        />
        <span style="margin-left: 10px;margin-right: 10px,">部门排行榜</span>
        <div style="margin-left: 10px;width:200px">
          <!-- <Checkbox
            v-model:checked="state.rdChecked"
            value="rdRank"
            label="仅开发"
          /> -->
          <input
            id="test"
            v-model="state.rdChecked"
            type="checkbox"
          >
          <label
            for="test"
            style="white-space: nowrap;"
          >仅开发</label>
        </div>
      </div>
      <div v-if="state.innerValue===false">
        <BeerTable
          class="data-table"
          size="s"
          border-style="none"
          :columns="state.columnsinuser"
          :data="state.ScoreListInuser"
        >

          <template #rank="{ rowData,rowIndex }">
            <span v-if="state.paginationInuser.pageNo==1&&rowIndex+1 ==1"><img
              class="manImg"
              src="./../../static/rangking_l_1.png"
            ></span>
            <span v-else-if="state.paginationInuser.pageNo==1&&rowIndex+1 ==2"><img
              class="manImg"
              src="./../../static/rangking_l_2.png"
            > </span>
            <span v-else-if="state.paginationInuser.pageNo==1&&rowIndex+1 ==3"><img
              class="manImg"
              src="./../../static/rangking_l_3.png"
            > </span>
            <span
              v-else
              class="no"
            >{{ rowData.rank }} </span>

          </template>
          <template #nickname="{ rowData }">
            <Tag
              color="green"
              size="small"
            >{{ rowData.nickname }}</Tag>
          </template>

        </BeerTable>
        <div class="pagination-table">
          <Pagination
            v-model="state.paginationInuser"
            align="flex-end"
            @update:modelValue="handlePaginationInuser"
          />
        </div>
      </div>
      <div
        v-show="state.innerValue"
        id="departChart"
        :style="{ margin: 0, height: '500px' }"
      />
      <!-- <div v-else-if="innerValue===true">111111111111</div> -->
    </TabPane>
    <TabPane
      name="2"
      label="外网排名"
    >
      <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
        <SearchInput
          v-model="state.filters.user"
          action-position="inner"
          placeholder="根据用户名或userid搜索"
          @search="handleSearch"
        />
      </new-bs-box>
      <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }" />
      <BeerTable
        class="data-table"
        size="s"
        border-style="none"
        :columns="state.columns"
        :data="state.ScoreList"
      >

        <template #rank="{ rowData,rowIndex }">
          <span v-if="state.pagination.pageNo==1&&rowIndex+1 ==1"><img
            class="manImg"
            src="./../../static/rangking_l_1.png"
          ></span>
          <span v-else-if="state.pagination.pageNo==1&&rowIndex+1 ==2"><img
            class="manImg"
            src="./../../static/rangking_l_2.png"
          > </span>
          <span v-else-if="state.pagination.pageNo==1&&rowIndex+1 ==3"><img
            class="manImg"
            src="./../../static/rangking_l_3.png"
          > </span>
          <span
            v-else
            class="no"
          >{{ rowData.rank }} </span>

        </template>

      </BeerTable>
      <div class="pagination-table">
        <Pagination
          v-model="state.pagination"
          align="flex-end"
          @update:modelValue="handlePagination"
        />
      </div>
    </TabPane>
  </Tabs>

</template>

<script setup lang="ts">
  import {
    reactive, watch, onMounted,
  } from 'vue'
  import {
    BeerTable,
    NewBsBox,
    Pagination,
    toaster,
    SearchInput,
    SingleSelect,
    Tabs,
    TabPane,
  } from '@xhs/yam-beer'
  import { Switch, Tag } from '@xhs/delight'

  import * as echarts from 'echarts'
  import { getjobnameList } from '../../services/Job'
  import { scoreRank, staffRank, departRank } from '../../services/Award'

  //   import { getKey } from '~/utils/common'

  type IFilterConfig = {
    page: number
    pageSize: number
    user?: string
    jobId?:number
  }
  const rdDep = ['社区前端组', '基础体验技术组', '社区后端组', '内容发布组', '社交产品组', '增长技术组']
  const reportdata = reactive({
    barNameList: [],
    barValList: [],
    totalScore: 0,
  })

  const state = reactive({
    rdChecked: false,
    innerValue: false,
    tabvalue: '1',
    filters: {
      user: '',
      jobId: 0,
    },
    jobNameList: [],
    columns: [
      {
        title: '排名',
        key: 'rank',
        td: {
          is: 'rank',
        },
      },
      {
        title: '用户名',
        key: 'userName',
      },
      // {
      //   title: 'userId',
      //   key: 'userId',
      // },
      {
        title: 'bug总数',
        key: 'totalBug',
      },
      {
        title: '有效bug',
        key: 'validBug',
      },
      {
        title: '积分',
        key: 'score',
      },

    ],
    columnsinuser: [
      {
        title: '排名',
        key: 'rank',
        td: {
          is: 'rank',
        },
      },
      {
        title: '薯名',
        key: 'nickname',
        td: {
          is: 'nickname',
        },
      },
      {
        title: '部门名称',
        key: 'userName',
      },
      // {
      //   title: 'userId',
      //   key: 'userId',
      // },
      {
        title: 'bug总数',
        key: 'totalBug',
      },
      {
        title: '有效bug',
        key: 'validBug',
      },
      {
        title: '积分',
        key: 'score',
      },

    ],

    ScoreList: [],
    ScoreListInuser: [],
    departData: {},
    // ScoreList : JSON.parse('[{"rank":40,"userId":"55b6dbf8b7ba2222be22673d","userName":"池小云呐","totalBug":13,"validBug":0,"score":0},{"rank":40,"userId":"55fc20f25894467ea36ef9c2","userName":"咖啡","totalBug":1,"validBug":0,"score":0},{"rank":40,"userId":"5609f0c558944613dcb590e3","userName":"喵呜の宝藏","totalBug":1,"validBug":0,"score":0},{"rank":40,"userId":"562c866ef53ee06d8baa876c","userName":"鲸推求职","totalBug":1,"validBug":0,"score":0},{"rank":40,"userId":"563082d19eb57834e02870f8","userName":"小糖果Anthea","totalBug":1,"validBug":0,"score":0},{"rank":40,"userId":"573e7a9c82ec390ad5f5ead8","userName":"爱笑的土豆","totalBug":1,"validBug":0,"score":0},{"rank":40,"userId":"5903342650c4b47a26724045","userName":"嗯","totalBug":1,"validBug":0,"score":0},{"rank":40,"userId":"593ad3c950c4b47077c386ea","userName":"八达创意互动科技","totalBug":4,"validBug":0,"score":0},{"rank":40,"userId":"5a79c63f4eacab6d22a595da","userName":"芋泥吃我－","totalBug":1,"validBug":0,"score":0},{"rank":40,"userId":"5ad6b2dab1da145eff4889f2","userName":"木子一一","totalBug":3,"validBug":0,"score":0}]'),
    pagination: {
      pageNo: 1,
      pageSize: 10,
      total: 0,
    },
    paginationInuser: {
      pageNo: 1,
      pageSize: 10,
      total: 0,
    },
    departmentoption: {
      title: {
        text: '部门排行榜',
        subtext: '积分 = 有效bug数*10',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter(param: any) {
          return [
            `${param[0].name
            }：<br/>积分=有效bug*10<br/>积分（${
              param[0].value
            }）<br/>有效bug数（${
              ((param[0].value / 10))
            }）`,
          ].join('<br/>')
        },
      },
      xAxis: {
        type: 'category',
        data: reportdata.barNameList,
        axisLabel: {
          interval: 0,
          rotate: 30, // 旋转角度
          fontSize: 10,
        },
      },
      grid: { // 控制图的大小，调整下面这些值就可以，
        x: 40,
        x2: 100,
        y2: 150, // y2可以控制 X轴跟Zoom控件之间的间隔，避免以为倾斜后造成 label重叠到zoom上
      },
      yAxis: {
        type: 'value',
      },
      color: '#FFCE34',
      series: [
        {
          data: reportdata.barValList,
          type: 'bar',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
          barMaxWidth: 30,
          itemStyle: {

            normal: {
              label: {
                show: true, // 开启显示
                position: 'inside', // 在上方显示
                textStyle: { // 数值样式
                  color: 'black',
                  fontSize: 12,
                },
              },

              // // 随机显示
              // color() {
              //   return `#${Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16)}`
              // },

              // 定制显示（按顺序）
              color(params) {
                const colorList = ['#C33531', '#EFE42A', '#64BD3D', '#EE9201', '#29AAE3', '#B74AE5', '#0AAF9F', '#E89589', '#16A085', '#4A235A', '#C39BD3 ', '#F9E79F', '#BA4A00', '#ECF0F1', '#616A6B', '#EAF2F8', '#4A235A', '#3498DB']
                return colorList[params.dataIndex]
              },
            },
          },
        },
      ],
    },

  })

  // barSort

  const handlenameList = async () => {
    try {
      const res = await getjobnameList()
      const list = res.list
      list.sort((a, b) => b.value - a.value)
      state.jobNameList = list
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const handleSearch = async () => {
    const {
      user, jobId,
    } = state.filters
    const params: IFilterConfig = {
      page: state.pagination.pageNo,
      pageSize: state.pagination.pageSize,
    }
    if (user) params.user = user
    if (jobId) params.jobId = jobId
    try {
      const res = await scoreRank(params)
      if (res.data.totalCount === 0) {
        toaster.warning('暂无数据！')
      }

      state.ScoreList = res.data.userScoreRankList // 后端进行分页
      state.pagination.total = res.data.totalCount
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const handleStaff = async () => {
    const {
      jobId,
    } = state.filters
    const params: IFilterConfig = {
      page: state.pagination.pageNo,
      pageSize: state.pagination.pageSize,
    }

    if (jobId) params.jobId = jobId
    try {
      const res = await staffRank(params)
      if (res.data.totalCount === 0) {
        toaster.warning('暂无数据！')
      }

      state.ScoreListInuser = res.data.userScoreRankList // 后端进行分页
      state.pagination.total = res.data.totalCount
    } catch (error) {
      toaster.warning(error.message)
    }
  }
  const handlebarShow = async () => {
    const {
      jobId,
    } = state.filters
    const params: IFilterConfig = {
      page: state.pagination.pageNo,
      pageSize: state.pagination.pageSize,
    }

    if (jobId) params.jobId = jobId
    try {
      const res = await departRank(params)
      // let res = {"code":200,"msg":"success","data":{"业务技术部/商业技术部/商业平台组/CRM数据开发组":10,"业务技术部/社区技术部/业务体验技术组":10,"业务技术部/交易技术部/交易客户端组":10,"业务技术部/社区技术部/业务体验技术组/基础体验技术组":10,"业务技术部/社区技术部/智能创作组/编辑组":20,"产品部/体验设计部/i18n设计组":20,"业务技术部/社区技术部/业务体验技术组/社区后端组":30,"业务技术部/社区技术部/业务体验技术组/社区前端组":30,"业务技术部/社区技术部/业务应用技术组/内容发布组":30,"业务技术部/社区技术部/业务应用技术组/社交产品组":110}}
      const obj = res.data
      const arr = Object.entries(obj).sort((a, b) => b[1] - a[1])
      const sortedObj = Object.fromEntries(arr)

      if (!state.rdChecked) {
        state.departData = sortedObj
        reportdata.barNameList = Object.keys(state.departData)
        reportdata.barValList = Object.values(state.departData)

        // 取到信息后再初始化echart
        state.departmentoption.xAxis.data = reportdata.barNameList
        state.departmentoption.series[0].data = reportdata.barValList
        reportdata.totalScore = reportdata.barValList.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

        if (document.getElementById('departChart')!.hasAttribute('_echarts_instance_')) {
          document.getElementById('departChart')!.removeAttribute('_echarts_instance_')
        }
        const mystatChart = echarts.init(document.getElementById('departChart')!)
        mystatChart.clear()
        mystatChart.setOption(state.departmentoption, true)
        window.onresize = function () {
          mystatChart.resize()
        }
      } else {
        // 遍历对象属性，并判断是否在目标数组中
        const newObj = {}
        const keys = Object.keys(sortedObj)

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          const arrKey = key.split('/')
          if (rdDep.includes(arrKey[arrKey.length - 1])) {
            newObj[key] = sortedObj[key]
          }
        }
        state.departData = newObj
        reportdata.barNameList = Object.keys(state.departData)
        reportdata.barValList = Object.values(state.departData)

        // 取到信息后再初始化echart
        state.departmentoption.xAxis.data = reportdata.barNameList
        state.departmentoption.series[0].data = reportdata.barValList
        reportdata.totalScore = reportdata.barValList.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

        if (document.getElementById('departChart')!.hasAttribute('_echarts_instance_')) {
          document.getElementById('departChart')!.removeAttribute('_echarts_instance_')
        }
        const mystatChart = echarts.init(document.getElementById('departChart')!)
        mystatChart.clear()
        mystatChart.setOption(state.departmentoption, true)
        window.onresize = function () {
          mystatChart.resize()
        }
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const handleSelectTab = () => {
    if (state.tabvalue === '2') {
      handleSearch()
    } else {
      handleStaff()
      handlebarShow()
    }
  }

  const handlePagination = () => {
    handleSearch()
  }
  const handlePaginationInuser = () => {
    handleStaff()
  }

  watch([() => state.filters.jobId], () => {
    handleSearch()
    handleStaff()
    handlebarShow()
  })

  watch([() => state.innerValue], () => {
    // console.log("state.innerValue",state.innerValue)
    if (state.innerValue) {
      handlebarShow()
    } else {
      state.rdChecked = false
    }
  })
  watch([() => state.rdChecked], () => {
    console.log('state.rdChecked', state.rdChecked)
    handlebarShow()
  })

  onMounted(() => {
    handlenameList()
    handleSearch()
    handleStaff()

    // let str = '{"业务技术部/社区技术部/业务体验技术组/社区后端组":30,"业务技术部/商业技术部/商业平台组/CRM数据开发组":10,"业务技术部/社区技术部/业务体验技术组":10,"业务技术部/社区技术部/业务体验技术组/社区前端组":30,"业务技术部/社区技术部/业务应用技术组/内容发布组":30,"业务技术部/社区技术部/智能创作组/编辑组":20,"业务技术部/交易技术部/交易客户端组":10,"产品部/体验设计部/i18n设计组":20,"业务技术部/社区技术部/业务应用技术组/社交产品组":60,"平台部/内容质量中心/安全审核/重要人物审核组/重要人物笔记1小组":10}'
    //   state.departData = JSON.parse(str)
    //   reportdata.barNameList = Object.keys(state.departData)
    //   reportdata.barValList = Object.values(state.departData)
    //   // 取到信息后再初始化echart
    //   state.departmentoption.xAxis.data = reportdata.barNameList
    //   state.departmentoption.series[0].data = reportdata.barValList
    //   reportdata.totalScore = reportdata.barValList.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    //   console.log("inner----",state.innerValue)
  })
</script>

<style lang="stylus" scoped>
.data-table {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-table {
  margin-top: 24px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.manImg{
width: 30px;
height: 25px;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}
</style>
