import { HttpConfig } from '@xhs/launcher'
import { getPorchHost } from '~/common'

const httpConfig: HttpConfig = {
  BASE_URL: {
    // development: 'https://logan.devops.xiaohongshu.com/proxy/testin',
    development: 'http://127.0.0.1:3000', // mock
    production: '',
    test: '',
  },
  BASE_CONFIG: {
    defaults: {
      transform: false,
      timeout: 30000,
    },
    development: {
      withCredentials: true,
    },
    test: {
      withCredentials: true,
    },
  },
  API_LIST: {
    // LOGIN_SSO: '/api/testin/user/login',
    LOGOUT: '/api/testin/user/logout',
    USER_INFO: '/api/testin/user/login/getuserinfo',
    CLEAR_CUSTOMER_COOKIE: `${getPorchHost()}/api/cas/clearSsoCookie`,
    VALIDATE: '/api/testin/user/validate/token',
    GET_USERS: '/api/testin/user/getUsers',
    UPDATE_ROLE: '/api/testin/user/update/role',

    // 任务api
    JOB_LIST: '/api/testin/job/getjoblist',
    VERSION_LIST: '/api/testin/job/getversionlist',
    JOB_ADD: '/api/testin/job/add',
    JOB_DELETE: '/api/testin/job/delete',
    JOB_TEST: '/api/testin/job/getlist',
    JOB_INFO: '/api/testin/job/getjobinfo',
    JOB_UPDATE: '/api/testin/job/updateinfo',
    JOB_NAMELIST: '/api/testin/job/getjobnamelist',
    UPDATE_STATUS: '/api/testin/job/updatestatus',
    BUG_STAT: '/api/testin/job/bugStat',
    STAT_INFO: '/api/testin/job/statinfo',
    GETSTAT_BUG: '/api/testin/job/statbugs',
    PUSH_GROUP: '/api/testin/job/pushgroup',

    // 缺陷
    BUG_ADD: '/api/testin/bug/add',
    BUG_ADDTAPD: '/api/testin/bug/pushToTapd',
    BUG_LIST: '/api/testin/bug/getbugs',
    BUG_INFO: '/api/testin/bug/getbuginfo',
    BUG_DELETE: '/api/testin/bug/delete',
    BUG_UPDATE: '/api/testin/bug/update',
    BUG_DUPLICATE: '/api/testin/bug/duplicate',
    BUG_VAILD: '/api/testin/bug/valid',
    PUSH_CONTENT: '/api/testin/bug/connect',
    CHAT_HISTORY: '/api/testin/bug/chathistory',

    // 礼物
    AWARD_LIST: '/api/testin/award/getAwardList',
    AWARD_ADD: '/api/testin/award/add',
    UPDATE_AWARD_NUM: '/api/testin/award/updateNum',
    AWARD_DELETE: '/api/testin/award/delete',
    UPDATE_AWARD_NAME: '/api/testin/award/updateName',
    AWARDNAME_LIST: '/api/testin/award/getValidAwardList',

    // 奖励记录
    AWARDLOG_LIST: '/api/testin/reward/getExchangeLogs',
    AWARD_ADD_USER: '/api/testin/reward/users',
    AWARD_USER_SCORERANK: '/api/testin/reward/scoreRank',
    AWARD_USER_STAFFRANK: '/api/testin/bug/staffrank',
    AWARD_USER_DEPRANK: '/api/testin/bug/departrank',

    // 设备
    BRAND_LIST: '/api/testin/device/getDeviceList',
    BRAND_ADD: '/api/testin/device/add',
    BRAND_DELETE: '/api/testin/device/delete',
    // BRAND_STAT: '/api/testin/device/getdevicestat',
    DEVICE_LIST: '/api/testin/device/getDeviceNameList',
    // 白名单管理
    WHITE_LIST: '/api/testin//white/getWhiteInfoList',
    WHITE_DELETE: '/api/testin/white/delete',
    WHITE_ADD: '/api/testin/white/add',
    WHITE_INFO: '/api/testin/white/searchWhiteId',
    WHITE_UPDATE: '/api/testin/white/update',

    // mock
    BUG_LIST_MOCK: '/mock/11/bug/getbugs',
    // LOGOUT: '/api/user/logout',
    BUG_INFO_MOCK: '/mock/11/bug/getbuginfo',
    CHAT_HISTORY_MOCK: '/mock/11/bug/chathistory',
    BUG_STAT_MOCK: '/mock/11/job/bugStat',
    // MOCKTEST:'/mock/11/job/mocktest'
    JOB_INFO_MOCK: '/mock/11/job/getjobinfo',
    JOB_LIST_MOCK: '/mock/11/job/getjoblist',
    // JOB_NAMELIST_MOCK: '/mock/11/job/getjobnamelist',
    // BUG_DELETE_MOCK:'/mock/11/bug/delete',
    // GET_USERS_MOCK:'/mock/11/user/getUsers',
    // UPDATE_ROLE_MOCK:'/mock/11/user/update',
    // AWARD_LIST_MOCK: '/mock/11/award/getawardlist',
    // AWARDNAME_LIST_MOCK:'/mock/11/award/getValidAwardList',
    // BRAND_STAT_MOCK: '/mock/11/device/getdevicestat',
    // DEVICE_LIST_MOCK:'/mock/11/device/getDeviceNameList'
    // STAT_INFO_MOCK: '/mock/11/user/getUsers',
    STAT_INFO_MOCK: '/mock/11/job/statinfo',
    GETSTAT_BUG_MOCK: '/mock/11/job/statbugs',
    WHITE_LIST_MOCK: '/mock/11/white/getWhiteInfoList',
    /// ///
  },
}

export default httpConfig
