import { ref, computed } from 'vue'
import { useModal } from './useModal'

export const FORM_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
}

export const useBrandModalForm:any = (init?:any):any => {
  const initBrandData = init === undefined ? {} : init

  const brandmode = ref('')
  const data = ref<any>(initBrandData)
  const isBrandEditMode = computed(() => brandmode.value === FORM_MODE.EDIT)

  const {
    visible,
    // bugvisible,
    toggleModal,
  } = useModal()

  function showCreateBrandModal(newData: any) {
    //   // 父组件信息处理
    //   const newBugData = {
    //     //   jobName: newData.jobName,
    //     version: newData.version,
    //     jobId: newData.id,
    //     platform: newData.type,
    //   }

    //   data.value = newBugData
    window.console.log('222')
    window.console.log(JSON.stringify(newData))
    data.value = newData
    brandmode.value = FORM_MODE.CREATE
    toggleModal(true)
  }

  function showBrandEditModal(newData: any) {
    // // 测试区间给testTime处理
    // newData.testTime = {
    //   start: formatDate(newData.startTime),
    //   end: formatDate(newData.endTime),
    // }

    data.value = newData
    brandmode.value = FORM_MODE.EDIT
    toggleModal(true)
  }

  function hideBrandModal() {
    data.value = initBrandData
    toggleModal(false)
  }

  return {
    visible,
    data,
    brandmode,
    isBrandEditMode,
    showCreateBrandModal,
    showBrandEditModal,
    hideBrandModal,
  }
}
