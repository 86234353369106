<template>
  <!-- <ThemeProvider> -->
  <TopBar title="众测平台">
    <template #right>
      <!-- <TopMenuPanel /> -->
      <BeerAvatar
        v-if="USERINFO.avatar"
        :src="USERINFO.avatar"
      />
      <Dropdown
        :label="USERINFO.nickName?USERINFO.nickName:&quot;路人&quot;"
        :options="optionsList"
        variant="text"
        trigger="hover"
        :show-arrow="false"
        @click:option="onclick"
      />

    </template>
  </TopBar>

  <NewBsBox
    :bs="{
      my: '3x',
      mx: 'auto',

      maxWidth: ['100%'],
      height: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      padding: '30px',
      margin: 0,
    }"
    as="main"
  >
    <MenuPanel :bs="{ mr: 20, boxShadow: 'level1' }" />
    <NewBsBox
      class="page-content-container"
      :bs="{
        flex: 1,
        bg: 'white',

        borderRadius: 'card',
        boxShadow: 'level1',
        alignSelf: 'stretch',
        minHeight: 662,
        width:1244,//小屏刚好
        boxSizing: 'border-box',
      }"
    >
      <router-view />
    </NewBsBox>
  </NewBsBox>
  <!-- </ThemeProvider> -->
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    // ThemeProvider,
    Dropdown,
    NewBsBox,
    TopBar,
    BeerAvatar,
  } from '@xhs/yam-beer'
  import businessTheme from '@xhs/yam-beer/theme/businessTheme'
  import MenuPanel from '../components/MenuPanel.vue'
  import {
    logout,
  } from '../services/user'
  import { USERINFO } from '../common'

  export default defineComponent({
    name: 'AppLayout',

    components: {
      Dropdown,
      // ThemeProvider,
      MenuPanel,
      TopBar,
      NewBsBox,
      BeerAvatar,
    },

    setup() {
      return {
        businessTheme,
        optionsList: [
          '退出登陆',
        ],
        USERINFO,

      }
    },

    computed: {
      // user() {
      //   // return this.$auth.userInfo
      // },
    },
    methods: {
      onclick() {
        // 调用登出接口
        logout()
      },
    },

  })
</script>
