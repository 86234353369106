import { LauncherOptions } from '@xhs/launcher'
import AppLayout from '../layouts/AppLayout.vue'
import Home from '../pages/home/Home.vue'
import SiteError from '../pages/SiteError/SiteError.vue'
import Table from '../pages/list/Table.vue'
import Form from '../pages/form/Form.vue'
import JobPage from '../pages/Job/JobPage.vue'

import JobDetail from '../pages/Job/JobDetail.vue'
import JobReport from '../pages/Job/JobReport.vue'
import DefectPage from '../pages/Bug/DefectPage.vue'
import BugDetail from '../pages/Bug/BugDetail.vue'
// import ReportList from '../pages/Report/List.vue'
import AwardList from '../pages/Award/List.vue'
import AwardAdd from '../pages/Award/Add.vue'
import AwardRank from '../pages/Award/Rank.vue'
import UserPage from '../pages/User/UserPage.vue'

import ManageGift from '../pages/Manage/Gift.vue'
import ManageBrand from '../pages/Manage/Brand.vue'
import ManageWhite from '../pages/Manage/White.vue'

// import ReportPage from '../pages/Report/ReportPage.vue'
// import ReportPage from '../pages/Report/ReportPage.vue'
import StatInfo from '../pages/Stat/StatInfo.vue'

import { toPorch } from '../sso'

const NeedLoginMeta = {
  auth: {
    check: ['isLogin'],
    fallback: () => toPorch({ retry: true }),
  },
}

const routes: LauncherOptions['routes'] = [
  {
    path: '/',
    redirect: '/jobpage',
  },
  {
    path: '/site-error',
    name: 'SiteError',
    component: SiteError,
  },
  {
    path: '/',
    name: 'AppLayout',
    meta: NeedLoginMeta, // 登陆
    component: AppLayout,
    children: [
      {
        name: 'TablePage',
        path: 'table',

        meta: {
          title: '表格',
          icon: '',
        },
        component: Table,
      },
      {
        name: 'FormPage',
        path: 'form',
        meta: {
          title: '表单',
        },
        component: Form,
      },
      {
        name: 'Home',
        path: 'home',
        meta: {
          title: '主页',
        },
        component: Home,
      },
      {
        name: 'JobPage',
        path: 'jobpage',
        meta: {
          title: '众测任务',
          icon: '',
          instanceId: 'JobPage',
        },
        component: JobPage,

      },
      {
        path: 'jobdetail/:id',
        name: 'JobDetail',
        component: JobDetail,
        meta: {
          instanceId: 'JobDetail',
          title: '任务详情',
        },
      },
      {
        path: 'jobreport/:id',
        name: 'JobReport',
        component: JobReport,
        meta: {
          instanceId: 'JobReport',
          title: '报告详情',
        },
      },
      {
        name: 'DefectPage',
        path: 'defectpage',
        meta: {
          title: '缺陷列表',
          // icon: '',
          // instanceId: 'BugPage',
        },
        component: DefectPage,
      },
      {
        path: 'defectpage/:id',
        name: 'BugDetail',
        component: BugDetail,
        meta: {
          instanceId: 'BugDetail',
          title: 'Bug详情',
        },
      },
      {
        path: 'awardlist',
        name: 'AwardList',
        component: AwardList,
        meta: {
          instanceId: 'AwardList',
          title: '奖励列表',
        },
      },
      {
        path: 'awardadd',
        name: 'AwardAdd',
        component: AwardAdd,
        meta: {
          instanceId: 'AwardAdd',
          title: '奖励发放',
        },
      },
      {
        path: 'awardrank',
        name: 'AwardRank',
        component: AwardRank,
        meta: {
          instanceId: 'AwardRank',
          title: '奖励排名',
        },
      },
      {
        name: 'StatInfo',
        path: 'statinfo',
        meta: {
          title: 'Dashboard',
          icon: '',
          instanceId: 'StatInfo',
        },
        component: StatInfo,
      },
      {
        name: 'UserPage',
        path: 'userpage',
        meta: {
          title: '用户管理',
          icon: '',
          instanceId: 'UserPage',
        },
        component: UserPage,
      },
      {
        name: 'ManageGift',
        path: 'managegift',
        meta: {
          title: '礼物管理',
          icon: '',
          instanceId: 'ManageGift',
        },
        component: ManageGift,
      },
      {
        name: 'ManageBrand',
        path: 'managebrand',
        meta: {
          title: '品牌配置',
          icon: '',
          instanceId: 'ManageBrand',
        },
        component: ManageBrand,
      },
      {
        name: 'ManageWhite',
        path: 'managewhite',
        meta: {
          title: '白名单管理',
          icon: '',
          instanceId: 'ManageWhite',
        },
        component: ManageWhite,
      },

    ],
  },

]

export default routes
