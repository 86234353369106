<template>
  <!-- <new-bs-box :bs="{ marginBottom: '20px',marginTop:'15px',marginRight: '20px', justifyContent: 'flex-end', display: 'flex', alignItems: 'center' }"> -->
  <NewBsBox
    :bs="{
      padding: 24,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    }"
  >
    <!-- <new-bs-box :bs="{ marginLeft: '20px',marginTop:'7px' }"> -->
    <NewBsBox
      :bs="{ flex: '30%', justifyContent: 'flex-start', display: 'flex' }"
    >
      <Button
        icon-before="add-m"
        @click="handleTapdBatch({})"
      >批量转pingcode</Button>
      <Button
        icon-before="delete"
        modifier="danger"
        @click="handleDeleteBatch({})"
      >批量删除</Button>
      <!-- <Button
        icon-before="add-m"
        @click="test({})"
      >cesgu</Button> -->

    </NewBsBox>
    <NewBsBox
      :bs="{ flex: '70%', justifyContent: 'flex-end', display: 'flex',marginTop:'7px' }"
    >
      <!-- <NewBsBox> -->
      <single-select
        v-model="state.filters.valid"
        placeholder="根据是否有效筛选"
        :options="duplicateoptions"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px',width:160 }"
      />

      <single-select
        v-model="state.filters.priority"
        placeholder="根据业务筛选"
        :options="priorityoptions"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px',width:150 }"
      />
      <single-select
        v-model="state.filters.level"
        placeholder="根据严重等级筛选"
        :options="leveloptions"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px',width:160 }"
      />
      <single-select
        v-model="state.filters.status"
        placeholder="根据状态筛选"
        :options="typeoptions"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px',width:140 }"
      />
      <!-- </NewBsBox> -->

      <!-- <NewBsBox> -->
      <single-select
        v-model="state.filters.platform"
        placeholder="根据平台类型筛选"
        :options="TYPE_OPTIONES"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px',width:160 }"
      />

      <single-select
        v-model="state.filters.jobId"
        placeholder="根据任务筛选"
        :options="state.jobNameList"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px',width:140 }"
      />
      <SearchInput
        v-model="state.filters.title"
        action-position="inner"
        placeholder="根据bug标题筛选"
        :bs="{ mr: '10px',width:220 }"
        @search="handleSearch"
      />
    </NewBsBox>

    <!-- </NewBsBox> -->
  </NewBsBox>

  <beer-table-with-checkbox
    class="bug-table"
    :checked-rows="state.checkedRows"
    :columns="state.columns"

    :data="state.bugList"
  >
    <template #title="{ rowData }">
      <span style="minwidth: 250px;">{{ rowData.title }}</span>
    </template>
    <template #caseId="{ rowData }">
      <BeerInput
        v-model="rowData.caseId"
        :bs="{width:100,ml:-10}"
        placeholder="用例caseid"
        @keydown.enter="updateCaseId(rowData)"
      />
    </template>
    <template #priority="{ rowData }">
      <SingleSelect
        v-model="rowData.priority"
        :bs="{width:180,ml:-10}"
        :options="priorityoptions"
        @update:modelValue="changePriority(rowData)"
      />
    </template>
    <template #level="{ rowData }">
      <SingleSelect
        v-model="rowData.level"
        :bs="{width:75,ml:-10,}"
        :options="leveloptions"
        @update:modelValue="changeLevel(rowData)"
      />
    </template>

    <template #status="{ rowData }">
      <SingleSelect
        v-model="rowData.status"
        :bs="{width:90,ml:-10,}"
        :options="typeoptions"
        @update:modelValue="changeStatus(rowData)"
      />
    </template>

    <template #showdetail="{ rowData }">
      <!-- <NewBsBox :bs="{width:40}">  -->
      <Button
        style="margin-right: 3; min-width: 20px"
        size="s"
        variant="outline"
        @click="jumpToDetail(rowData)"
      >查看</Button>
      <!-- </NewBsBox> -->
      <Button
        size="s"
        modifier="danger"
        style="margin-right: 0; min-width: 20px"
        @click.stop="handleDelete(rowData)"
      >删除</Button>

    </template>
    <template #action="{ rowData }">
      <Button
        size="s"
        modifier="warn"
        style="margin-right: 0; width: 100px"
        :disabled="rowData.status !== 1"
        @click="handleTapd(rowData)"
      >转pingcode</Button>
    </template>
    <template #tapdLink="{ rowData }">
      <Button
        style="margin-left: -15px;margin-right: 0px; minwidth: 30px; padding: 0px"
        variant="link"
        :disabled="rowData.status === 1||rowData.tapdLink === null"
        @click="jumpTapd(rowData)"
      >pincodelink</Button>
    </template>
    <template #duplicate="{ rowData }">
      <SingleSelect
        v-model="rowData.duplicate"
        :bs="{width:60}"
        :options="duplicateoptions"
        @update:modelValue="changeDuplicate(rowData)"
      />
    </template>
    <template #valid="{ rowData }">
      <SingleSelect
        v-model="rowData.valid"
        :bs="{width:60}"
        :options="duplicateoptions"
        @update:modelValue="changeValid(rowData)"
      />
    </template>

  </beer-table-with-checkbox>

  <div class="pagination-table">
    <Pagination
      v-model="state.pagination"
      align="flex-end"
      @update:modelValue="handlePagination"
    />
  </div>
</template>

<script setup lang="ts">
  import {
    reactive, onMounted, watch,
  } from 'vue'
  // import { useRouter } from 'vue-router'

  import {
    toaster,
    Button,
    Pagination,
    NewBsBox,
    SingleSelect,
    SearchInput,
    BeerTableWithCheckbox,
    showModal,
    BeerInput,
  } from '@xhs/yam-beer'
  // import {
  //   SingleSelect,
  // } from '@beer'

  import {
    getBugs, deleteBug, updateBug, duplicateBug,
    validBug,
  } from '../../services/Bug'
  import { getjobnameList } from '../../services/Job'
  import { TYPE_MAP, getKey, TYPE_OPTIONES } from '~/utils/common'
  import {
    USERINFO,
  } from '../../common'

  type IFilterConfig = {
    page: number
    pageSize: number
    title?: string
    jobId?: string
    status?: number
    platform?: number
    priority?:number
    level?:number
    valid?:number
  }

  const typeoptions = [
    { name: '未处理', value: 1 },
    { name: '已转', value: 2 },
    { name: '已关闭', value: 3 },
  ]
  const leveloptions = [
    { name: '空', value: 1 },
    { name: '建议', value: 2 },
    { name: '提示', value: 3 },
    { name: '一般', value: 4 },
    { name: '严重', value: 5 },
    { name: '致命', value: 6 },
  ]

  const priorityoptions = [
    { name: '空', value: 1 },
    { name: '首页/推荐/附近/频道/搜索', value: 2 },
    { name: '消息/私信/群聊/推送', value: 3 },
    { name: '发布笔记/创作', value: 4 },
    { name: '图文笔记/评论/点赞/关注', value: 5 },
    { name: '视频', value: 6 },
    { name: '个人页/他人页/个人中心', value: 7 },
    { name: '直播', value: 8 },
    { name: '购物/电商', value: 9 },
    { name: '卡顿/闪退/崩溃', value: 10 },
    { name: '建议/产品提议/体验', value: 11 },
    { name: '登陆/账号/注册', value: 12 },
    { name: '活动/运营活动/创服中心', value: 13 },
    { name: '其他', value: 14 },
    // { name: '空', value: 1 },
    // { name: '无关紧要', value: 2 },
    // { name: '低', value: 3 },
    // { name: '中', value: 4 },
    // { name: '高', value: 5 },
    // { name: '紧急', value: 6 },
  ]
  const duplicateoptions = [
    { name: '是', value: 2 },
    { name: '否', value: 1 },
  ]
  // const router = useRouter()
  const state:any = reactive({
    jobName: '',
    jobNameList: [],
    checkedRows: [],
    pagination: {
      pageNo: 1,
      pageSize: 10,
      total: 0,
    },
    selected: '',
    statusMap: {
      1: '未处理',
      2: '已转',
      3: '已关闭',
    },
    filters: {
      title: '',
      jobId: '',
      status: 0,
      platform: 0,
    },
    columns: [
      {
        title: '任务ID',
        key: 'jobId',
        th: {
          bs: {
            width: '70px',
          },
        },
      },
      {
        title: 'bugID',
        key: 'id',
        th: {
          bs: {
            width: '70px',
          },
        },
      },
      {
        title: 'caseId',
        key: 'caseId',
        td: {
          is: 'caseId',
        },
        th: {
          bs: {
            width: '110px',
            // padding: '3px',
          },
        },
      },

      {
        title: 'Bug标题',
        key: 'title',
        th: {
          bs: {
            width: '250px',
          },
        },
      },
      {
        title: '提报人',
        key: 'reporter',
        th: {
          bs: {
            width: '140px',
          },
        },
      },
      {
        title: '薯名',
        key: 'nickname',
        formatter: (nickname:any) => {
          if (nickname === '()' || nickname === 'null(null)') {
            return '-'
          }
          return nickname
        },
        th: {
          bs: {
            width: '80px',
          },
        },
      },
      {
        title: '版本号',
        key: 'version',
        th: {
          bs: {
            width: '70px',
          },
        },
      },
      {
        title: '平台类型',
        key: 'platform',
        formatter: (platform:any) => getKey(TYPE_MAP, platform),
        th: {
          bs: {
            width: '70px',
          },
        },
      },
      {
        title: '手机系统',
        key: 'deviceSystem',
        th: {
          bs: {
            width: '80px',
          },
        },
      },
      {
        title: '业务分类',
        key: 'priority',
        td: {
          is: 'priority',
        },
        th: {
          bs: {
            width: '200px',
            padding: '3px',
          },
        },
      },
      {
        title: '严重等级',
        key: 'level',
        td: {
          is: 'level',
        },
        th: {
          bs: {
            width: '85px',
          },
        },
      },
      {
        title: '状态',
        key: 'status',
        // formatter: (status:any) => getKey(state.statusMap, status),
        td: {
          is: 'status',
          // bs: {
          //   width: '110px',
          // },
        },
        th: {
          bs: {
            width: '100px',
            padding: '3px',
          },
        },

      },
      {
        key: 'showdetail',
        title: '操作',
        td: {
          is: 'showdetail',
        },
        th: {
          bs: {
            // color: 'brand', // 可以灵活控制th的样式
            width: '160px',
          },
        },

      },
      {
        key: 'action',
        title: '一键转pingcode',
        td: {
          is: 'action',
        },
        th: {
          bs: {
            width: '120px',
          },
        },
      },
      {
        title: 'pingcode链接',
        key: 'tapdLink',
        td: {
          is: 'tapdLink',
        },
        th: {
          bs: {
            width: '85px',
          },
        },
      },
      {
        title: '是否重复',
        key: 'duplicate',
        td: {
          is: 'duplicate',
          // bs: {
          //   width: '60px',
          // },
        },
        th: {
          bs: {
            width: '88px',
          },
        },
        // fixed: 'right',
      },
      {
        title: '是否有效',
        key: 'valid',
        td: {
          is: 'valid',
        },
        th: {
          bs: {
            width: 85,
          },
        },
        fixed: 'right',
      },

    ],
    bugList: [],
  })

  const handleSearch = async () => {
    const {
      title, jobId, status, platform, priority, level, valid,
    } = state.filters
    const params: IFilterConfig = {
      page: state.pagination.pageNo,
      pageSize: state.pagination.pageSize,
    }
    if (title) params.title = title
    if (jobId) params.jobId = jobId
    if (status) params.status = status
    if (platform) params.platform = platform
    if (priority) params.priority = priority
    if (level) params.level = level
    if (valid) params.valid = valid

    try {
      const res = await getBugs(params)
      state.bugList = res.bugList // 后端进行分页
      state.pagination.total = res.totalCount
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  function handlePagination() {
    handleSearch()
  }
  const handlenameList = async () => {
    try {
      const res = await getjobnameList()
      const list = res.list
      list.sort((a, b) => b.value - a.value)
      state.jobNameList = list
    } catch (error) {
      toaster.warning(error.message)
    }
  }
  const jumpToDetail = async (rowData: { id: any }) => {
    const id = rowData.id
    // router.push({
    //   name: 'BugDetail',
    //   params: {
    //     id,
    //   },
    // })
    const strurl = window.location.href
    if (strurl.indexOf('.sit.') !== -1) {
      window.open(`http://test.sit.xiaohongshu.com/defectpage/${id}`)
    } else if (strurl.indexOf('.beta.') !== -1) {
      window.open(`http://test.beta.xiaohongshu.com/defectpage/${id}`)
    } else {
      window.open(`https://test.xiaohongshu.com/defectpage/${id}`)
    }
  }

  function jumpTapd(data:any) {
    if (
      data.tapdLink !== null
      && data.tapdLink !== undefined
      && data.tapdLink !== ''
    ) {
      window.open(data.tapdLink)
    } else {
      toaster.warning('pingcodelink为空！')
    }
  }

  function handleTapd(data:any) {
    const payload = {
      bugId: data.id, // bugid
      operatorId: USERINFO.userId,
      status: 2,
    }
    updateBug(payload)
      .then(res => {
        if (res.code === 200) {
          toaster.success('一键转pingcode成功')
          handleSearch()
        }
      })
      .catch(err => toaster.warning(err))
  }
  function handleTapdBatch() {
    if (state.checkedRows.length === 0) {
      toaster.warning('请选择数据！')
    } else {
      state.checkedRows.forEach((item:any) => {
        if (state.bugList[item].status === 2) {
          return
        }
        const payload = {
          bugId: state.bugList[item].id,
          status: 2,
          operatorId: USERINFO.userId,
        }
        updateBug(payload)
          .then(res => {
            if (res.code !== 200) {
              toaster.danger(`bug号:${payload.bugId}提交pingcode失败`)
            }
          })
          .catch(err => toaster.warning(err))
      })

      toaster.success('批量提交完成！')
      // state.tableflag = true
      // state.tableKey += 1
      // nextTick(() => {
      //   otherParam.showA = true
      // })
      // 成功后需要强制刷新表格，getbugs接口需要延迟几秒请求
      setTimeout(() => {
        handleSearch()
      }, 3000)
      state.checkedRows = []
    }
  }

  // function handleTapd(data:any) {
  //   const payload = {
  //     id: data.id, // bugid
  //     title: data.title,
  //     bugInfo: data.bugInfo,
  //     version: data.version,
  //     platform: getKey(TYPE_MAP, data.platform),
  //     deviceSystem: data.deviceSystem,
  //     operatorId: data.reporterId,
  //     attachmentList: data.attachmentList,
  //   }
  //   // window.console.log(payload)
  //   pushToTapd(payload)
  //     .then(res => {
  //       if (res !== false) {
  //         toaster.success('一键转tapd成功')
  //         handleSearch()
  //       }
  //     })
  //     .catch(err => toaster.warning(err))
  // }

  // function handleTapdBatch() {
  //   if (state.checkedRows.length === 0) {
  //     toaster.warning('请选择数据！')
  //   } else {
  //     state.checkedRows.forEach((item:any) => {
  //       if (state.bugList[item].status === 2) {
  //         return
  //       }
  //       const payload = {
  //         id: state.bugList[item].id,
  //         title: state.bugList[item].title,
  //         bugInfo: state.bugList[item].bugInfo,
  //         version: state.bugList[item].version,
  //         platform: getKey(TYPE_MAP, state.bugList[item].platform),
  //         deviceSystem: state.bugList[item].deviceSystem,
  //         operatorId: state.bugList[item].reporterId,
  //         attachmentList: state.bugList[item].attachmentList,
  //       }
  //       pushToTapd(payload)
  //         .then(res => {
  //           if (res === false) {
  //             toaster.danger(`bug号:${payload.id}提交tapd失败`)
  //           }
  //         })
  //         .catch(err => toaster.warning(err))
  //     })

  //     toaster.warning('批量提交完成！')
  //     // state.tableflag = true
  //     // state.tableKey += 1
  //     // nextTick(() => {
  //     //   otherParam.showA = true
  //     // })
  //     // 成功后需要强制刷新表格，getbugs接口需要延迟几秒请求
  //     setTimeout(() => {
  //       handleSearch()
  //     }, 3000)
  //     state.checkedRows = []
  //   }
  // }

  // 删除bug
  const handleDelete = async (rowData: { id: string }) => {
    const bugId = rowData.id

    showModal({
      props: {
        type: 'dialog',
        title: '确定要删除该条bug么？',
        async handleConfirm({ close }) {
          try {
            const res = await deleteBug({ bugId })
            if (res.code === 200) {
              console.log(res)
              toaster.success('删除成功')
              close()
              handleSearch()
            } else {
              toaster.danger(res.msg)
            }
          } catch (error) {
            toaster.warning(error.message)
          }
        },
      },
    })
  }
  // 批量删除
  function handleDeleteBatch() {
    if (state.checkedRows.length === 0) {
      toaster.warning('请选择数据！')
    } else {
      showModal({
        props: {
          type: 'dialog',
          title: '确定要删除这些bug么？',
          async handleConfirm({ close }) {
            try {
              close()
              state.checkedRows.forEach((item:any) => {
                // //此处是判断什么状态的bug可以删除
                // if (state.bugList[item].status === 2) {
                //   return
                // }
                const payload = {
                  bugId: state.bugList[item].id,

                }
                deleteBug(payload)
                  .then(res => {
                    if (res.code !== 200) {
                      toaster.danger(`bug号:${payload.bugId}删除失败`)
                    }
                  })
                  .catch(err => toaster.warning(err))
              })
              toaster.warning('批量操作完成！')
              // 成功后需要强制刷新表格，getbugs接口需要延迟几秒请求
              setTimeout(() => {
                handleSearch()
              }, 3000)
              state.checkedRows = []
            } catch (error) {
              toaster.warning(error.message)
            }
          },
        },
      })
    }
  }

  // 更新bug状态
  const changeStatus = async (rowData: { id: any;status:any;operatorId:any }) => {
    // toaster.notify(`clicked  -----${JSON.stringify(rowData.status)}`)
    const fromstatus = rowData.status
    const params = {
      bugId: rowData.id,
      operatorId: rowData.operatorId,
      status: rowData.status,
    }

    try {
      const res = await updateBug(params)
      if (res.code === 200) {
        toaster.success('操作成功！')
        handleSearch()
      } else {
        toaster.warning(res.msg)
        rowData.status = fromstatus // 更新失败恢复原来值
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  // 更新bug等级
  const changeLevel = async (rowData: { id: any;level:any;operatorId:any }) => {
    // toaster.notify(`clicked  -----${JSON.stringify(rowData.status)}`)
    const fromlevel = rowData.level
    const params = {
      bugId: rowData.id,
      operatorId: rowData.operatorId,
      level: rowData.level,
    }
    try {
      const res = await updateBug(params)
      if (res.code === 200) {
        toaster.success('操作成功！')
        handleSearch()
      } else {
        toaster.warning(res.msg)
        rowData.level = fromlevel // 更新失败恢复原来值
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  // 更新用例id
  const updateCaseId = async (rowData: { id: any;caseId:any;operatorId:any }) => {
    const fromcaseId = rowData.caseId
    const params = {
      bugId: rowData.id,
      operatorId: rowData.operatorId,
      caseId: Number(rowData.caseId),
    }
    try {
      const res = await updateBug(params)
      if (res.code === 200) {
        toaster.success('操作成功！')
        handleSearch()
      } else {
        toaster.warning(res.msg)
        rowData.caseId = fromcaseId// 更新失败恢复原来值
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  // 更新优先级
  const changePriority = async (rowData: { id: any;priority:any;operatorId:any }) => {
    // toaster.notify(`clicked  -----${JSON.stringify(rowData.status)}`)
    const frompriority = rowData.priority
    const params = {
      bugId: rowData.id,
      operatorId: rowData.operatorId,
      priority: rowData.priority,
    }
    try {
      const res = await updateBug(params)
      if (res.code === 200) {
        toaster.success('操作成功！')
        handleSearch()
      } else {
        toaster.warning(res.msg)
        rowData.priority = frompriority// 更新失败恢复原来值
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  // 标记是否重复
  const changeDuplicate = async (rowData: { id: any;duplicate:any;operatorId:any }) => {
    // toaster.notify(`clicked  -----${JSON.stringify(rowData.status)}`)
    const fromduplicate = rowData.duplicate
    const params = {
      bugId: rowData.id,
      operatorId: rowData.operatorId,
      duplicate: rowData.duplicate,
    }
    try {
      const res = await duplicateBug(params)
      if (res.code === 200) {
        toaster.success('操作成功！')
        handleSearch()
      } else {
        toaster.warning(res.msg)
        rowData.duplicate = fromduplicate// 更新失败恢复原来值
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  // 标记是否有效
  const changeValid = async (rowData: { id: any;valid:any;operatorId:any }) => {
    // toaster.notify(`clicked  -----${JSON.stringify(rowData.status)}`)
    const fromvalid = rowData.valid
    const params = {
      bugId: rowData.id,
      operatorId: rowData.operatorId,
      valid: rowData.valid,
    }
    try {
      const res = await validBug(params)
      if (res.code === 200) {
        toaster.success('操作成功！')
        handleSearch()
      } else {
        toaster.warning(res.msg)
        rowData.valid = fromvalid// 更新失败恢复原来值
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  watch([() => state.filters.jobId, () => state.filters.status, () => state.filters.platform, () => state.filters.priority, () => state.filters.level, () => state.filters.valid], () => {
    handleSearch()
  })
  onMounted(() => {
    handlenameList()
    handleSearch()
  })

      // return {
      //   ...toRefs(state),
      //   handleSearch,
      //   handleTapd,
      //   handleTapdBatch,
      //   handlePagination,
      //   jumpTapd,
      //   handlenameList,
      // }
    // },
  // })
</script>
<style lang="stylus" scoped>
.bug-table {
  margin-left: 15px;
  margin-right: 15px;
}

.pagination-table {
  margin-top: 24px;
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>
