<template>
  <NewBsBox :bs="{ m: 15 }">
    <BeerSwitch v-model="state.flag">
      <NewBsBox :bs="{ ml: 8 }">{{
        state.flag ? "打开任务维度" : "关闭任务维度"
      }}</NewBsBox>
    </BeerSwitch>

    <span
      v-if="state.flag === true"
      style="font-weight: bold; font-size: 14px; margin-right: 5px"
    >任务名称:</span>
    <single-select
      v-if="state.flag === true"
      v-model="state.filters.jobId"
      placeholder="根据任务筛选"
      :options="state.jobNameList"
      :show-clear="true"
      :filterable="true"
      :bs="{ mr: '10px', width: 140 }"
    />
  </NewBsBox>

  <div style="text-align: left; margin-bottom: 15px; margin-left: 5px;margin-right: 0px;width:100%">
    <Button
      class="btn"
      icon-before="add-m"
      size="l"
      variant="secondary"
      @click="handleAdd"
    >发放奖励</Button>
  </div>

  <BeerTable
    class="data-table"
    size="s"
    border-style="none"
    :columns="state.columns"
    :data="state.awardData"
  >
    <template #userId="{ rowIndex }">
      <BeerInput
        v-if="state.awardData[rowIndex].hasOwnProperty('userId')"
        v-model="state.awardData[rowIndex].userId"
      />
      <span v-else>{{ state.awardData[rowIndex].userId }}</span>
      <!-- ===={{state.awardData[rowIndex].userId}} -->
    </template>

    <template #type="{ rowData, rowIndex }">
      <SingleSelect
        v-model="state.awardData[rowIndex].type"
        :bs="{ width: 160 }"
        :options="state.awardNameList"
        @update:modelValue="
          selectedType(rowData, state.awardData[rowIndex].type)
        "
      />
    </template>
    <template #num="{ rowIndex }">
      <BeerInput v-model="state.awardData[rowIndex].num" />
    </template>
    <template #action="{ rowData, rowIndex }">
      <Button
        v-if="state.awardData[rowIndex].hasOwnProperty('userId')&&(!state.awardData[rowIndex].hasOwnProperty('userIdAlia'))"
        size="s"
        variant="outline"
        @click.stop="handleCopyitem(rowData, rowIndex)"
      >复制</Button>
      <Button
        v-if="state.awardData[rowIndex].hasOwnProperty('userId')&&(!state.awardData[rowIndex].hasOwnProperty('userIdAlia'))"
        size="s"
        modifier="danger"
        @click.stop="handleDeleteAll(rowData, rowIndex)"
      >整体删除</Button>
      <Button
        v-if="state.awardData[rowIndex].hasOwnProperty('userIdAlia')"
        size="s"
        modifier="danger"
        @click.stop="handleDeleteitem(rowData, rowIndex)"
      >删除条目</Button>
    </template>
  </BeerTable>

  <!-- <NewBsBox :bs="{testAlign:center}"> -->

  <!-- </NewBsBox> -->

  <div
    v-if="state.awardData.length !== 0"
    style="text-align: center; margin-top: 30px"
  >
    <BeerButton
      variant="default"
      @click="handsubmit"
    >提交</BeerButton>
    <BeerButton
      variant="secondary"
      @click="close"
    >取消</BeerButton>
  </div>
</template>

<script setup lang="ts">
  import { reactive, onMounted } from 'vue'
  import {
    NewBsBox,
    // FormGroup,
    BeerInput,
    SingleSelect,
    BeerButton,
    BeerTable,
    Button,
    BeerSwitch,
    toaster,
  } from '@xhs/yam-beer'
  //   import BeerForm from '@xhs/beer-formily'
  import { getjobnameList } from '../../services/Job'
  import { getValidAwardList, rewardAdd } from '../../services/Award'
  import { USERINFO } from '../../common'

  type Obj = {
    [key: string]: any
  }
  const countObj: Obj = {}

  const state = reactive({
    flag: false,
    jobNameList: [],
    filters: {
      jobId: 0,
    },
    awardObj: {},
    defaultcount: 1,

    awardData: [],
    addNew: false,
    useridflag: false,
    awardNameList: [],
    columns: [
      {
        title: 'userId',
        key: 'userId',
        td: {
          is: 'userId',
        },
      },
      {
        title: '礼物类型',
        key: 'type',
        td: {
          is: 'type',
        },
      },
      {
        key: 'num',
        title: '礼物数量',
        td: {
          is: 'num',
        },
      },
      {
        key: 'action',
        title: '操作',
        td: {
          is: 'action',
        },
      },
    ],
  })

  const handleAdd = () => {
    //    state.addNew = true;
    state.useridflag = false
    const row = {
      userId: '',
      type: '',
      num: '',

    }

    state.awardData.push(row)
  // this.handleEdit(row, this.tableData.length - 1);
  }

  // const handleAdditem = async (
  //   rowData: { userId: string; type: number; num: number},
  //   rowIndex: number
  // ) => {

  //   if (rowData.userId === "") {
  //     toaster.danger("请先输入userid");
  //     return;
  //   }

  // window.console.log("suerid-----"+JSON.stringify(state.awardData[rowIndex]))
  //   window.console.log(
  //     "添加后的值4444------" + JSON.stringify(countObj[`${rowData.userId}`])
  //   );
  //   if (JSON.stringify(countObj[`${rowData.userId}`]) === undefined) {
  //     state.defaultcount = 1;
  //   } else {
  //     state.defaultcount = countObj[`${rowData.userId}`];

  //   }
  //   state.defaultcount += 1;
  //   countObj[`${rowData.userId}`] = state.defaultcount;
  //   window.console.log("添加后的值------" + JSON.stringify(countObj));
  //   if(state.defaultcount!==1){
  //       state.useridflag = true
  //   }
  //   window.console.log("flag------" +state.useridflag)
  //   const row = {
  //     userId: rowData.userId,
  //     type: "",
  //     num: "",
  //     // userIdAlia: rowData.userId,//第一行展示内容
  //   };
  //   state.awardData.splice(rowIndex + 1, 0, row);
  //   // window.console.log(JSON.stringify(state.awardData))
  // }

  const handleCopyitem = async (
    rowData: { userId: string; type: number; num: number},
    rowIndex: number,
  ) => {
    if (rowData.userId === '') {
      toaster.danger('请先输入userid')
      return
    }

    // window.console.log(`suerid-----${JSON.stringify(state.awardData[rowIndex])}`)
    // window.console.log(
    // //   `添加后的值4444------${JSON.stringify(countObj[`${rowData.userId}`])}`,
    // )
    if (JSON.stringify(countObj[`${rowData.userId}`]) === undefined) {
      state.defaultcount = 1
    } else {
      state.defaultcount = countObj[`${rowData.userId}`]
    }
    state.defaultcount += 1
    countObj[`${rowData.userId}`] = state.defaultcount
    window.console.log(`添加后的值------${JSON.stringify(countObj)}`)
    if (state.defaultcount !== 1) {
      state.useridflag = true
    }
    window.console.log(`flag------${state.useridflag}`)
    const row = {
      userId: rowData.userId,
      type: '',
      num: '',
      userIdAlia: rowData.userId, // 第一行展示内容
    }
    state.awardData.splice(rowIndex + 1, 0, row)
  // window.console.log(JSON.stringify(state.awardData))
  }

  const handleDeleteitem = async (
    rowData: { userIdAlia: string; type: number; num: number },
    rowIndex: number,
  ) => {
    state.awardData.splice(rowIndex, 1)
    countObj[`${rowData.userIdAlia}`] -= 1 // 删除单个奖品条目后，计数-1
  }

  const handleDeleteAll = async (
    rowData: { userId: string; type: number; num: number },
    rowIndex: number,
  ) => {
    //     window.console.log(JSON.stringify(state.awardData))
    //     window.console.log('整体删除-----'+rowData.userId+"--index--"+rowIndex)
    //  window.console.log('输入userid，1行-----'+JSON.stringify(countObj[`${rowData.userId}`]))
    // 删除点击整体删除的index开始
    if (rowData.userId !== '') {
      state.awardData.splice(rowIndex, countObj[`${rowData.userId}`])
    } else if (rowData.userId === '') {
      state.awardData.splice(rowIndex, 1) // 删除一行,还未输入userid
    }
    if (JSON.stringify(countObj[`${rowData.userId}`]) === undefined) {
      // 输入了userid
      state.awardData.splice(rowIndex, 1)
    }
    // 删除计数
    delete countObj[`${rowData.userId}`]

  // window.console.log(JSON.stringify(state.awardData))
  //  window.console.log("777777------"+JSON.stringify(countObj))
  }

  const selectedType = async (rowData: any, param: any) => {
    window.console.log(JSON.stringify(rowData))
    window.console.log(JSON.stringify(param))
    // window.console.log(JSON.stringify(state.awardData))
  }

  //   const getarrlength = (parm: any) => {
  function getarrlength(param: any) {
    let length = 0
    Object.values(param).forEach(item => {
      if (item !== '') {
        length += 1
      }
    })

    // const arr = Object.values(parm).filter(item => {
    //   if (item !== '') {
    //     return true // 过滤掉为空的项
    //   }
    // })
    return length
  }

  const handsubmit = async () => {
    const tmparr2: any = []
    const arr: any = []
    // type Obj = {
    //   [key: string]: any
    // }
    // const tmpobj: Obj = {}
    // const firstobj: Obj = {}
    const secendobj: Obj = {}
    /// ////  Object.prototype.hasOwnProperty.call(foo, “bar”)
    try {
      state.awardData.forEach((item: any) => {
        // if (!item.hasOwnProperty('userIdAlia')) {
        if (!Object.prototype.hasOwnProperty.call(item, 'userIdAlia')) {
          if (getarrlength(item) < 3) {
            toaster.warning('输入值不能为空！')
            //  return false
            throw new Error()
          }
        }
        // if (item.hasOwnProperty('userIdAlia')) {
        if (Object.prototype.hasOwnProperty.call(item, 'userIdAlia')) {
          if (getarrlength(item) < 4) {
            toaster.warning('输入值不能为空！')
            //  return false
            throw new Error()
          }
        }

        // if (!secendobj.hasOwnProperty(item.userId)) {
        if (!Object.prototype.hasOwnProperty.call(secendobj, item.userId)) {
          tmparr2.splice(0, tmparr2.length)
        }
        const firstobj: Obj = {}
        firstobj[`${item.type}`] = item.num
        tmparr2.push(firstobj)
        secendobj[`${item.userId}`] = JSON.parse(JSON.stringify(tmparr2))
        arr.push(JSON.parse(JSON.stringify(secendobj)))
      })
    } catch (e) {
      //   toaster.warning(e)
      return
    }

    // arr.slice(-1)
    window.console.log(`userid整体--${JSON.stringify(arr)}`)
    window.console.log(`userid礼物---${JSON.stringify(arr.slice(-1))}`)
    const payload: Obj = {
      operatorId: USERINFO.userId,
      users: arr.slice(-1),
      isJobReward: 0,
    }

    // 任务维度
    if (state.flag === true) {
      //   window.console.log(`2222====${state.filters.jobId}`)
      payload.jobId = state.filters.jobId
      payload.isJobReward = 1
      rewardAdd(payload)
        .then(res => {
          if (res.code === 200) {
            // chenggong
            // 提交完成后，恢复默认值
            state.awardData = []
            Object.keys(countObj).forEach((item: any) => {
              // for (const [key] of Object.entries(countObj)) {
              if (Object.prototype.hasOwnProperty.call(countObj, item)) {
                delete countObj[item]
              }
            })
          } else {
            toaster.warning(res.msg)
          }
        })
        .catch(err => toaster.warning(err))
    } else {
      rewardAdd(payload)
        .then(res => {
          if (res.code === 200) {
            // chenggong
            // 提交完成后，恢复默认值
            state.awardData = []
            // for (const key of Object.keys(countObj)) {
            Object.keys(countObj).forEach((item: any) => {
              // for (const [key] of Object.entries(countObj)) {
              if (Object.prototype.hasOwnProperty.call(countObj, item)) {
                delete countObj[item]
              }
            })
          } else {
            toaster.warning(res.msg)
          }
        })
        .catch(err => toaster.warning(err))
    }

    window.console.log(JSON.stringify(state.awardData))
  }
  const close = () => {
    state.awardData = []
    // window.console.log(JSON.stringify(countObj))
    Object.keys(countObj).forEach((item: any) => {
      // for (const [key] of Object.entries(countObj)) {
      if (Object.prototype.hasOwnProperty.call(countObj, item)) {
        delete countObj[item]
      }
    })
  }

  const handlenameList = async () => {
    try {
      const res = await getjobnameList()
      state.jobNameList = res.list
    } catch (error) {
      toaster.warning(error.message)
    }
  }
  const handleawardList = async () => {
    try {
      const res = await getValidAwardList()
      if (res.code === 200) {
        state.awardNameList = res.data.list
      } else {
        toaster.warning(res.msg)
      }
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  //   watch([() => state.awardData[rowindex]], () => {

  //   })

  onMounted(() => {
    handlenameList()
    handleawardList()
  // window.console.log(getarrlength({"userId":"ttt","type":"","num":""}))
  })
</script>

<style lang="stylus" scoped>
.data-table {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-table {
  margin-top: 24px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.manImg {
  width: 30px;
  height: 25px;
}
.btn{
width: 99%;
border:1px dashed #808080;

}
.btn:hover {
    background-color:#6495ED;
    color:#fff;
}
.btn:active {
    background-color: #6495ED;
    color:#fff;
}
</style>
