<template>
  <h2 align="center">报告详情</h2>
  <!-- <NewBsBox :bs="{ display: 'flex' }"> -->
  <BeerPanel :bs="{ boxShadow: 'none', flex: 1, margin: 0, pt: 0 }">
    <BeerTitle :bs="{ mb: 16 }"> 基本信息 </BeerTitle>
    <BeerDivider />
    <NewBsBox
      :bs="{
        padding: 24,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }"
    >
      <NewBsBox :bs="{ flex: '33%' }">
        <NewBsBox :bs="{ pb: '13px' }">
          <NewBsBox
            :bs="{ fontWeight: '600', display: 'inline' }"
          >任务名称:</NewBsBox>
          {{ jobInfo.jobName }}
        </NewBsBox>
        <NewBsBox :bs="{ pb: '13px' }">
          <NewBsBox
            :bs="{ fontWeight: '600', display: 'inline' }"
          >版本号:</NewBsBox>
          {{ jobInfo.version }}
        </NewBsBox>
        <NewBsBox :bs="{ pb: '13px' }">
          <NewBsBox
            :bs="{ fontWeight: '600', display: 'inline' }"
          >任务类型:</NewBsBox>
          {{ jobInfo.typeValue }}
        </NewBsBox>
      </NewBsBox>

      <NewBsBox :bs="{ flex: '33%' }">
        <NewBsBox :bs="{ pb: '13px', flex: '50%' }">
          <NewBsBox
            :bs="{ fontWeight: '600', display: 'inline' }"
          >测试开始:</NewBsBox>
          {{ jobInfo.startTime }}
        </NewBsBox>
        <NewBsBox :bs="{ pb: '13px', flex: '50%' }">
          <NewBsBox
            :bs="{ fontWeight: '600', display: 'inline' }"
          >测试结束:</NewBsBox>
          {{ jobInfo.endTime }}
        </NewBsBox>
        <NewBsBox :bs="{ pb: '13px', flex: '50%' }">
          <NewBsBox
            :bs="{ fontWeight: '600', display: 'inline' }"
          >buildId:</NewBsBox>
          {{ jobInfo.buildId }}
        </NewBsBox>
      </NewBsBox>
    </NewBsBox>
  </BeerPanel>
  <!-- </NewBsBox> -->

  <BeerPanel :bs="{ boxShadow: 'none', flex: 1, margin: 0, pt: 0 }">
    <BeerTitle :bs="{ mb: 16 }"> 缺陷信息 </BeerTitle>
    <BeerDivider />
    <NewBsBox
      :bs="{
        padding: 24,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }"
    >
      <NewBsBox :bs="{ flex: '33%' }">
        <BeerTitle
          v-if="state.totalCount===0"
          :bs="{ mb: 0 }"
        >
          暂无信息！
        </BeerTitle>

        <div
          id="bugChart"
          :style="{ margin: 20, width: '500px', height: '500px' }"
        />
      </NewBsBox>
      <BeerDivider :vertical="true" />
      <NewBsBox
        v-if="state.totalCount!==0"
        :bs="{ flex: '33%', marginLeft: 15 }"
      >
        <BeerTitle :bs="{ mb: 16 }">
          bug总数：{{ state.totalCount }} 个，有效bug：{{ state.validCount }} 个
        </BeerTitle>
        <BeerTable
          class="data-table"
          size="s"
          border-style="none"
          :columns="state.columns"
          :data="state.BugList"
        >
          <template #percent="{ rowData }">
            <NewBsBox>{{ ((rowData.value * 100) / state.totalCount).toFixed(2) }}%</NewBsBox>
          </template>
        </BeerTable>
      </NewBsBox>
    </NewBsBox>
  </BeerPanel>

  <BeerPanel :bs="{ boxShadow: 'none', flex: 1, margin: 0, pt: 0 }">
    <BeerTitle :bs="{ mb: 16 }"> 设备信息 </BeerTitle>
    <BeerDivider />
    <NewBsBox
      :bs="{
        padding: 24,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }"
    >
      <NewBsBox
        v-if="jobInfo.type===1"
        :bs="{ flex: '33%',marginRight: 15,marginBottom: 30 }"
      >
        <BeerTitle
          v-if="reportdata.totalDeviceCount>0"
          :bs="{ mb: 0 }"
        >
          覆盖设备{{ reportdata.totalDeviceCount }}个，覆盖品牌{{ reportdata.totalBrandCount }}个，品牌分布如下：
        </BeerTitle>
        <BeerTitle
          v-else
          :bs="{ mb: 10 }"
        >
          暂无数据！
        </BeerTitle>
        <div
          id="brandChart"
          :style="{ margin: 0, height: '400px' }"
        />

      </NewBsBox>
      <BeerDivider
        v-if="jobInfo.type===1"
        :vertical="true"
      />
      <NewBsBox :bs="{ flex: '33%', marginLeft: 15 }">
        <BeerTitle
          v-if="reportdata.totalDeviceCount>0&&jobInfo.type===1"
          :bs="{ mb: 0 }"
        >
          覆盖机型{{ reportdata.totalDeviceTypeCount }}个，机型分布如下：
        </BeerTitle>
        <BeerTitle
          v-else-if="reportdata.totalDeviceCount>0&&jobInfo.type===2"
          :bs="{ mb: 0 }"
        >
          覆盖设备{{ reportdata.totalDeviceCount }}个，覆盖机型{{ reportdata.totalDeviceTypeCount }}个，机型分布如下：
        </BeerTitle>
        <BeerTitle
          v-else
          :bs="{ mb: 10 }"
        >
          暂无数据！
        </BeerTitle>
        <div
          id="deviceChart"
          :style="{ margin: 0, height: '400px' }"
        />
      </NewBsBox>

      <BeerDivider
        v-if="jobInfo.type===2"
        :vertical="true"
      />

      <NewBsBox :bs="{ flex: '33%',marginLeft: 15 }">
        <BeerTitle
          v-if="reportdata.totalDeviceCount>0"
          :bs="{ mb: 10 }"
        >
          覆盖系统{{ reportdata.totalOsVersionCount }}个，系统分布如下：
        </BeerTitle>
        <BeerTitle
          v-else
          :bs="{ mb: 10 }"
        >
          暂无数据！
        </BeerTitle>
        <div
          id="systemChart"
          :style="{ margin: 0, height: '400px' }"
        />

      </NewBsBox>

    </NewBsBox>
    <!-- <BeerDivider />

    <NewBsBox
      :bs="{
        padding: 24,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }"
    >
    <NewBsBox :bs="{ flex: '33%',marginRight: 15 }">
<BeerTitle
          v-if="reportdata.totalDeviceCount>0"
          :bs="{ mb: 10 }"
        >
          覆盖系统{{ reportdata.totalOsVersionCount }}个，系统分布如下：
        </BeerTitle>
        <BeerTitle
          v-else
          :bs="{ mb: 10 }"
        >
          暂无数据！
        </BeerTitle>
        <div
          id="systemChart"
          :style="{ margin: 0, height: '400px' }"
        />
    </NewBsBox>
    </NewBsBox>  -->

    <!-- <BeerDivider :vertical="true" />
     <NewBsBox :bs="{ flex: '33%',marginRight: 15 }">
        </NewBsBox>-->

  </BeerPanel>
  <BeerPanel :bs="{ boxShadow: 'none', flex: 1, margin: 0, pt: 0 }">
    <BeerTitle :bs="{ mb: 16 }"> 页面覆盖信息 </BeerTitle>
    <BeerDivider />
    <NewBsBox
      :bs="{
        padding: 24,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }"
    >
      <NewBsBox :bs="{ flex: '33%' }">
        <BeerTitle
          v-if="reportdata.totalDeviceCount>0"
          :bs="{ mb: 10 }"
        >
          覆盖页面{{ reportdata.totalPageCount }}个，页面分布如下：
        </BeerTitle>
        <BeerTitle
          v-else
          :bs="{ mb: 10 }"
        >
          暂无数据！
        </BeerTitle>

        <div
          id="pageChart"
          :style="{ margin: 20, height: '500px' }"
        />
        <NewBsBox :bs="{ mr: 16 }">
          <!-- <BeerTitle>
          详细页面列表：
        </BeerTitle> -->
          <!-- <NewBsBox :bs="{ mr: 16 }">
          </NewBsBox> -->
          <BeerDivider style="margin:20px;" />
          <span style="font:16px;margin-top:20px;font-weight: bold;">详细页面列表：</span>
          <span style="font:16px;margin-top:20px;margin-left:20px;font-weight: bold;">前 </span>
          <!-- <BeerInput v-model="state.pagelistcount" /> -->
          <BeerInput
            v-model="state.pagelistcount"
            :bs="{ width: 80 }"
            type="number"
            placeholder="number"
          />
          <span style="font:16px;margin-top:20px;margin-right:30px;font-weight: bold;"> 条</span>
          <Button
            icon-before="filtrate"
            @click="fitlercount(state.pagelistcount)"
          >筛选</Button>
        </NewBsBox>
        <NewBsBox :bs="{ margin: 10, pt: 3 }">
          <beer-table-with-sort
            class="data-table"
            size="s"
            border-style="none"
            :columns="state.pagecolumns"
            :data="reportdata.pageList"
            :default-sort="{
              keys: [ 'pageNum'],
              orders: [ 'desc'],
            }"
          >
            <template #pageNum="{ rowData }">
              <NewBsBox>{{ rowData.pageNum }}</NewBsBox>
            </template>
          </beer-table-with-sort>

        </NewBsBox>

      </NewBsBox>

    </NewBsBox>
  </BeerPanel>

</template>

<script setup lang="ts">
  import { onMounted, reactive } from 'vue'
  import { useRoute } from 'vue-router'
  import {
    NewBsBox,
    BeerPanel,
    BeerTitle,
    BeerDivider,
    BeerTable,
    toaster,
    BeerTableWithSort,
    // SingleSelect,
    BeerInput,
    Button,
    // Excel,
  } from '@xhs/yam-beer'
  import * as echarts from 'echarts'
  import { getJobInfo } from '../../services/Job'
  import type { JobInfo } from '../../services/Job'
  import { getbugStat } from '../../services/Bug'
  // import { getdeviceStat } from '../../services/Brand'

  //   import { useBugModalForm } from '../../composables/useBugModalForm'

  //   import { USERINFO } from '../../common'
  import {
    formatDate, getKey, TYPE_MAP, sortBykeyDesc,
  } from '~/utils/common'

  const route = useRoute()
  const id = Number(route.params.id)
  //   const str = window.location.href
  //   const h5url = `${str.indexOf('.sit.') !== -1 ? 'test.sit.xiaohongshu.com' : 'test.xiaohongshu.com'}`

  const jobInfo = reactive<JobInfo>({
    jobName: '',
    version: '',
    type: 0,
    jobInfo: '',
    startTime: '',
    endTime: '',
    jobUrl: '',
    typeValue: '',
    buildId: '',
  })

  // type EChartsOption = echarts.EChartsOption

  const reportdata = reactive({
    levelData: [],
    brandnameList: [] as any[],
    brandvalueList: [] as any[],
    devicenameList: [] as any[],
    devicevalueList: [] as any[],
    // devicenameList: [],
    // devicevalueList: [],
    systemnameList: [] as any[],
    systemvalueList: [] as any[],
    totalBrandCount: -1,
    totalDeviceCount: -1,
    totalDeviceTypeCount: -1,
    totalOsVersionCount: -1,
    pagenameList: [] as any[],
    pagevalueList: [] as any[],
    totalPageCount: -1,
    pageList: [],
    pageListbak: [],
  })

  // const pagestate = reactive({
  //   pagination: {
  //     pageNo: 1,
  //     pageSize: 10,
  //     total: 0,
  //   },
  // })
  // const exceldata:any = reactive({
  //   nhj: [],
  // })

  const state = reactive({
    pagelistcount: '',
    columns: [
      {
        title: '缺陷等级',
        key: 'name',
      },
      {
        title: 'bug数',
        key: 'value',
      },
      {
        title: '占比',
        key: 'percent',
        td: {
          is: 'percent',
        },
      },
    ],
    BugList: [],
    totalCount: 0,
    validCount: 0,
    pagecolumns: [
      {
        title: '序号',
        key: 'pageName',
        formatter: (rowData, rowIndex) => rowIndex + 1,
      },
      {
        title: '页面',
        key: 'pageName',
      },
      {
        title: '人数',
        key: 'pageNum',
        sort: true,
        td: {
          is: 'pageNum',
        },
      },
    ],
    //   pagecolumns: [
    //   { Header: '页面', accessor: 'pageName' },
    //   { Header: '页面点击次数', accessor: 'pageNum' },
    // ],

    bugoption: {
      title: {
        text: '问题分布',
        // subtext: 'Fake Data',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        orient: 'vertical',
        left: 'right',
      },
      series: [
        {
          name: '缺陷等级',
          type: 'pie',
          radius: '50%',
          data: reportdata.levelData,
          // data: [
          //   { value: 10, name: '空' },
          //   { value: 20, name: '建议' },
          //   { value: 30, name: '提示' },
          //   { value: 2, name: '一般' },
          //   { value: 4, name: '严重' },
          //   { value: 4, name: '致命' },
          // ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    },
    // totalDeviceCount: 0,
    brandoption: {
      title: {
        // text: '机型分布',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        // formatter: '{b} : {c}'+ '----'+ '{c}',
        formatter(param: any) {
          // window.console.log(param);
          return [
            `${param[0].name
            }：${
              param[0].value
            }(${
              ((param[0].value * 100) / reportdata.totalDeviceCount).toFixed(2)
            }%)`,
          ].join('<br/>')
        },
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },

      color: '#91cc75',

      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: 'category',
        // data: ['小米', '华为', 'vivo', 'OPPO', '红米', '一加']
        data: reportdata.brandnameList,
        axisLabel: {
          interval: 0,
          rotate: 40,
        },
      },
      dataZoom: [
        {
          show: true,
          yAxisIndex: 0,
          filterMode: 'empty',
          width: 10,
          height: '80%',
          showDataShadow: false,
          left: '93%',
        },
      ],
      series: [
        {
          // name: '2012',
          type: 'bar',
          // data: [10, 20, 30, 40, 50, 60]
          data: reportdata.devicevalueList,
          barMaxWidth: 30,
          itemStyle: {
            normal: {
              label: {
                show: true, // 开启显示
                position: 'inside', // 在上方显示
                textStyle: { // 数值样式
                  color: 'black',
                  fontSize: 12,
                },
              },
              // 随机显示
              color() {
                return `#${Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16)}`
              },

              // // 定制显示（按顺序）
              // color: function(params) {
              //     var colorList = ['#C33531','#EFE42A','#64BD3D','#EE9201','#29AAE3', '#B74AE5','#0AAF9F','#E89589','#16A085','#4A235A','#C39BD3 ','#F9E79F','#BA4A00','#ECF0F1','#616A6B','#EAF2F8','#4A235A','#3498DB' ];
              //     return colorList[params.dataIndex]
              // }
            },
          },
        },
      ],
    },
    deviceoption: {
      title: {
        // text: '机型分布',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        // formatter: '{b} : {c}'+ '----'+ '{c}',
        formatter(param: any) {
          // window.console.log(param);
          return [
            `${param[0].name
            }：${
              param[0].value
            }(${
              ((param[0].value * 100) / reportdata.totalDeviceCount).toFixed(2)
            }%)`,
          ].join('<br/>')
        },
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },

      color: '#91cc75',

      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: 'category',
        // data: ['小米', '华为', 'vivo', 'OPPO', '红米', '一加']
        data: reportdata.devicenameList,
        axisLabel: {
          interval: 0,
          rotate: 40,
        },
      },
      dataZoom: [
        {
          show: true,
          yAxisIndex: 0,
          filterMode: 'empty',
          width: 10,
          height: '80%',
          showDataShadow: false,
          left: '93%',
        },
      ],
      series: [
        {
          // name: '2012',
          type: 'bar',
          // data: [10, 20, 30, 40, 50, 60]
          data: reportdata.devicevalueList,
          barMaxWidth: 30,
          itemStyle: {
            normal: {
              label: {
                show: true, // 开启显示
                position: 'inside', // 在上方显示
                textStyle: { // 数值样式
                  color: 'black',
                  fontSize: 12,
                },
              },

              // 随机显示
              color() {
                return `#${Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16)}`
              },

              // // 定制显示（按顺序）
              // color: function(params) {
              //     var colorList = ['#C33531','#EFE42A','#64BD3D','#EE9201','#29AAE3', '#B74AE5','#0AAF9F','#E89589','#16A085','#4A235A','#C39BD3 ','#F9E79F','#BA4A00','#ECF0F1','#616A6B','#EAF2F8','#4A235A','#3498DB' ];
              //     return colorList[params.dataIndex]
              // }
            },
          },
        },
      ],
    },
    systemoption: {
      title: {
        // text: '系统分布',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter(param: any) {
          // window.console.log(param);
          return [
            `${param[0].name
            }：${
              param[0].value
            }(${
              ((param[0].value * 100) / reportdata.totalDeviceCount).toFixed(2)
            }%)`,
          ].join('<br/>')
        },
      },
      xAxis: {
        type: 'category',
        data: reportdata.systemnameList,
      },
      yAxis: {
        type: 'value',
      },
      color: '#FFCE34',
      series: [
        {
          data: reportdata.systemvalueList,
          type: 'bar',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
          barMaxWidth: 30,
          itemStyle: {

            normal: {
              label: {
                show: true, // 开启显示
                position: 'inside', // 在上方显示
                textStyle: { // 数值样式
                  color: 'black',
                  fontSize: 12,
                },
              },

              // 随机显示
              color() {
                return `#${Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16)}`
              },

              // // 定制显示（按顺序）
              // color: function(params) {
              //     var colorList = ['#C33531','#EFE42A','#64BD3D','#EE9201','#29AAE3', '#B74AE5','#0AAF9F','#E89589','#16A085','#4A235A','#C39BD3 ','#F9E79F','#BA4A00','#ECF0F1','#616A6B','#EAF2F8','#4A235A','#3498DB' ];
              //     return colorList[params.dataIndex]
              // }
            },
          },
        },
      ],
    },

    // pagecolumns:[
    //   {
    //     title: '页面',
    //     key: 'name',
    //   },
    //   {
    //     title: '个数',
    //     key: 'value',
    //   },
    //   {
    //     title: '占比',
    //     key: 'percent',
    //     td: {
    //       is: 'percent',
    //     },
    //   },
    // ],
    pageoption: {
      title: {
        // text: '系统分布',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter(param: any) {
          // window.console.log(param);
          return [
            `${param[0].name
            }：${
              param[0].value
            }(${
              ((param[0].value * 100) / reportdata.totalPageCount).toFixed(2)
            }%)`,
          ].join('<br/>')
        },
      },
      xAxis: {
        type: 'category',
        data: reportdata.pagenameList,
        axisLabel: { interval: 0, rotate: 30 },
      },
      grid: { // 控制图的大小，调整下面这些值就可以，
        x: 40,
        x2: 100,
        y2: 150, // y2可以控制 X轴跟Zoom控件之间的间隔，避免以为倾斜后造成 label重叠到zoom上
      },
      yAxis: {
        type: 'value',
      },
      dataZoom: [
        {
          show: true,
          start: 0,
          end: 50,
        },
        {
          type: 'inside',
          start: 0,
          end: 50,
        },
      ],
      color: '#FFCE34',
      series: [
        {
          data: reportdata.pagevalueList,
          type: 'bar',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
          barMaxWidth: 30,
          itemStyle: {

            normal: {
              label: {
                show: true, // 开启显示
                position: 'inside', // 在上方显示
                textStyle: { // 数值样式
                  color: 'black',
                  fontSize: 12,
                },
              },

              // 随机显示
              color() {
                return `#${Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16)}`
              },

              // // 定制显示（按顺序）
              // color: function(params) {
              //     var colorList = ['#C33531','#EFE42A','#64BD3D','#EE9201','#29AAE3', '#B74AE5','#0AAF9F','#E89589','#16A085','#4A235A','#C39BD3 ','#F9E79F','#BA4A00','#ECF0F1','#616A6B','#EAF2F8','#4A235A','#3498DB' ];
              //     return colorList[params.dataIndex]
              // }
            },
          },
        },
      ],
    },

  })

  //   let state.option: EChartsOption

  const initJobInfo = async () => {
    const res = await getJobInfo({ id })
    Object.assign(jobInfo, res)
    // 处理一下时间格式和任务类型显示
    jobInfo.typeValue = getKey(TYPE_MAP, jobInfo.type)
    jobInfo.startTime = formatDate(jobInfo.startTime)
    jobInfo.endTime = formatDate(jobInfo.endTime)
  }

  // 获取统计信息
  const getbugInfo = async () => {
    const res = await getbugStat({ id })
    if (res.code === 200) {
      // 获取缺陷信息节点
      state.totalCount = res.data.totalCount
      state.validCount = res.data.validCount
      if (state.totalCount > 0) {
        reportdata.levelData = res.data.bugLevelStatList
        state.BugList = res.data.bugLevelStatList

        // window.console.log(reportdata.levelData)
        // 取到缺陷分布信息后再初始化echart
        state.bugoption.series[0].data = reportdata.levelData
        const chartDom = document.getElementById('bugChart')!
        const myChart = echarts.init(chartDom)
        myChart.setOption(state.bugoption)
      // state.bugoption && myChart.setOption(state.bugoption)
      }

      // 机型总数
      reportdata.totalDeviceCount = res.data.totalDeviceCount
      reportdata.totalDeviceTypeCount = res.data.totalDeviceTypeCount
      reportdata.totalOsVersionCount = res.data.totalOsVersionCount
      // window.console.log(`sum:${reportdata.totalDeviceCount}`)
      // 获取设备信息节点
      if (reportdata.totalDeviceTypeCount > 0) {
        if (jobInfo.type === 1) {
          // 品牌处理
          reportdata.totalBrandCount = res.data.totalBrandCount
          reportdata.brandnameList = Object.keys(res.data.deviceBrandStatMap)
          reportdata.brandvalueList = Object.values(res.data.deviceBrandStatMap)
          // 取到信息后再初始化echart
          state.brandoption.yAxis.data = reportdata.brandnameList
          state.brandoption.series[0].data = reportdata.brandvalueList
          const brandchartDom = document.getElementById('brandChart')!
          const mybrandChart = echarts.init(brandchartDom)
          mybrandChart.setOption(state.brandoption)
        }

        // 机型处理
        reportdata.devicenameList = Object.keys(res.data.deviceStatMap)
        reportdata.devicevalueList = Object.values(res.data.deviceStatMap)
        // 取到信息后再初始化echart
        state.deviceoption.yAxis.data = reportdata.devicenameList
        state.deviceoption.series[0].data = reportdata.devicevalueList
        const devicechartDom = document.getElementById('deviceChart')!
        const mydeviceChart = echarts.init(devicechartDom)
        mydeviceChart.setOption(state.deviceoption)
      }
      if (reportdata.totalOsVersionCount > 0) {
        // 获取系统节点信息
        reportdata.systemnameList = Object.keys(res.data.osVersionMap)
        reportdata.systemvalueList = Object.values(res.data.osVersionMap)
        // 取到信息后再初始化echart
        state.systemoption.xAxis.data = reportdata.systemnameList
        state.systemoption.series[0].data = reportdata.systemvalueList
        const systemchartDom = document.getElementById('systemChart')!
        const mysystemChart = echarts.init(systemchartDom)
        mysystemChart.setOption(state.systemoption)
      }

      // 获取页面覆盖率
      reportdata.totalPageCount = res.data.totalPageCount
      if (reportdata.totalPageCount > 0) {
        reportdata.pagenameList = Object.keys(res.data.pageStatMap)
        reportdata.pagevalueList = Object.values(res.data.pageStatMap)

        // window.console.log(reportdata.pagevalueList)
        // 取到页面覆盖信息后再初始化echart
        state.pageoption.xAxis.data = reportdata.pagenameList
        state.pageoption.series[0].data = reportdata.pagevalueList
        const pagechartDom = document.getElementById('pageChart')!
        const mypageChart = echarts.init(pagechartDom)
        mypageChart.setOption(state.pageoption)

        // 页面覆盖率表格
        reportdata.pageList = res.data.pageStatList
        if (res.data.pageStatList.length !== 0) {
          reportdata.pageListbak = sortBykeyDesc(res.data.pageStatList, 'pageNum')
        }
      }
    } else {
      toaster.warning(res.msg)
    }
  }

  const fitlercount = async (param:any) => {
    console.log(param)
    if (param < 0) {
      toaster.warning('请输入正数！')
    } else if (param === '' || param === '0') {
      reportdata.pageList = reportdata.pageListbak
    } else {
      reportdata.pageList = reportdata.pageListbak.slice(0, param)
    }
  }

  // const prepagedata = async (pageNo:any,pageSize:any) => {
  //   window.console.log(pageNo,pageSize)
  // return Promise.resolve({
  //       list: '*'.repeat(pageSize).split('').map(() => ({ pageName: pageNo, pageNum: pageNo })),
  //       total: pagestate.pagination.total,
  //     })
  // }

  //   function handleAction(
  //     newS:any,
  //     action:any,
  //     instance:any,
  //   ) {
  //     if (action.type === 'updatePage') {
  //       prepagedata(action.pageNo,action.pageSize)
  //         .then(({ list }) => {
  //           exceldata = list
  //           instance.setPage(action)
  //         })
  //     }
  //   }

  onMounted(() => {
    initJobInfo()
    getbugInfo()
    // prepagedata(pagestate.pagination.pageNo,pagestate.pagination.pageSize)
    // .then(({ total, list }) => {
    //   window.console.log("99999999")
    //     exceldata.nhj = list
    //     pagestate.pagination.total = total
    //     window.console.log(exceldata.nhj)
    //   })
  })
</script>
<style lang="stylus" scoped>
.data-table {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-table {
  margin-top: 24px;
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>
