import { http } from '@xhs/launcher'

// export const SERVICE = ''

export function getUserInfo(): any {
  const ticket = new URLSearchParams(window.location.search).get('ticket')
  return http.get('USER_INFO', {
    params: ticket && { ticket },
  })
}

// export function logout(): Promise<any> {
//   return http.get('LOGOUT')
// }

// export async function login(service: string, ticket: string): Promise<any> {
//   service = 'http%3A%2F%2F*.dev.xiaohongshu.com%2Fark'
//   alert(service)
//   return http.post('LOGIN_SSO', {
//     service,
//     ticket,
//   })
// }

export function ssoLogout(): Promise<any> {
  const tmpservice = window.location.href
  const service = tmpservice.indexOf('.sit.') !== -1 ? tmpservice.replace('https', 'http') : tmpservice
  return http.get('CLEAR_CUSTOMER_COOKIE', {
    params: {
      service,
    },
    withCredentials: true,
  })
}

export function logout(): Promise<any> {
  return http.get('LOGOUT')
    .then(res => {
      // const tmpurl = JSON.parse(JSON.stringify(res)).redirectUrl.split('=')[1]
      // const tmpurl = String(res).split('=')[1]
      // const tmpurl = window.location.href
      const tmpurl = String(res)
      ssoLogout()
      setTimeout(() => {
        window.location.replace(tmpurl)
      }, 3000)
    })
    .catch(() => ssoLogout())
}

export function validate(): Promise<any> {
  return http.get('VALIDATE', {
    extractData: false,
  })
}

// //获取用户列表
export function getUsers(payload: {
  // page: number
  // pageSize: number
}): Promise<any> {
  return http.post('GET_USERS', payload, {
    extractData: false,
  })
}

// 更新用户信息 updateRole
export function updateRole(payload: {
  userId: string
  role: number
}): Promise<any> {
  return http.post('UPDATE_ROLE', payload, {
    extractData: false,
  })
}

// MOCK
// // 任务列表
// export function getUsers(payload: {
//   // page: number
//   // pageSize: number
// }): Promise<any> {
//   return http.post('GET_USERS_MOCK', payload, {
//     extractData: false,
//   })
// }
// export function updateRole(payload: {
//   userId: string
//   role: number
// }): Promise<any> {
//   return http.post('UPDATE_ROLE_MOCK', payload, {
//     extractData: false,
//   })
// }

// /// //////报表
// export function getUsers(payload: {
//   userId: string
//   role: number
// }): Promise<any> {
//   return http.post('STAT_INFO_MOCK', payload, {
//     extractData: false,
//   })
// }
