<template>
  <new-bs-box
    :bs="{
      marginBottom: '20px',
      marginTop: '0px',
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
    }"
  >
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
      <single-select
        v-model="state.filters.role"
        placeholder="根据角色筛选"
        :options="ROLE_OPTIONES"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px' }"
      />
      <!-- <single-select
        v-model="state.filters.version"
        placeholder="根据userid筛选"
        :options="state.versionList"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px' }"
      /> -->
      <SearchInput
        v-model="state.filters.user"
        action-position="inner"
        placeholder="根据用户名或userid搜索"
        @search="handleSearch"
      />
    </new-bs-box>
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
      <!-- <Button
        icon-before="add-m"
        @click="test({})"
      >测试</Button> -->
    </new-bs-box>

  </new-bs-box>
  <BeerTable
    class="data-table"
    size="s"
    border-style="none"
    :columns="state.columns"
    :data="state.userList"
  >
    <template #action="{ rowData }">
      <!-- <Button
        style="min-width: 30px"
        size="s"
        variant="outline"
        @click="jumpToDetail(rowData)"
      >查看</Button> -->

      <Button
        style="min-width: 20px"
        size="s"
        @click="showUserEditModal(rowData)"
      >权限设置</Button>

    </template>

  </BeerTable>
  <div class="pagination-table">
    <Pagination
      v-model="state.pagination"
      align="flex-end"
      @update:modelValue="handlePagination"
    />
  </div>
  <EditUser
    :show="userVisible"
    :data="userdatainfo"
    :mode="usermode"
    @close="hideUserModal"
    @submit="handleUserSubmit"
  />

  <!-- <EditBug
    :show="bugVisible"
    :data="bugdatainfo"
    :mode="bugmode"
    @close="hideBugModal"
    @submit="handleBugSubmit"
  /> -->

</template>

<script setup lang="ts">
  import { reactive, watch, onMounted } from 'vue'
  import {
    BeerTable,
    Button,
    NewBsBox,
    Pagination,
    toaster,
    SearchInput,
    SingleSelect,
    // Popover,
  } from '@xhs/yam-beer'
  import {
    updateRole,
    getUsers,
    // mocktest,
  } from '../../services/user'

  import { useUserModalForm } from '../../composables/useUserModalForm'
  import EditUser from '../../components/User/EditUser.vue'
  import {
    getKey, ROLE_MAP, ROLE_OPTIONES,
  } from '~/utils/common'
  //   import { USERINFO } from '../../common'
  const {
    visible: userVisible,
    data: userdatainfo,
    usermode,
    isUserEditMode,
    showUserEditModal,
    hideUserModal,
  } = useUserModalForm()

  type IFilterConfig = {
    page: number
    pageSize: number
    user?: string
    role?: number
  }
  const state = reactive({
    filters: {
      user: '',
      role: 0,
    },

    columns: [
      {
        title: '用户名',
        key: 'userName',
      },
      {
        title: 'userId',
        key: 'userId',
      },
      {
        title: '角色',
        key: 'role',
        formatter: (role: number) => getKey(ROLE_MAP, role),
      },
      {
        key: 'action',
        title: '操作',
        td: {
          is: 'action',
        },
      },

    ],
    userList: [],
    // jobList : JSON.parse('[{"creator":"aaa","endTime":"2021-11-21","jobId":123,"jobName":"xxx","startTime":"2021-11-19","status":3,"version":"1.0.0"}]'),
    pagination: {
      pageNo: 1,
      pageSize: 10,
      total: 0,
    },

  })

  const handleSearch = async () => {
    const {
      user, role,
    } = state.filters
    const params: IFilterConfig = {
      page: state.pagination.pageNo,
      pageSize: state.pagination.pageSize,
    }
    if (user) params.user = user
    if (role) params.role = role
    try {
      const res = await getUsers(params)
      if (res.data.totalCount === 0) {
        toaster.warning('暂无数据！')
      }

      state.userList = res.data.userList // 后端进行分页
      state.pagination.total = res.data.totalCount
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const handlePagination = () => {
    handleSearch()
  }

  // watch(() => state.activeItem, value => {
  //        toaster.notify(`you clicked ${state.activeItem} ------${state.activeId}`)
  //       })

  // /api/testin/user/update/role
  function handleUserSubmit(data: any) {
    const {

      userId,
      role,
    } = data

    const payload = {
      userId,
      role,
    }
    if (isUserEditMode.value) {
      updateRole(payload)
        .then(res => {
          if (res.code === 200) {
            toaster.success('设置角色成功！')
            handleSearch()
            hideUserModal()
          } else {
            toaster.warning(res.msg)
          }
        })
        .catch(err => toaster.warning(err))
    }
  }

  watch([() => state.filters.userId, () => state.filters.role], () => {
    handleSearch()
  })

  onMounted(() => {
    handleSearch()
  })
</script>

<style lang="stylus" scoped>
.data-table {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-table {
  margin-top: 24px;
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>
