export const supermenus = [

  {
    name: 'JobPage',
    title: '众测任务',
    icon: 'manage-b',

  },
  {
    name: 'DefectPage',
    title: '缺陷管理',
    icon: 'note-b',

  },
  {
    name: 'AwardPage',
    title: '众测奖励',
    icon: 'gift',
    children: [
      {
        name: 'AwardList',
        title: '奖励记录',
      },
      {
        name: 'AwardAdd',
        title: '奖励发放',
      },
      {
        name: 'AwardRank',
        title: '奖励排名',
      },
    ],
  },
  {
    name: 'StatInfo',
    icon: 'booking-b',
    title: 'Dashboard',
  },
  {
    name: 'UserPage',
    icon: 'add-friends',
    title: '用户管理',
  },
  {
    name: 'ManageCenter',
    title: '配置中心',
    icon: 'setting',
    children: [
      {
        name: 'ManageGift',
        title: '奖品管理',
      },
      {
        name: 'ManageBrand',
        title: '机型配置',
      },
      {
        name: 'ManageWhite',
        title: '白名单管理',
      },
    ],
  },

]

export const adminmenus = [

  {
    name: 'JobPage',
    title: '众测任务',
    icon: 'manage-b',

  },
  {
    name: 'DefectPage',
    title: '缺陷管理',
    icon: 'note-b',

  },
  // {
  //   name: 'AwardPage',
  //   title: '众测奖励',
  //   icon: 'gift',
  //   children: [
  //     {
  //       name: 'AwardRank',
  //       title: '奖励排名',
  //     },
  //   ],
  // },

]
export const membermenus = [

  {
    name: 'JobPage',
    title: '众测任务',
    icon: 'manage-b',

  },
  // {
  //   name: 'AwardPage',
  //   title: '众测奖励',
  //   icon: 'gift',
  //   children: [
  //     {
  //       name: 'AwardRank',
  //       title: '奖励排名',
  //     },
  //   ],
  // },

]
