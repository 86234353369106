import { http } from '@xhs/launcher'

export type BrandInfo = {
  brandNameEn: string
  brandNameCh:string
}

// 列表
export function getWhiteList(): Promise<any> {
  return http.get('WHITE_LIST', {
    extractData: false,
  })
}

// 搜索单条
export function searchWhiteId(payload: {
  whiteGroupId: number
}): Promise<any> {
  return http.get('WHITE_INFO', {
    params: payload,
  }, {
    extractData: false,
  })
}

// 新增
export function WhiteAdd(payload: {
  groupName: string
  groupId:string
  whiteGroup:string
}): Promise<any> {
  return http.post('WHITE_ADD', payload, {
    extractData: false,
  })
}

// 删除
export function deleteWhite(payload: {
  whiteGroupId: number
}): Promise<any> {
  return http.get('WHITE_DELETE', {
    params: payload,
  }, {
    extractData: false,
  })
}
// 更新
export function WhiteUpdate(payload: {
  groupName: string
  groupId:string
  whiteGroup:string
  id:string
}): Promise<any> {
  return http.post('WHITE_UPDATE', payload, {
    extractData: false,
  })
}

// // MOCK
// export function getWhiteList(): Promise<any> {
//   return http.get('WHITE_LIST_MOCK', {
//     extractData: false,
//   })
// }
