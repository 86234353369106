<template>
  <NewBsBox :bs="{ display: 'flex' }">
    <BeerPanel :bs="{ boxShadow: 'none', margin: 0, flex: 1 }">
      <BeerTitle :bs="{ mb: 16 }">
        <!-- 基本信息 -->
        {{ jobInfo.jobName }}
      </BeerTitle>
      <BeerDivider />
      <NewBsBox
        :bs="{
          padding: 24,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }"
      >
        <NewBsBox :bs="{ flex: '33%' }">
          <NewBsBox :bs="{ pb: '13px' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >版本号:</NewBsBox>
            {{ jobInfo.version }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >任务类型:</NewBsBox>
            {{ jobInfo.typeValue }}
          </NewBsBox>

          <NewBsBox :bs="{ pb: '13px', flex: '50%' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >测试开始:</NewBsBox>
            {{ jobInfo.startTime }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px', flex: '50%' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >测试结束:</NewBsBox>
            {{ jobInfo.endTime }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >buildId:</NewBsBox>
            {{ jobInfo.buildId }}
          </NewBsBox>
        </NewBsBox>
        <!-- <BeerDivider :vertical="true" /> -->
        <NewBsBox :bs="{ flex: '33%' }">
          <div style="margin-left: 110px;">
            <qrcode-vue
              :value="jobInfo.jobUrl"
              :size="130"
              level="H"
            />
            <div style="margin-left: 0px;"><span>扫码下载内测APP</span></div>
          </div>

        </NewBsBox>

      </NewBsBox>

    </BeerPanel>
    <BeerPanel :bs="{ boxShadow: 'none', margin: 0, flex: 1 }">
      <NewBsBox
        :bs="{
          padding: 0,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }"
      >
        <NewBsBox :bs="{ mr: 10 }">
          <BeerTitle :bs="{ mb: 16 }">
            上报缺陷
          </BeerTitle>
        </NewBsBox>
        <NewBsBox :bs="{ }">
          <BeerButton
            modifier="danger"
            size="m"
            icon-before="add-m"
            @click="showCreateBugModal(jobInfo)"
          > PC入口-点击上报 </BeerButton>
        </NewBsBox>
      </NewBsBox>
      <BeerDivider />

      <NewBsBox :bs="{ mt: 20 }">

        <div style="text-align:center">
          <qrcode-vue
            :value="
              state.submitBugUrl +
                '?id=' +
                jobInfo.id +
                '&version=' +
                jobInfo.version +
                '&platform=' +
                jobInfo.type +
                '&reporterId=' +
                USERINFO.userId
            "
            :size="130"
            level="H"
          />
          <div style="margin-left: 10px;">请在小红书APP内扫码上报</div>

        </div>

      </NewBsBox>

    </BeerPanel>
  </NewBsBox>

  <BeerPanel :bs="{ boxShadow: 'none', flex: 1, margin: 0, pt: 0 }">
    <BeerTitle :bs="{ mb: 16 }"> 产品简介 </BeerTitle>
    <BeerDivider />
    <NewBsBox :bs="{ fontWeight: '600', margin: 10, padding: 10 }">
      <span style="white-space: pre-wrap;">{{ jobInfo.jobInfo }}</span>
    </NewBsBox>
  </BeerPanel>
  <BeerPanel :bs="{ boxShadow: 'none', flex: 1, margin: 0, pt: 0 }">
    <BeerTitle :bs="{ mb: 16 }"> 实验白名单 </BeerTitle>
    <BeerDivider />
    <NewBsBox :bs="{ fontWeight: '600', margin: 10, padding: 10 }">
      <Tag
        v-for="(item, index) in jobInfo.whiteId"
        :key="`${index}`"
        color="teal"
        class="information-title"
        @click="jumpToExperiment(item)"
      >{{ item }}</Tag>

    </NewBsBox>
  </BeerPanel>
  <BeerPanel :bs="{ boxShadow: 'none', flex: 1, margin: 0, pt: 0 }">
    <BeerTitle :bs="{ mb: 16 }"> 任务步骤 </BeerTitle>
    <BeerDivider />

    <NewBsBox :bs="{ margin: 20, padding: 10 }">
      <Steps :current-step="state.currentStep">
        <Step
          title="查看任务"
          icon="course"
        />
        <Step
          title="扫码下载"
          icon="qr-code-b"
        />
        <Step
          title="bug提报"
          icon="account-feedback-b"
        />
      </Steps>
    </NewBsBox>
  </BeerPanel>

  <EditBug
    :show="bugVisible"
    :data="bugdatainfo"
    :mode="bugmode"
    @close="hideBugModal"
    @submit="handleBugSubmit"
  />

</template>

<script setup lang="ts">
  import {
    onMounted, reactive,
  } from 'vue'
  import { useRoute } from 'vue-router'
  import {
    NewBsBox,
    BeerPanel,
    BeerTitle,
    BeerDivider,
    BeerButton,
    // showModal,
    Steps,
    Step,
    toaster,
  } from '@xhs/yam-beer'
  import { Tag } from '@xhs/delight'
  import QrcodeVue from 'qrcode.vue'
  import { getJobInfo } from '../../services/Job'
  import type { JobInfo } from '../../services/Job'
  import { useBugModalForm } from '../../composables/useBugModalForm'
  import { postApiBug } from '../../services/Bug'
  import EditBug from '../../components/Bug/EditBug.vue'
  import { USERINFO } from '../../common'
  import {
    formatDate, getKey, TYPE_MAP,
  } from '~/utils/common'

  // import { useModal } from '~/composables/useModal'

  const route = useRoute()
  const id = Number(route.params.id)
  const str = window.location.href
  const h5url = `${str.indexOf('.sit.') !== -1 ? 'test.sit.xiaohongshu.com' : 'test.xiaohongshu.com'}`

  const state = reactive({
    currentStep: 2,
    // submitBugUrl: 'xhsdiscover://webview//10.31.170.66:1389/bug/add',
    submitBugUrl: `xhsdiscover://webview/${h5url}/bug/add`,
  })

  const jobInfo = reactive<JobInfo>({

    jobName: '',
    version: '',
    type: 0,
    jobInfo: '',
    startTime: '',
    endTime: '',
    jobUrl: '',
    typeValue: '',
    buildId: '',
    whiteId: [],
  })

  // const { visible: repoModalVisible, toggleModal: toggleRepoModal } = useModal()
  const {
    visible: bugVisible,
    data: bugdatainfo,
    bugmode,
    // isBugEditMode,
    showCreateBugModal,
    hideBugModal,
  } = useBugModalForm()

  const jumpToExperiment = async (item: any) => {
    // console.log(item)
    const experimenturl = `https://experiment.devops.xiaohongshu.com/#/lumos/detail/${item}`
    window.open(experimenturl)
  }

  const initJobInfo = async () => {
    const res = await getJobInfo({ id })
    Object.assign(jobInfo, res)
    // 处理一下时间格式和任务类型显示
    jobInfo.typeValue = getKey(TYPE_MAP, jobInfo.type)
    jobInfo.startTime = formatDate(jobInfo.startTime)
    jobInfo.endTime = formatDate(jobInfo.endTime)
  }

  // 提交PC端缺陷表单
  function handleBugSubmit(bugdata:any) {
    const {
      title, bugInfo, platform, deviceSystem, jobId, version,
    } = bugdata
    //  if(bugdata.isRecur == ''){
    //   var tmpisRecur= 1
    //  } else {
    //    var tmpisRecur= parseInt(bugdata.isRecur)
    //  }
    const payload = {
      title,
      bugInfo,
      isRecur: Number(bugdata.isRecur),
      platform,
      deviceSystem,
      jobId,
      version,
      attachmentList: bugdata.bugfileList,
      // reporter: redName.Auth.userInfo.nickName, // 登陆
      reporterId: USERINFO.userId,
      deviceBrand: bugdata.deviceBrand, // PC端提交bug添加系统设备
    }
    // window.console.log("payload")
    // window.console.log(payload)
    postApiBug(payload)
      .then(res => {
        if (res.code === 200) {
          toaster.success('创建bug成功')
          hideBugModal()
        } else {
          toaster.warning(res.msg)
        }
      })
      .catch(err => toaster.warning(err))
  }

  // watch(() => projectInfo.value, val => {
  //   window.console.log()
  // })
  onMounted(() => {
    initJobInfo()
  })
</script>

<style lang="stylus" scoped>
.information-title {
  font-size: 18px;
  cursor: pointer;
  margin:5px;
}
</style>
