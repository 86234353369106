import { http } from '@xhs/launcher'

export type AwardInfo = {
  awardName: string
  num: number
}

// 奖品列表
export function getAwardList(payload: {
  page: number
  pageSize: number
}): Promise<any> {
  return http.post('AWARD_LIST', payload,
    {
      extractData: false,
    })
}

// 新增奖品
export function AwardAdd(payload: {
  awardName: string
  num: number
}): Promise<any> {
  return http.post('AWARD_ADD', payload, {
    extractData: false,
  })
}

// 更新库存数量
export function UpdateAwardNum(payload: {
  awardId: number
  num: number
}): Promise<any> {
  return http.post('UPDATE_AWARD_NUM', payload, {
    extractData: false,
  })
}
// 更新礼物名字
export function updateAwardName(payload: {
  awardId: number
  awardName: string
}): Promise<any> {
  return http.post('UPDATE_AWARD_NAME', payload, {
    extractData: false,
  })
}

// 删除
export function deleteAward(payload: {
  awardId: number
}): Promise<any> {
  return http.get('AWARD_DELETE', {
    params: payload,
  }, {
    extractData: false,
  })
}

// 获奖记录
export function getRewardLogs(payload: {
  page: number
  pageSize: number
}): Promise<any> {
  return http.post('AWARDLOG_LIST', payload,
    {
      extractData: false,
    })
}
// 获取礼物类型
export function getValidAwardList(): Promise<any> {
  return http.get('AWARDNAME_LIST',
    {
      extractData: false,
    })
}

// 发放奖励
export function rewardAdd(payload: {
}): Promise<any> {
  return http.post('AWARD_ADD_USER', payload,
    {
      extractData: false,
    })
}
// 用户积分排名
export function scoreRank(payload: {
  page: number
  pageSize: number
}): Promise<any> {
  return http.post('AWARD_USER_SCORERANK', payload,
    {
      extractData: false,
    })
}

// 内部员工用户积分排名
export function staffRank(payload: {
  page: number
  pageSize: number
}): Promise<any> {
  return http.post('AWARD_USER_STAFFRANK', payload,
    {
      extractData: false,
    })
}
export function departRank(payload: {
  page: number
  pageSize: number
}): Promise<any> {
  return http.post('AWARD_USER_DEPRANK', payload,
    {
      extractData: false,
    })
}

// // MOCK
// export function getAwardList(payload: {
//   page: number
//   pageSize: number
// }): Promise<any> {
//   return http.post('AWARD_LIST_MOCK', payload,
//     {
//       extractData: false,
//     })
// }

// export function getValidAwardList(): Promise<any> {
//   return http.get('AWARDNAME_LIST_MOCK', {
//     extractData: false,
//   })
// }
