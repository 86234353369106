import { ref } from 'vue'

export const useModal = ():any => {
  const visible = ref<boolean>(false)

  function toggleModal(v: boolean) {
    visible.value = v
  }

  return {
    visible,
    toggleModal,
  }
}
