import { ModuleTree } from 'vuex'
import { IRootState } from '@xhs/launcher-plugin-store'

import user from './modules/user'

import { IUserState } from './types'

// 扩充 store 类型
declare module '@xhs/launcher-plugin-store' {
  interface IRootState {
    user: IUserState
  }
}

const modules: ModuleTree<IRootState> = {
  user,
}

export default { modules }
