import { ref, computed } from 'vue'
import { useModal } from './useModal'

export const FORM_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
}

export const useModalForm:any = (init?:any):any => {
  const initData = init === undefined ? {} : init
  const mode = ref('')
  const data = ref<any>(initData)
  const isEditMode = computed(() => mode.value === FORM_MODE.EDIT)

  const {
    visible,
    toggleModal,
  } = useModal()

  function showCreateModal(newData: any) {
    data.value = newData
    mode.value = FORM_MODE.CREATE
    toggleModal(true)
  }
  // 时间格式化只留年月日
  function formatDate(date:any) { // 设置时间转换格式
    const time = new Date(date)
    const y:number|string = time.getFullYear() // 获取年
    let m:number|string = time.getMonth() + 1 // 获取月
    m = m < 10 ? `0${m}` : m // 判断月是否大于10
    let d:number|string = time.getDate() // 获取日
    d = d < 10 ? (`0${d}`) : d // 判断日期是否大10
    return `${y}-${m}-${d}` // 返回时间格式
  }

  function showEditModal(newData: any) {
    // console.log("99999-----编辑")
    // console.log(newData)
    // 测试区间给testTime处理
    newData.testTime = {
      start: formatDate(newData.startTime),
      end: formatDate(newData.endTime),
    }
    data.value = newData
    mode.value = FORM_MODE.EDIT
    toggleModal(true)
  }

  function hideModal() {
    data.value = initData
    toggleModal(false)
  }

  return {
    data,
    mode,
    isEditMode,
    visible,
    showCreateModal,
    showEditModal,
    hideModal,

  }
}
