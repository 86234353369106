<template>
  <new-bs-box
    :bs="{
      marginBottom: '20px',
      marginTop: '0px',
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
    }"
  >
    <new-bs-box :bs="{ marginRight: '20px', marginTop: '20px' }">
      <DateRangePicker
        v-model:start="state.dayRange.start"
        v-model:end="state.dayRange.end"
        fixed
        @update:start="updateStartDate"
        @update:end="updateEndDate"
      />
    </new-bs-box>
  </new-bs-box>

  <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
    <!-- <Tabs  v-model="state.tabvalue" @update:modelValue="handleSelectTab"
      :list="[
    { name: '1', label: 'Android'},
    { name: '2', label: 'iOS'},

  ]">
<template #default="{ currentTabItem }">
      {{ currentTabItem.name }}
       <div
          id="statChart"
          :style="{ margin: 0, height: '600px' }"
        />
    </template>
     </Tabs> -->
    <BeerTabs
      v-model="state.tabvalue"
      @update:modelValue="handleSelectTab"
    >
      <TabPane
        name="1"
        label="Android"
      >
        <template #tab>
          <Icon
            :color="red"
            icon="camera-b"
          />
          Android
        </template>
        <div
          id="statChartAndroid"

          :style="{ margin: 0, height: '500px',width: '100%' }"
        />
      </TabPane>
      <TabPane
        name="2"
        label="iOS"
      >
        <template #tab>
          <Icon
            :color="null"
            icon="collect-b"
          />
          iOS
        </template>
        <div
          id="statChartiOS"

          :style="{ margin: 0, height: '500px',width: '100%' }"
        />
      </TabPane>

    </BeerTabs>
  </new-bs-box>

</template>

<script setup lang="ts">
  import { reactive, onMounted } from 'vue'
  //  import { Space, DatePicker, DateRangePicker, Text , Result} from '@xhs/delight'
  //  import { Android,Apple } from '@xhs/delight/icons'
  import {
    NewBsBox,
    toaster,
    BeerTabs, TabPane, Icon,
    DateRangePicker,
  } from '@xhs/yam-beer'
  // import BeerDateRangePicker from '@xhs/yam-beer/cmp/dataEntry/DatePicker/DateRangePicker'
  import * as echarts from 'echarts'
  // import { EleResize } from '@/utils/esresize'

  // import dayjs from 'dayjs'
  // import { getUsers } from '../../services/user'
  import { GetStatBug, StatInfo } from '../../services/Job'

  import {
    getCurrentDate, beforeDays,
  } from '~/utils/common'

  const reportdata = reactive({
    versionList: [] as any[],
    sumbugList: [] as any[],
    validatebugList: [] as any[],
    modelList: [] as any[],
    osVersionList: [] as any[],
    pageList: [] as any[],
    pageRateList: [] as any[],
    peopleList: [] as any[],
  })

  // const statChart = reactive({
  //   statChart:'statChart'
  //   })

  const state = reactive({
    dayRange: { start: '', end: '' },
    tabvalue: '1',
    // statChart: 'statChart',
    time: '',
    statoption: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },

      //   formatter(params:any) {
      //     // window.console.log(params)
      // // let str = params[0].name + '<br />'
      // params.forEach((item:any) => {
      //   if (item.seriesName === '页面覆盖率') {
      //       return `${item.marker}${item.seriesName}: ${item.value}%<br />`
      //    } else {
      //       return `${item.marker}${item.seriesName}: ${item.value}个<br />`
      //    }
      // })
      // }

      },
      toolbox: {
        feature: {
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar'] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        data: ['总bug数', '有效bug数', '机型总数', '系统总数', '覆盖页面数', '页面覆盖率', '参与人数'],
      },
      xAxis: [
        {
          type: 'category',
          data: reportdata.versionList, // 横轴为版本号
          axisPointer: {
            type: 'shadow',
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '个',
          min: 0,
          max: 2000,
          interval: 200,
          axisLabel: {
            formatter: '{value} ',
          },
        },
        {
          type: 'value',
          name: '百分比',
          min: 0,
          max: 100,
          interval: 20,
          axisLabel: {
            formatter: '{value} %',
          },
        },
      ],
      series: [
        {
          name: '总bug数',
          type: 'bar',
          tooltip: {
            valueFormatter(value: any) {
              return `${value} 个`
            },
          },
          data: reportdata.sumbugList,
          label: {
            show: true, // 开启显示
            position: 'inside', // 在上方显示
            color: 'black',
            fontSize: 12,
          },
        },
        {
          name: '有效bug数',
          type: 'bar',
          tooltip: {
            valueFormatter(value: any) {
              return `${value} 个`
            },
          },
          data: reportdata.validatebugList,
          label: {
            show: true, // 开启显示
            position: 'inside', // 在上方显示
            color: 'black',
            fontSize: 12,
          },
        },
        {
          name: '机型总数',
          // barMaxWidth: 30,
          type: 'bar',
          tooltip: {
            valueFormatter(value: any) {
              return `${value} 个`
            },
          },
          data: reportdata.modelList,
          label: {
            show: true, // 开启显示
            position: 'inside', // 在上方显示
            color: 'black',
            fontSize: 12,
          },
        },
        {
          name: '系统总数',
          // barMaxWidth: 30,
          type: 'bar',
          tooltip: {
            valueFormatter(value: any) {
              return `${value} 个`
            },
          },
          data: reportdata.osVersionList,
          label: {
            show: true, // 开启显示
            position: 'inside', // 在上方显示
            color: 'black',
            fontSize: 12,
          },
        },
        {
          name: '覆盖页面数',
          // barMaxWidth: 30,
          type: 'bar',
          tooltip: {
            valueFormatter(value: any) {
              return `${value} 个`
            },
          },
          data: reportdata.pageList,
          label: {
            show: true, // 开启显示
            position: 'inside', // 在上方显示
            color: 'black',
            fontSize: 12,
          },
        },
        {
          name: '页面覆盖率',
          type: 'line',
          // barMaxWidth: 30,
          yAxisIndex: 1,
          tooltip: {
            valueFormatter(value: any) {
              return `${value} %`
            },
          },
          data: reportdata.pageRateList,
          label: {
            show: true, // 开启显示
            position: 'top', // 在上方显示
            color: 'black',
            fontSize: 12,
            formatter(params:any) {
              // window.console.log(params.value)
              return `${params.value}%`
            },
          },
        },
        {
          name: '参与人数',
          type: 'line',
          // barMaxWidth: 30,
          yAxisIndex: 0,
          tooltip: {
            valueFormatter(value: any) {
              return `${value} 个`
            },
          },
          data: reportdata.peopleList,
          label: {
            show: true, // 开启显示
            position: 'top', // 在上方显示
            color: 'black',
            fontSize: 12,
          },
        },
      ],
    },
  })

  function handleStatinfo() {
    const payload = {
      startTime: state.dayRange.start,
      endTime: state.dayRange.end,
      type: Number(state.tabvalue),
    }

    // window.console.log(`ios-----${state.tabvalue}`)

    // 后端分开写数据，前端聚合，获取bug分布
    GetStatBug(payload)
      // getUsers(payload)// mock临时
      .then(res => {
        if (res.code === 200) {
          res.data.forEach((item:any) => {
            reportdata.versionList.push(item.version)
            reportdata.sumbugList.push(item.totalBug)
            reportdata.validatebugList.push(item.validateBug)
            // // reportdata.peopleList.push(item.peoplecount) //另一个接口获取
          })

          // 先赋值
          state.statoption.xAxis[0].data = reportdata.versionList
          state.statoption.series[0].data = reportdata.sumbugList
          state.statoption.series[1].data = reportdata.validatebugList
        } else {
          toaster.warning(res.msg)
        }
      })
      .catch(err => toaster.warning(err))

    // 获取参与人数
    StatInfo(payload)
      // getUsers(payload)// mock临时
      .then(res => {
        if (res.code === 200) {
          res.data.forEach((item:any) => {
            reportdata.modelList.push(item.model)
            reportdata.osVersionList.push(item.osVersion)
            reportdata.pageList.push(item.pageNum)
            reportdata.pageRateList.push((Number(item.pageRate) * 100).toFixed(2)) // Number(point*100).toFixed(1)
            reportdata.peopleList.push(item.appSum)
          })

          // reportdata.peopleList[0] = Math.random()
          state.statoption.series[2].data = reportdata.modelList
          state.statoption.series[3].data = reportdata.osVersionList
          state.statoption.series[4].data = reportdata.pageList
          state.statoption.series[5].data = reportdata.pageRateList
          state.statoption.series[6].data = reportdata.peopleList

          // window.console.log("人数------"+JSON.stringify(state.statoption.series[2].data))
          // 取完数据就init
          if (state.tabvalue === '1') {
            // window.console.log(`has-----state.statoption.series[0].data---前---${JSON.stringify(state.statoption.series[2].data)}`)
            if (document.getElementById('statChartAndroid')!.hasAttribute('_echarts_instance_')) {
              document.getElementById('statChartAndroid')!.removeAttribute('_echarts_instance_')
            }
            const mystatChart = echarts.init(document.getElementById('statChartAndroid')!)

            // document.getElementById('statChartAndroid')!.setAttribute('_echarts_instance_', '')
            // mystatChart.dispose()
            mystatChart.clear()
            mystatChart.setOption(state.statoption, true)
            window.onresize = function () {
              mystatChart.resize()
            }
            // window.console.log(`has-----state.statoption.series[0].data---后---${JSON.stringify(state.statoption.series[2].data)}`)
          // mystatChart.()
          } else if (state.tabvalue === '2') {
            if (document.getElementById('statChartiOS')!.hasAttribute('_echarts_instance_')) {
              document.getElementById('statChartiOS')!.removeAttribute('_echarts_instance_')
            }
            const mystatChart = echarts.init(document.getElementById('statChartiOS')!)
            mystatChart.clear()
            mystatChart.setOption(state.statoption, true)
            window.onresize = function () {
              mystatChart.resize()
            }
          }
        } else {
          toaster.warning(res.msg)
        }
      })
      .catch(err => toaster.warning(err))
  }
  const updateStartDate = () => {
    state.dayRange.end = ''
  }
  const updateEndDate = () => {
    if (state.dayRange.start !== null && state.dayRange.end !== '' && state.dayRange.end !== null) {
      // window.console.log('判断里边的')
      reportdata.versionList = []
      reportdata.sumbugList = []
      reportdata.validatebugList = []
      reportdata.modelList = []
      reportdata.osVersionList = []
      reportdata.pageList = []
      reportdata.pageRateList = []
      reportdata.peopleList = []

      handleStatinfo()
    }
  }

  const handleSelectTab = () => {
    reportdata.versionList = []
    reportdata.sumbugList = []
    reportdata.validatebugList = []
    reportdata.modelList = []
    reportdata.osVersionList = []
    reportdata.pageList = []
    reportdata.pageRateList = []
    reportdata.peopleList = []

    handleStatinfo()
  }

  // watch([() => state.statoption], () => {
  //   const statChartDom = document.getElementById('statChart')!
  //   const mystatChart = echarts.init(statChartDom)
  //   mystatChart.setOption(state.statoption, true)
  // })
  onMounted(() => {
    state.dayRange.end = getCurrentDate()
    state.dayRange.start = beforeDays(14)
    state.tabvalue = '1'// 默认为安卓
    handleStatinfo()
  })
</script>

<style lang="stylus" scoped>
.data-table {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-table {
  margin-top: 24px;
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>
