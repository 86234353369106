import { withDialog } from '@xhs/yam-beer'
import {
  logout, ssoLogout, getUserInfo, validate,
} from './services/user'
import {
  getPorchHost, USERINFO, ADMIN_PAGE, MEMBER_PAGE,
} from './common'

const confirmAuthorizedModal = withDialog({
  title: '你没有操作的权限，点击确定重新登录',
  cancelText: null,
  confirmText: '刷新',
})
const ticketFailedModal = withDialog({
  title: '登录失败',
  cancelText: null,
  confirmText: '重试',
})
const noAuthModel = withDialog({
  title: '用户未授权',
  cancelText: null,
  confirmText: '重新登录',
})
const shopFailedModal = withDialog({
  title: '登录失败, 无可用店铺',
  cancelText: null,
  confirmText: '重新登录',
})
const noMenuModel = withDialog({
  title: '没有页面权限',
  cancelText: null,
  confirmText: '确定',
})

const EXCLUDE_APIS = [
  'abtest/v2',
  'sso_login',
]

const isExcluded = (url: string) => EXCLUDE_APIS.some(path => url.indexOf(path) > -1)

function getParams() {
  const regex = /[?&]([^=#]+)=([^&#]*)/g
  const url = window.location.href
  const params: any = {}
  let match

    while (match = regex.exec(url)) { // eslint-disable-line
    params[match[1]] = match[2]
  }
  return params
}

function getSsoService(retry: boolean = false) {
  const params = getParams()
  delete params.ticket
  const newQuery = Object.keys(params)
    .map(k => (`${k}=${params[k]}`))
    .join('&')
  let currentPathName = window.location.pathname
  if (retry) {
    // currentPathName = ''
    currentPathName = window.location.pathname
  }
  // eslint-disable-next-line
  const path = (window as any).__POWERED_BY_QIANKUN__ ? `${currentPathName}` : currentPathName
  return window.encodeURI(`${window.location.origin}${path}${newQuery ? '?' : ''}${newQuery}`)
}

function getPorchRequestUrl(retry: boolean) {
  const host = getPorchHost()
  // 如果是移动端 下面return的地址应该是`${host}/mobile/login?&service=${getSsoService(retry)}`
  return `${host}/login?&service=${getSsoService(retry)}`
}

export const LOCAL_VALUE = {
  redirecting: false,
}

/**
* 去 Porch 登陆页面，如果登陆成功返回当前的地方
* - 如果是重试的话，就返回到首页。
*
* @param {*} [options={ retry: boolean }]
* @returns {boolean} redicting is exsiting ?
*/
export function toPorch(options: any = {}): boolean {
  const url = getPorchRequestUrl(options.retry)
  window.location.href = url
  LOCAL_VALUE.redirecting = true
  return true
}

/**
* 当 sso_login 验证 ticket 失败
*/
function onTicketFailed(e: any) {
  if (e.status === 401) {
    window.console.warn('sso_login return 401, check your dev_host & sso host is match')
  }
  if (e.message === '无可用店铺') {
    shopFailedModal(() => {
      ssoLogout()
        .then(() => toPorch())
        .catch(() => toPorch())
    })
    return
  }
  if (e.status === 403) {
    noAuthModel(() => {
      ssoLogout()
        .then(() => toPorch())
        .catch(() => toPorch())
    })
    return
  }
  ticketFailedModal(() => {
    ssoLogout()
      .then(() => toPorch())
      .catch(() => toPorch())
  })
}
export function isInArray(arr:any, value:any) {
  for (let i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return true
    }
  }
  return false
}

export function isIndexOfArray(arr:any, value:any) {
  for (let i = 0; i < arr.length; i++) {
    if (value.indexOf(arr[i]) !== -1) {
      return true
    }
  }
  return false
}

export function checkTicketAndNext(to: { [key: string]: any }, from: { [key: string]: any }, next: (args?: any) => void): any {
//   window.console.log(JSON.stringify(to))
  const assignTo = { ...to }
  if (to.query.ticket) {
    return getUserInfo()
      .then(() => {
        assignTo.query.ticket = undefined
        return next(assignTo)
      })
      .catch((e: any) => {
        onTicketFailed(e)
      })
  }

  return validate().then(res => {
    if (res.code !== 200) {
      if (String(res.data).indexOf('service=') !== -1) {
        return toPorch()
      }
    }
    if (res.code === 200) {
      USERINFO.avatar = res.data.avatar
      USERINFO.nickName = res.data.nickName
      USERINFO.userId = res.data.userId
      USERINFO.role = res.data.role

      // window.console.log(`sso---===${USERINFO.role}----userInfo------${JSON.stringify(USERINFO)}`)

      if (USERINFO.role === 3 && !isIndexOfArray(MEMBER_PAGE, to.path)) {
        const str = window.location.href
        const tmpstr = `${str.indexOf('.sit.') !== -1 ? 'http://test.sit.xiaohongshu.com' : 'https://test.xiaohongshu.com'}`
        noMenuModel(() => {
          window.location.replace(tmpstr)
        })
      }
      if (USERINFO.role === 2 && !isIndexOfArray(ADMIN_PAGE, to.path)) {
        const str = window.location.href
        const tmpstr = `${str.indexOf('.sit.') !== -1 ? 'http://test.sit.xiaohongshu.com' : 'https://test.xiaohongshu.com'}`
        noMenuModel(() => {
          window.location.replace(tmpstr)
        })
      }
    }
    return next()
  })

  // USERINFO.role = 1
  // return next() // mock测试用
}

export function checkAndHandleHttperror(err: { [key: string]: any }, req: { [key: string]: any }, config: { [key: string]: any }): Error {
  if (config.url && isExcluded(config.url)) {
    throw err
  }
  if (err.status === 401) {
    toPorch()
  } else if (err.status === 403) {
    confirmAuthorizedModal(() => {
      logout()
        .then(() => toPorch())
        .catch(() => toPorch())
    })
  }

  throw err
}
