import { ref, computed } from 'vue'
import { useModal } from './useModal'

export const FORM_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
}

export const useBugModalForm:any = (init?:any):any => {
  const initBugData = init === undefined ? {} : init

  const bugmode = ref('')
  const data = ref<any>(initBugData)
  const isBugEditMode = computed(() => bugmode.value === FORM_MODE.EDIT)

  const {
    visible,
    // bugvisible,
    toggleModal,
  } = useModal()

  function showCreateBugModal(newData: any) {
    // 父组件信息处理
    const newBugData = {
      //   jobName: newData.jobName,
      version: newData.version,
      jobId: newData.id,
      platform: newData.type,
    }

    data.value = newBugData
    bugmode.value = FORM_MODE.CREATE
    toggleModal(true)
  }

  function hideBugModal() {
    data.value = initBugData
    toggleModal(false)
  }

  return {
    visible,
    data,
    bugmode,
    isBugEditMode,
    // bugvisible,
    showCreateBugModal,
    hideBugModal,
  }
}
