import { http } from '@xhs/launcher'

export type BrandInfo = {
  brandNameEn: string
  brandNameCh:string
}

// 设备列表
export function getBrandList(payload: {
  page: number
  pageSize: number
}): Promise<any> {
  return http.post('BRAND_LIST', payload,
    {
      extractData: false,
    })
}

// 新增设备
export function BrandAdd(payload: {
  brandNameEn: string
  brandNameCh:string
}): Promise<any> {
  return http.post('BRAND_ADD', payload, {
    extractData: false,
  })
}

// 删除
export function deleteBrand(payload: {
  deviceId: number
}): Promise<any> {
  return http.get('BRAND_DELETE', {
    params: payload,
  }, {
    extractData: false,
  })
}
// PC上报bug获取设备列表
export function getDeviceNameList(): Promise<any> {
  return http.get('DEVICE_LIST', {
    extractData: false,
  })
}

// // 获取机型
// export function getdeviceStat(payload: {
//   id: number
// }): Promise<any> {
//   return http.get('BRAND_STAT', {
//     params: payload,
//   }, {
//     extractData: false,
//   })
// }

// // // MOCK
// export function getdeviceStat(payload: {
//   id: number
// }): Promise<any> {
//   return http.get('BRAND_STAT_MOCK', {
//     params: payload,
//   },{
//     extractData: false,
//   })
// }

// export function getDeviceNameList(): Promise<any> {
//   return http.get('DEVICE_LIST_MOCK', {
//     extractData: false,
//   })
// }
