<template>
  <new-bs-box
    :bs="{
      marginBottom: '20px',
      marginTop: '0px',
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
    }"
  >
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
      <single-select
        v-model="state.filters.jobId"
        placeholder="根据任务筛选"
        :options="state.jobNameList"
        :show-clear="true"
        :filterable="true"
        :bs="{ mr: '10px',width:140 }"
      />

      <SearchInput
        v-model="state.filters.user"
        action-position="inner"
        placeholder="根据用户名或userid搜索"
        @search="handleSearch"
      />
    </new-bs-box>
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }" />

  </new-bs-box>
  <BeerTable
    class="data-table"
    size="s"
    border-style="none"
    :columns="state.columns"
    :data="state.awardList"
  >

    <!-- <template #rank="{ rowIndex }">
      <span v-if="rowIndex+1 ==1"><img
        class="manImg"
        src="./../../static/rangking_l_1.png"
      ></span>
      <span v-else-if="rowIndex+1 ==2"><img
        class="manImg"
        src="./../../static/rangking_l_2.png"
      > </span>
      <span v-else-if="rowIndex+1 ==3"><img
        class="manImg"
        src="./../../static/rangking_l_3.png"
      > </span>
      <span
        v-else
        class="no"
      >{{ rowIndex+1 }} </span>

    </template> -->

  </BeerTable>
  <div class="pagination-table">
    <Pagination
      v-model="state.pagination"
      align="flex-end"
      @update:modelValue="handlePagination"
    />
  </div>
</template>

<script setup lang="ts">
  import { reactive, watch, onMounted } from 'vue'
  import {
    BeerTable,
    NewBsBox,
    Pagination,
    toaster,
    SearchInput,
    SingleSelect,
    // Popover,
  } from '@xhs/yam-beer'

  import { getjobnameList } from '../../services/Job'
  import { getRewardLogs } from '../../services/Award'
  import { getKey } from '~/utils/common'

  const typemap = {
    1: '任务维度',
    2: '用户维度',
  }
  type IFilterConfig = {
    page: number
    pageSize: number
    user?: string
    jobId?:number
  }
  const state = reactive({
    filters: {
      user: '',
      jobId: 0,
    },
    jobNameList: [],
    // columns: [
    //   {
    //     title: '排名',
    //     key: 'rank',
    //     td: {
    //       is: 'rank',
    //     },
    //   },
    //   {
    //     title: '用户名',
    //     key: 'userName',
    //   },
    //   // {
    //   //   title: 'userId',
    //   //   key: 'userId',
    //   // },
    //   {
    //     title: 'bug总数',
    //     key: 'sumnum',
    //   },
    //   {
    //     title: '有效bug',
    //     key: 'validnum',
    //   },
    //   {
    //     title: '积分',
    //     key: 'score',
    //   },

    // ],
    columns: [
      {
        title: '用户名',
        key: 'userName',
      },
      // {
      //   title: 'userId',
      //   key: 'userId',
      // },
      {
        title: '任务名称',
        key: 'jobName',
      },
      {
        title: '奖励类型',
        key: 'type',
        formatter: (type: number) => getKey(typemap, type),
      },
      {
        title: '消耗积分',
        key: 'scoreCost',
        formatter: (scoreCost:any) => `-${String(scoreCost)}`,
      },
      {
        title: '奖励详情',
        key: 'rewardInfo',
        formatter: (rewardInfo:any) => JSON.stringify(rewardInfo),
      },

    ],
    awardList: [],
    // awardList : JSON.parse('[{"userName":"aaa","sumnum":30,"validnum":10,"score":100},{"userName":"bbb","sumnum":30,"validnum":10,"score":100},{"userName":"ccc","sumnum":30,"validnum":10,"score":100},{"userName":"ddd","sumnum":30,"validnum":10,"score":100}]'),
    pagination: {
      pageNo: 1,
      pageSize: 10,
      total: 0,
    },

  })

  const handlenameList = async () => {
    try {
      const res = await getjobnameList()
      state.jobNameList = res.list
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const handleSearch = async () => {
    const {
      user, jobId,
    } = state.filters
    const params: IFilterConfig = {
      page: state.pagination.pageNo,
      pageSize: state.pagination.pageSize,
    }
    if (user) params.user = user
    if (jobId) params.jobId = jobId
    try {
      const res = await getRewardLogs(params)
      if (res.data.totalCount === 0) {
        toaster.warning('暂无数据！')
      }

      state.awardList = res.data.logList // 后端进行分页
      state.pagination.total = res.data.totalCount
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const handlePagination = () => {
    handleSearch()
  }

  // watch(() => state.activeItem, value => {
  //        toaster.notify(`you clicked ${state.activeItem} ------${state.activeId}`)
  //       })

  watch([() => state.filters.jobId], () => {
    handleSearch()
  })

  onMounted(() => {
    handlenameList()
    handleSearch()
  })
</script>

<style lang="stylus" scoped>
.data-table {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-table {
  margin-top: 24px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.manImg{
width: 30px;
height: 25px;
}
</style>
