import Launcher from '@xhs/launcher'
import '@xhs/delight/style/index.styl'
import storePlugin from '@xhs/launcher-plugin-store'

// import authPlugin from '@xhs/launcher-plugin-auth'
// import workerPlugin from '@xhs/launcher-plugin-worker'
import httpConfig from './config/http.config'
import routes from './config/routes.config'

import storeConfig from './store/index'
// import getAuthConfig from './config/auth.config'
import './utils/index.css'

import {
  checkTicketAndNext,
  checkAndHandleHttperror,
} from './sso' //  这里写自己项目里上面sso.ts的文件path

const app = new Launcher('#app', {
  routes,
  http: httpConfig,
})

app.use(storePlugin, storeConfig)
// app.use(authPlugin, getAuthConfig())
// app.use(workerPlugin, {
//   appName: 'testin-fe',
//   appVersion: '1.0.0',
//   getUserInfo: () => Promise.resolve(
//     {
//       primaryUserId: app.store.getters.userInfo.userId,
//       secondaryUserId: app.store.getters.userInfo.userId,
//     },
//   ),
// })

app.start()

// 进入路由前处理ticket
app.router.beforeEach(checkTicketAndNext)
// 拦截器处理401 403
Launcher.http.interceptors.result.use(undefined, checkAndHandleHttperror)

export default app
