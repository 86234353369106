import { ref, computed } from 'vue'
import { useModal } from './useModal'

export const FORM_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
}

export const useWhiteModalForm:any = (init?:any):any => {
  const initWhiteData = init === undefined ? {} : init

  const whitemode = ref('')
  const data = ref<any>(initWhiteData)
  const isWhiteEditMode = computed(() => whitemode.value === FORM_MODE.EDIT)

  const {
    visible,
    // bugvisible,
    toggleModal,
  } = useModal()

  function showCreateWhiteModal(newData: any) {
    //   // 父组件信息处理
    //   const newBugData = {
    //     //   jobName: newData.jobName,
    //     version: newData.version,
    //     jobId: newData.id,
    //     platform: newData.type,
    //   }

    //   data.value = newBugData
    window.console.log('222')
    window.console.log(JSON.stringify(newData))
    data.value = newData
    whitemode.value = FORM_MODE.CREATE
    toggleModal(true)
  }

  function showWhiteEditModal(newData: any) {
    const newWhiteData = {
      groupName: newData.groupName,
      groupId: newData.groupId,
      whiteGroup: String(newData.whiteGroup),
      id: String(newData.id),
    }
    // window.console.log("编辑------")
    // window.console.log(JSON.stringify(newWhiteData))
    data.value = newWhiteData
    whitemode.value = FORM_MODE.EDIT
    toggleModal(true)
  }

  function hideWhiteModal() {
    data.value = initWhiteData
    toggleModal(false)
  }

  return {
    visible,
    data,
    whitemode,
    isWhiteEditMode,
    showCreateWhiteModal,
    showWhiteEditModal,
    hideWhiteModal,
  }
}
