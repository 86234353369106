<template>
  <div>
    <div class="swiper">
      <div class="box-fluid">
        <h1>
          众测平台
        </h1>
        <h3>
          在线发布测试任务，海量测试人员利用碎片化时间通过对内测项目进行操作验证项目是否会出现闪退、崩溃、UI等问题以及建议
        </h3>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'Swiper',
    data() {
      return {
        // 轮播图控制参数
        indexItem: 0,
      }
    },
  }
</script>

<style scoped>
  .swiper{
    font-size: 20px;
    width: 100%;
    height: 420px;
    background-image: url(./../../static/banner.png);
    background-size: 100% 100%;
    color: #fff;
    margin: 0px 0px 0px
  }
  .box-fluid{
    position: relative;
    padding: 120px 100px 0px
  }
  .content{
    font-size: 16px;
  }
</style>
