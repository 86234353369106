<template>
  <new-bs-box
    :bs="{
      marginBottom: '20px',
      marginTop: '10px',
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
    }"
  >
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">

      <SearchInput
        v-model="state.filters.deviceName"
        action-position="inner"
        placeholder="根据设备名称搜索"
        @search="handleSearch"
      />
    </new-bs-box>
    <new-bs-box :bs="{ marginLeft: '20px', marginTop: '7px' }">
      <Button
        icon-before="add-m"
        @click="showCreateBrandModal({})"
      >新增设备</Button>

    </new-bs-box>

  </new-bs-box>
  <BeerTable
    class="data-table"
    size="s"
    border-style="none"
    :columns="state.columns"
    :data="state.deviceList"
  >
    <!-- <template #num="{ rowData }">
      <CountInput
        v-model="rowData.num"
        :bs="{ mt: 0 }"
        @update:modelValue="changeNum(rowData)"
      />
    </template> -->
    <template #action="{ rowData }">
      <Button
        size="s"
        modifier="danger"
        @click.stop="handleDelete(rowData)"
      >删除</Button>
    </template>

  </BeerTable>
  <div class="pagination-table">
    <Pagination
      v-model="state.pagination"
      align="flex-end"
      @update:modelValue="handlePagination"
    />
  </div>
  <EditBrand
    :show="brandVisible"
    :data="branddatainfo"
    :mode="brandmode"
    @close="hideBrandModal"
    @submit="handleBrandSubmit"
  />

</template>

<script setup lang="ts">
  import { reactive, onMounted } from 'vue'
  import {
    BeerTable,
    Button,
    NewBsBox,
    Pagination,
    toaster,
    SearchInput,
    showModal,
    // Popover,
  } from '@xhs/yam-beer'

  import { useBrandModalForm } from '../../composables/useBrandModalForm'
  import EditBrand from '../../components/Mange/EditBrand.vue'
  import {
    getBrandList,
    BrandAdd,
    deleteBrand,
  } from '../../services/Brand'
  //   import {
  //     getKey, ROLE_MAP, ROLE_OPTIONES,
  //   } from '~/utils/common'
  //   import { USERINFO } from '../../common'
  const {
    visible: brandVisible,
    data: branddatainfo,
    brandmode,
    isBrandEditMode,
    showCreateBrandModal,
    // showAwardEditModal,

    hideBrandModal,
  } = useBrandModalForm()

  type IFilterConfig = {
    page: number
    pageSize: number
    deviceName?: string
  }
  const state = reactive({
    filters: {
      deviceName: '',
    },

    columns: [
      {
        title: '序号',
        key: 'id',

      },
      {
        title: '设备中文名称',
        key: 'brandNameCh',
      },
      {
        title: '设备英文名称',
        key: 'brandNameEn',

      },

      {
        key: 'action',
        title: '操作',
        td: {
          is: 'action',
        },
      },

    ],
    deviceList: [],
    // deviceList : JSON.parse('[{"id":"aaaa","endTime":"2021-11-21","jobId":123,"jobName":"xxx","startTime":"2021-11-19","status":3,"version":"1.0.0"}]'),
    pagination: {
      pageNo: 1,
      pageSize: 10,
      total: 0,
    },

  })

  const handleSearch = async () => {
    const {
      deviceName,
    } = state.filters
    const params: IFilterConfig = {
      page: state.pagination.pageNo,
      pageSize: state.pagination.pageSize,
    }
    if (deviceName) params.deviceName = deviceName

    try {
      const res = await getBrandList(params)
      if (res.data.totalCount === 0) {
        toaster.warning('暂无数据！')
      }

      state.deviceList = res.data.deviceList // 后端进行分页
      //   console.log(JSON.stringify(state.brandList))
      state.pagination.total = res.data.totalCount
    } catch (error) {
      toaster.warning(error.message)
    }
  }

  const handlePagination = () => {
    handleSearch()
  }

  function handleBrandSubmit(data: any) {
    const {
      brandNameEn, brandNameCh,
    } = data

    const payload = {
      brandNameEn,
      brandNameCh,
    }
    // console.log(JSON.stringify(payload))
    // if (isAwardEditMode.value) {
    //   updateRole(payload)
    //     .then(res => {
    //       if (res.code === 200) {
    //         toaster.success('设置角色成功！')
    //         handleSearch()
    //         hideAwardModal()
    //       } else {
    //         toaster.warning(res.msg)
    //       }
    //     })
    //     .catch(err => toaster.warning(err))
    // }
    if (!isBrandEditMode.value) {
      BrandAdd(payload)
        .then((res:any) => {
          if (res.code === 200) {
            toaster.success('新增设备类型成功！')
            handleSearch()
            hideBrandModal()
          } else {
            toaster.warning(res.msg)
          }
        })
        .catch(err => toaster.warning(err))
    }
  }

  //   // 更新bug状态
  //   const changeNum = async (rowData: { id: any;num:any }) => {
  //     // toaster.notify(`clicked  -----${JSON.stringify(rowData.status)}`)

  //     const params = {
  //       awardId: rowData.id,
  //       num: rowData.num,
  //     }
  //     try {
  //       const res = await UpdateAwardNum(params)
  //       if (res.code === 200) {
  //         toaster.success('操作成功！')
  //         handleSearch()
  //       } else {
  //         toaster.warning(res.msg)
  //       }
  //     } catch (error) {
  //       toaster.warning(error.message)
  //     }
  //   }

  const handleDelete = async (rowData: { id: string }) => {
    const deviceId = rowData.id
    showModal({
      props: {
        type: 'dialog',
        title: '确定要删除该设备型号么？',
        async handleConfirm({ close }) {
          try {
            const res = await deleteBrand({ deviceId })
            if (res.code === 200) {
              toaster.success('删除成功')
              close()
              handleSearch()
            } else {
              toaster.warning(res.msg)
            }
          } catch (error) {
            toaster.warning(error.message)
          }
        },
      },
    })
  }

  //   watch([() => state.filters.deviceName], () => {
  //     handleSearch()
  //   })

  onMounted(() => {
    handleSearch()
  })
</script>

<style lang="stylus" scoped>
.data-table {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-table {
  margin-top: 24px;
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>
