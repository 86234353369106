<template>
  <NewBsBox :bs="{ marginTop: 10 }">
    <BeerPanel :bs="{ boxShadow: 'none', flex: 1, margin: 0, pt: 0 }">
      <BeerTitle :bs="{ mb: 16 }">{{ bugInfo.title }} </BeerTitle>
      <BeerDivider />
      <NewBsBox :bs="{ fontWeight: '600', margin: 10, padding: 10 }">
        <span style="white-space: pre-wrap">{{ bugInfo.bugInfo }}</span>
      </NewBsBox>
    </BeerPanel>
  </NewBsBox>
  <NewBsBox :bs="{ display: 'flex' }">
    <BeerPanel :bs="{ boxShadow: 'none', margin: 0, flex: 1 }">
      <BeerTitle :bs="{ mb: 16 }"> 基础信息 </BeerTitle>
      <NewBsBox
        :bs="{
          padding: 24,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }"
      >
        <NewBsBox :bs="{ flex: '33%' }">
          <NewBsBox :bs="{ pb: '13px' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >版本号:</NewBsBox>
            {{ bugInfo.version }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px', flex: '50%' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >提报人:</NewBsBox>
            {{
              bugInfo.reporter === null ? bugInfo.reporterId : bugInfo.reporter
            }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >提报人userid:</NewBsBox>
            {{ bugInfo.reporterId }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >提报时间:</NewBsBox>
            {{ bugInfo.createTime }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >deviceId:</NewBsBox>
            {{ bugInfo.deviceId }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >薯名:</NewBsBox>
            {{ (bugInfo.nickname === 'null(null)' ||bugInfo.nickname === '()')? '' : bugInfo.nickname }}
          </NewBsBox>
        </NewBsBox>
        <BeerDivider :vertical="true" />
        <NewBsBox :bs="{ flex: '33%', marginLeft: 15 }">
          <NewBsBox :bs="{ pb: '13px' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >任务类型:</NewBsBox>
            {{ bugInfo.platform }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px', flex: '50%' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >设备系统:</NewBsBox>
            {{ bugInfo.deviceSystem }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px', flex: '50%' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >手机品牌:</NewBsBox>
            {{ bugInfo.deviceBrand }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >业务方向:</NewBsBox>
            {{ bugInfo.priority }}
          </NewBsBox>
          <NewBsBox :bs="{ pb: '13px' }">
            <NewBsBox
              :bs="{ fontWeight: '600', display: 'inline' }"
            >严重等级:</NewBsBox>
            {{ bugInfo.level }}
          </NewBsBox>
        </NewBsBox>
      </NewBsBox>
    </BeerPanel>
  </NewBsBox>

  <BeerPanel :bs="{ boxShadow: 'none', flex: 1, margin: 0, pt: 0 }">
    <BeerTitle :bs="{ mb: 16 }">附件 </BeerTitle>
    <BeerDivider />
    <NewBsBox :bs="{ margin: 10, padding: 10 }">
      <div
        v-for="(item, index) in state.attachmentImageList"
        :key="`${index}`"
      >
        <a
          :href="item"
          target="_blank"
          @click.prevent="ImageClick(`${index}`)"
        >{{ item }}</a>
      </div>

      <div
        v-for="(item, index) in state.attachmentVideoList"
        :key="`${index}`"
      >
        <a
          :href="item"
          target="_blank"
          @click.prevent="VideoClick(`${index}`)"
        >{{ item.src }}</a>
      </div>
      <div
        v-for="(item, index) in state.attachmentFileList"
        :key="`${index}`"
      >
        <a
          :href="item"
          target="_blank"
        >{{ item }}</a>
      </div>
    </NewBsBox>
  </BeerPanel>
  <div style="display: inline; float: right; margin-right: 20px">
    <Button
      style="background-color: #ff6666"
      type="primary"
      :icon="{ icon: MessageEmoji, color: 'blue' }"
      @click="historyOpen"
    >历史消息</Button>
    <Drawer
      v-model:visible="state.historyVisible"
      outside-closeable
      title="历史消息"
    >
      <List :options="state.historyContent">
        <template #avatar="{ avatar }">
          <Avatar
            :src="avatar"
            size="extra-small"
          />
        </template>
        <template #title="{ nickname, createdAt }">
          <div>
            <div style="display: inline">
              <span style="font-size: 14px; font-weight: bold">
                {{ nickname }}</span>
            </div>
            <div style="display: inline; margin-left: 10px">
              <span style="font-size: 10px">{{ createdAt }}</span>
            </div>
          </div>
        </template>
        <template #description="{ description }">
          <NewBsBox
            v-if="
              description.indexOf('msg-video.xhscdn.com') != -1"
            :bs="{ width: '80%', mr: 20 }"
          >
            <a
              :href="description"
              target="_blank"
            >{{ description }}</a>
          </NewBsBox>
          <NewBsBox
            v-else-if="description.indexOf('msg-img.xhscdn.com') != -1"
            as="img"
            :src="description"
            :bs="{ width: '50%' }"
          />
          <NewBsBox
            v-else-if="description.indexOf('fe-video-qc.xhscdn.com') != -1"
            as="img"
            :src="description"
            :bs="{ width: '10%' }"
          />

          <Text v-else>
            {{ description }}
          </Text>
        </template>
      </List>
    </Drawer>
  </div>
  <div style="display: inline; float: right; margin-right: 20px">
    <Popconfirm
      v-model:visible="state.visible"
      :outside-closeable="true"
      confirm-text="发送"
      @confirm="handleSend"
      @cancel="handleClose"
    >
      <template #title>
        <Text
          :icon="{ icon: User, theme: 'filled', color: 'red-6' }"
          type="h5"
          bold
        >
          {{ bugInfo.reporter }}
        </Text>
      </template>
      <template #description>
        <TextArea
          v-model:modelValue="state.content"
          style="height: 300px"
          placeholder="请输入..."
          :max-length="200"
        />
      </template>
      <Button
        type="primary"
        :icon="{ icon: EmailPush, theme: 'filled', color: 'blue' }"
        @click="handleOpen"
      >发送消息</Button>
    </Popconfirm>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, reactive } from 'vue'
  import { useRoute } from 'vue-router'
  import {
    Button,
    Popconfirm,
    Text,
    TextArea,
    Drawer,
    List,
    Avatar,
  } from '@xhs/delight'
  import { User, EmailPush, MessageEmoji } from '@xhs/delight/icons'

  import {
    NewBsBox,
    BeerPanel,
    BeerTitle,
    BeerDivider,
    viewImgs,
    showVideoList,
    toaster,
  } from '@xhs/yam-beer'

  import { getBugInfo, pushContent, chatHistory } from '../../services/Bug'
  import type { BugInfo } from '../../services/Bug'

  import {
    TYPE_MAP,
    getKey,
    datetimeFormat,
    LEVEL_OPTIONES,
    PRIORITY_OPTIONES,
    getDataTime,
  } from '~/utils/common'
  // import { useModal } from '~/composables/useModal'

  const route = useRoute()
  const id = Number(route.params.id)

  const bugInfo = reactive<BugInfo>({
    title: '',
    version: '',
    platform: 0,
    bugInfo: '',
    deviceSystem: '',
    attachmentList: [],
    reporter: '',
    reporterId: '',
    createTime: '',
    level: 0,
    priority: 0,
  })

  const state: any = reactive({
    attachmentImageList: [],
    attachmentImageViewList: [],
    attachmentVideoList: [],
    attachmentFileList: [],
    visible: false,
    content: '',
    historyVisible: false,
    historyContent: [],
  })

  function checkisimg(url: any) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null
  }
  function checkisvideo(url: any) {
    return url.match(/\.(mp4)$/) != null
  }

  const initBugInfo = async () => {
    const res = await getBugInfo({ id })
    Object.assign(bugInfo, res)
    // 处理一下任务类型显示
    bugInfo.platform = getKey(TYPE_MAP, bugInfo.platform)
    bugInfo.createTime = datetimeFormat(String(bugInfo.createTime))
    bugInfo.level = getKey(LEVEL_OPTIONES, bugInfo.level)
    bugInfo.priority = getKey(PRIORITY_OPTIONES, bugInfo.priority)

    bugInfo.attachmentList.forEach(element => {
      if (checkisimg(element)) {
        state.attachmentImageList.push(element)

        state.attachmentImageViewList.push(String(element).split(':')[0])
      } else if (checkisvideo(element)) {
        state.attachmentVideoList.push({ src: element })
      } else {
        state.attachmentFileList.push(element)
      }
    })
  }

  const ImageClick = (index: any) => viewImgs(state.attachmentImageList, { initialViewIndex: index })
  function VideoClick(index: any) {
    showVideoList(state.attachmentVideoList, index)
  }

  function getchatHistory() {
    const payload = {
      chatUid: '617a8d49000000001f03b1bf',
      uid: bugInfo.reporterId,
    // limit: '10',
    // storeId: '10',
    }
    chatHistory(payload).then(res => {
      if (res.code !== 200) {
        toaster.danger(res.msg)
      } else {
        state.historyContent = []
        if (res.data.length !== 0) {
          res.data.forEach((element: any) => {
            if (element.messageType === 'TEXT') {
              state.historyContent.push({
                title: element.senderId,
                description: element.content,
                nickname: element.nickname,
                createdAt: getDataTime(element.createdAt),
                avatar: element.avatar,
                avatarAlign: 'start',
              })
            } else if (element.messageType === 'IMAGE') {
              state.historyContent.push({
                title: element.senderId,
                description: element.imgToJson.link,
                // description: 'https://fe-video-qc.xhscdn.com/fe-platform/222affe21c8be521b8431df42212ef637aa943a3.png',
                nickname: element.nickname,
                createdAt: getDataTime(element.createdAt),
                avatar: element.avatar,
                avatarAlign: 'start',
              // actions:[
              //   { name: '查看', onClick: () => viewImgs(['https://msg-img.xhscdn.com/01026p016k7akf5lsy20e10yyit0knogdd?imageView2/2/format/jpg']) }
              // ]
              })
            } else if (element.messageType === 'EMOTICON') {
              state.historyContent.push({
                title: element.senderId,
                description:
                  element.imgToJson.image === ''
                    ? element.imgToJson.emojiKey
                    : element.imgToJson.image,
                // description: 'https://fe-video-qc.xhscdn.com/fe-platform/222affe21c8be521b8431df42212ef637aa943a3.png',
                nickname: element.nickname,
                createdAt: getDataTime(element.createdAt),
                avatar: element.avatar,
                avatarAlign: 'start',
              })
            } else if (element.messageType === 'VIDEO') {
              state.historyContent.push({
                title: element.senderId,
                description: element.imgToJson.videoLink,
                // description: '{"duration":19000,"size":{"width":886,"height":1920},"link":"https://msg-img.xhscdn.com/1000g07o2ci6j6vggo0005ohf9okk16d6okdiapo?imageView2/2/format/jpg","videoLink":"https://msg-video.xhscdn.com/video/1000g0c02ci6j6vcgo0005ohf9okk16d6slvu71o","videoSize":40415926}',
                nickname: element.nickname,
                createdAt: getDataTime(element.createdAt),
                avatar: element.avatar,
                avatarAlign: 'start',
              })
            }

          // state.historyContent.push({
          //   title:element.senderId,
          //   description:element.content
          // })
          })
        } else {
          toaster.danger('暂无对话记录！')
        }
      }
    })

  // bugInfo.attachmentList.forEach(element => {
  //   if (checkisimg(element)) {
  //     state.attachmentImageList.push(element)

  //     state.attachmentImageViewList.push(String(element).split(':')[0])
  //   } else if (checkisvideo(element)) {
  //     state.attachmentVideoList.push({ src: element })
  //   } else {
  //     state.attachmentFileList.push(element)
  //   }
  // })
  }

  // historyOpen
  function historyOpen() {
    state.historyVisible = true
    getchatHistory()
  }

  function handleOpen() {
    state.visible = true
  }

  function handleClose() {
    state.visible = false
  }

  function handleSend() {
    // 发送接口
    const payload = {
      toUser: bugInfo.reporterId,
      content: state.content,
    }
    pushContent(payload)
      .then(res => {
        if (res.code !== 200) {
          toaster.danger(res.msg)
        } else {
          state.content = ''
          toaster.success(res.msg)
          state.visible = false
        }
      })
      .catch(err => toaster.warning(err))
  }

  onMounted(() => {
    initBugInfo()
  })
</script>
