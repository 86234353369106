<template>
  <BeerModal
    :title="whitetitle"
    :show="show"
    :bs="{
      width: 800,
    }"
    :handle-close="handleCancel"
    :handle-confirm="handleWhiteSubmit"
    :confirm-props="{
      disabled: !canSubmit,
    }"
  >
    <BeerForm
      ref="basicForm"
      v-model="basicFormData"
      :config="basicFormConfig"
      :submit="() => {}"
    >
      <template #submit-button><div /></template>
      <template #reset-button><div /></template>
    </BeerForm>
  </BeerModal>
</template>

<script setup lang="ts">
  import {
    defineProps,
    defineEmits,
    ref,
    reactive,
    computed,
    toRefs,
    watch,
    unref,
  } from 'vue'
  import { BeerModal } from '@xhs/yam-beer'
  import BeerForm from '@xhs/beer-formily'

  const props = defineProps<{
    show: boolean
    data: any
    mode: string
  }>()

  const emit = defineEmits(['close', 'submit'])
  const basicFormData = ref({} as any)
  const basicForm = ref<any>()
  const whitetitle = '白名单信息'

  const { canSubmit, basicFormConfig } = toRefs(
    reactive({
      canSubmit: true,
      basicFormConfig: computed(() => ({
        children: [
          {
            name: 'groupId',
            type: 'string',
            label: 'groupId',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              props: {
                bs: {
                  width: '400px',
                  placeholder: '请输入groupId',
                },
              },
            },
          },
          {
            name: 'groupName',
            type: 'string',
            label: 'groupName',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              props: {
                bs: {
                  width: '400px',
                  placeholder: '请输入groupName',
                },
              },
            },
          },
          {
            name: 'whiteGroup',
            type: 'string',
            label: 'whiteGroup',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              props: {
                bs: {
                  width: '400px',
                  placeholder: '请输入whiteGroup',
                },
              },
            },
          },

        ],
      })),
    }),
  )

  function init() {
    const initWhiteData = Object.keys(props.data).length ? props.data : {}
    basicFormData.value = {
      groupId: initWhiteData.groupId || '',
      groupName: initWhiteData.groupName || '',
      whiteGroup: initWhiteData.whiteGroup || '',
      id: initWhiteData.id || '',
    }
    // console.log("initWhiteData")
    // console.log(basicFormData.value)
  }

  watch(
    () => props.show,
    () => {
      init()
    },
  )

  function handleCancel() {
    init()
    emit('close')
  }

  function handleWhiteSubmit() {
    // 提交数据前处理一下

    basicForm?.value?.validate().then(() => {
      emit('submit', {
        ...unref(basicFormData),
      })
    })
  }
</script>
