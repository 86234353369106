import { http } from '@xhs/launcher'

interface IParams {
  pageNo: number
  pageSize: number
}

interface IData {
  id: number
  title1: string
  title2: string
  title3: string
}

let data: IData[] = []
let total1: number = 0

export async function getTable(params: IParams): Promise<{list:IData[]; total: number}> {
  if (total1) {
    return { list: data, total: total1 }
  }
  const { list, total } = await http.get('TABLE', { params })
  data = list
  total1 = total
  return { list, total }
}

export function createTable(rowData: Omit<IData, 'id'>): Promise<{list:IData[]; total: number}> {
  // return http.post('TABLE', rowData)

  data.unshift({
    id: data.length + 1,
    ...rowData,
  })
  total1 += 1

  return Promise.resolve({ list: data, total: total1 })
}

export function updateTable(rowData: IData): Promise<{list:IData[]; total: number}> {
  // return http.put('TABLE', rowData)

  const index = data.findIndex(row => row.id === rowData.id)

  data.splice(index, 1, rowData)

  return Promise.resolve({ list: data, total: total1 })
}

export function removeTable(rowData: IData): Promise<{list:IData[]; total: number}> {
  data = data.filter(row => row.id !== rowData.id)
  total1 -= 1
  return Promise.resolve({ list: data, total: total1 })
}
