<template>
  <BeerModal
    :title="title"
    :show="show"
    :bs="{
      width: 800,
    }"
    :handle-close="handleCancel"
    :handle-confirm="handleSubmit"
    :confirm-props="{
      disabled: !canSubmit,
    }"
  >
    <BeerForm
      ref="basicForm"
      v-model="basicFormData"
      :config="basicFormConfig"
      :submit="() => {}"
      @update:start="test()"
    >
      <template #submit-button><div /></template>
      <template #reset-button><div /></template>
    </BeerForm>
  </BeerModal>
</template>

<script setup lang="ts">
  import {
    defineProps,
    defineEmits,
    ref,
    reactive,
    computed,
    toRefs,
    watch,
    unref,
  } from 'vue'
  import { BeerModal, toaster } from '@xhs/yam-beer'
  import BeerForm from '@xhs/beer-formily'
  import dayjs from 'dayjs'
  // import { useStore } from 'vuex'
  // import { formatDate } from '~/utils/common'
  import { USERINFO } from '../../common'

  const props = defineProps<{
    show: boolean
    data: any
    mode: string
  }>()
  // const store = useStore()
  const emit = defineEmits(['close', 'submit'])
  const basicFormData = ref({} as any)
  const basicForm = ref<any>()
  const editMode = computed(() => props.mode === 'edit')
  const title = computed(() => (editMode.value ? '编辑任务' : '新建任务'))
  const { canSubmit, basicFormConfig } = toRefs(
    reactive({
      canSubmit: true,
      basicFormConfig: computed(() => ({
        children: [
          {
            name: 'type',
            type: 'number',
            label: '任务类型',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              is: 'singleSelect',
              options: [
                { name: 'Android', value: 1 },
                { name: 'iOS', value: 2 },
                { name: 'web', value: 3 },
                { name: 'H5', value: 4 },
              ],
              props: {
                bs: {
                  width: '400px',
                },
              },
            // modelValue: 1,
            },
          },
          {
            name: 'jobName',
            type: 'string',
            label: '任务名称',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              props: {
                bs: {
                  width: '400px',
                },
              },
            },
          },
          {
            name: 'version',
            type: 'string',
            label: '版本号',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              props: {
                bs: {
                  width: '400px',
                },
              },
            },
          },
          {
            name: 'testTime',
            label: '测试期限',

            component: {
              is: 'DateRangePicker',
              props: {
                bs: {
                  width: '400px',
                },
              },
              disableDay: (day: any) => day < dayjs().subtract(1, 'd'),

            },
            required: true,
            formItem: {
              isHorizontal: true,
            },
          },
          {
            name: 'jobInfo',
            label: '产品介绍',
            required: true,
            component: {
              is: 'textArea',
              props: {
                minRows: 9,
                placeholder: '请输入产品介绍',
                maxlength: 500,
              },

            },
            formItem: {
              isHorizontal: true,
            },
          },
          {
            name: 'jobUrl',
            type: 'string',
            label: 'job地址',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              props: {
                bs: {
                  width: '400px',
                },
              },
            },
          },
          {
            name: 'buildId',
            type: 'string',
            label: 'buildId',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              props: {
                bs: {
                  width: '400px',
                },
              },
            },
          },
          {
            name: 'whiteId',
            label: '白名单',
            component: {
              is: 'ComboInput',
              props: {
                bs: {
                  width: '400px',
                },
              },
              max: 50,
            },
            required: true,
            formItem: {
              isHorizontal: true,
            },
          },
        ],
      })),
    }),
  )

  function init() {
    const initData = Object.keys(props.data).length ? props.data : {}
    basicFormData.value = {
      jobName: initData.jobName || '',
      version: initData.version || '',
    }

    if (initData.testTime) {
      basicFormData.value.testTime = {
        start: initData.testTime.start,
        end: initData.testTime.end,
      }
    }
    if (initData.jobInfo) {
      basicFormData.value.jobInfo = initData.jobInfo
    }
    if (initData.jobUrl) {
      basicFormData.value.jobUrl = initData.jobUrl
    }
    if (initData.buildId) {
      basicFormData.value.buildId = initData.buildId
    }
    if (initData.whiteId) {
      basicFormData.value.whiteId = initData.whiteId
    }
    // console.log("initdata:",initData)

    if (initData.type) {
      basicFormData.value.type = initData.type
    }

    if (initData.id) {
      basicFormData.value.id = initData.id
    }
  }

  watch(
    () => props.show,
    () => {
      init()
    },
  )

  function handleCancel() {
    init()
    emit('close')
  }
  function test() {
    // alert('eeeee')
  }

  function handleSubmit() {
    // 创建人mock
    // basicFormData.value.creator = store.state.Auth.userInfo.nickName
    basicFormData.value.creatorId = USERINFO.userId

    // window.console.log(basicFormData.value.testTime)
    // 提交数据前处理一下测试开始结束时间
    if (basicFormData.value.testTime !== undefined) {
      basicFormData.value.startTime = basicFormData.value.testTime.start
      basicFormData.value.endTime = basicFormData.value.testTime.end
    }
    if (basicFormData.value.startTime === null || basicFormData.value.endTime === null) {
      toaster.warning('请输入测试时间！')
    }

    basicForm?.value?.validate().then(() => {
      emit('submit', {
        ...unref(basicFormData),
      })
    })
  }
</script>
