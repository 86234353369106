<template>
  <BeerModal
    :title="bugtitle"
    :show="show"
    :bs="{
      width: 800,
    }"
    :handle-close="handleCancel"
    :handle-confirm="handleBugSubmit"
    :confirm-props="{
      disabled: !canSubmit,
    }"
  >
    <BeerForm
      ref="basicForm"
      v-model="basicFormData"
      :config="basicFormConfig"
      :submit="() => {}"
    >
      <template #items="{ list }">
        <div
          v-for="item of list"
          :key="item.id"
          class="item-box"
        >
          <FileItem
            :readonly="item.uploadPercent === 100"
            :handle-remove="handleRemove(item)"
            :upload-percent="item.uploadPercent"
            :handle-click="() => handleFileClick(item)"
            :filename="item.url || item.path || item.filename"
            :error="item.error"
            :src="item.url"
          />
        </div>
      </template>
      <template #submit-button><div /></template>
      <template #reset-button><div /></template>
    </BeerForm>
  </BeerModal>
</template>

<script setup lang="ts">
  import {
    defineProps,
    defineEmits,
    ref,
    reactive,
    computed,
    toRefs,
    watch,
    unref,
  } from 'vue'
  import { BeerModal, FileItem } from '@xhs/yam-beer'
  import BeerForm from '@xhs/beer-formily'
  // import dayjs from 'dayjs'
  import { getDeviceNameList } from '../../services/Brand'

  const props = defineProps<{
    show: boolean
    data: any
    mode: string
  }>()

  const emit = defineEmits(['close', 'submit'])
  const basicFormData = ref({} as any)
  const basicForm = ref<any>()
  const bugtitle = '创建缺陷'
  const newcdnUrls: string[] = []
  const deviceData = reactive({
    deviceList: [],
  })

  const { canSubmit, basicFormConfig } = toRefs(
    reactive({
      canSubmit: true,
      basicFormConfig: computed(() => ({
        children: [
          {
            name: 'title',
            type: 'string',
            label: '问题标题',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              props: {
                bs: {
                  width: '400px',
                  placeholder: '请简单描述问题',
                },
              },
            },
          },
          {
            name: 'bugInfo',
            label: '操作步骤',
            required: true,
            component: {
              is: 'textArea',
              props: {
                minRows: 5,
                placeholder: '请输入操作步骤',
                maxlength: 500,
              },
            },

            formItem: {
              isHorizontal: true,
            },
          },
          {
            name: 'isRecur',
            type: 'number',
            label: '是否必现',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              is: 'radioGroup',
              options: [
                { value: 1, name: '否' },
                { value: 2, name: '是' },
              ],
            },
          },
          // {
          //   name: 'platform',
          //   type: 'string',
          //   label: '软件平台',
          //   required: true,
          //   formItem: {
          //     isHorizontal: true,
          //   },
          //   component: {
          //     is: 'singleSelect',
          //     options: [
          //       { name: 'Android', value: '1' },
          //       { name: 'iOS', value: '2' },
          //       { name: 'web', value: '3' },
          //       { name: 'H5', value: '4' },
          //     ],
          //     props: {
          //       bs: {
          //         width: '400px',
          //       },
          //     },
          //   },
          // },
          {
            name: 'deviceBrand',
            type: 'string',
            label: '手机设备',
            // required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              is: 'singleSelect',
              options: deviceData.deviceList,
              props: {
                bs: {
                  width: '400px',
                },
                filterable: true,
              },
            },
          },
          {
            name: 'deviceSystem',
            type: 'string',
            label: '手机系统',
            required: true,
            formItem: {
              isHorizontal: true,
            },
            component: {
              props: {
                bs: {
                  width: '400px',
                },
              },
            },
          },

          {
            name: 'fileUploaderValue',
            label: '上传附件',
            formItem: {
              isHorizontal: true,
            },
            component: {
              is: 'FileUploader',
              bucket: 'picasso', // 公用
              bizKey: 'testin-fe', // package.json的name
              maxFiles: 8,
            // modelValue:newcdnUrls,
            // handlePostSuccess: (params:any) => {
            // },
            },
          },
        ],
      })),
    }),
  )

  const getDeviceList = async () => {
    const res = await getDeviceNameList()
    if (res.code === 200) {
      deviceData.deviceList = res.data
    }
  }

  function init() {
    const initBugData = Object.keys(props.data).length ? props.data : {}
    basicFormData.value = {
      title: initBugData.title || '',
      bugInfo: initBugData.bugInfo || '',
      isRecur: initBugData.isRecur || '',
      // platform: initBugData.bugInfo || '',
      deviceSystem: initBugData.deviceSystem || '',
      deviceBrand: initBugData.deviceBrand || '', // 添加设备字段
    }
    if (initBugData.version) {
      basicFormData.value.version = initBugData.version
    }
    if (initBugData.jobId) {
      basicFormData.value.jobId = initBugData.jobId
    }
    if (initBugData.platform) {
      basicFormData.value.platform = initBugData.platform
    }
    // 提交bug时还需要带报告人

    // window.console.log("initBugData.deviceBrand:"+basicFormData.value.deviceBrand)
    // window.console.log("initBugData.deviceBrand----:"+basicFormData.value.deviceSystem)

    // 获取设备列表接口/api/testin/device/getDeviceNameList
    getDeviceList()
  }

  watch(
    () => props.show,
    () => {
      init()
    },
  )

  function handleCancel() {
    init()
    emit('close')
  }

  function handleBugSubmit() {
    // 提交数据前处理一下
    if (basicFormData.value.fileUploaderValue !== undefined) {
      basicFormData.value.fileUploaderValue.forEach((item: any) => {
        newcdnUrls.push(item.url)
      })
      basicFormData.value.bugfileList = newcdnUrls
    }
    // window.console.log("xuanxxxxxxx:"+basicFormData.value.deviceBrand)

    // //处理提交的devicebrand字段
    // if (basicFormData.value.deviceBrand !== undefined) {
    //   basicFormData.value.deviceBrand = basicFormData.value.deviceBrand
    // }

    basicForm?.value?.validate().then(() => {
      emit('submit', {
        ...unref(basicFormData),
      })
    })
  }
</script>
