import { http } from '@xhs/launcher'
// import { List } from 'echarts'

export type JobInfo = {
  jobName: string
  version: string
  type: number
  jobInfo: string
  startTime: string
  endTime: string
  jobUrl: string
  typeValue:string
  buildId:string
  whiteId: []
}

// 任务名接口
export function getjobnameList(): Promise<any> {
  return http.get('JOB_NAMELIST')
}

// 任务列表
export function getJobList(payload: {
  page: number
  pageSize: number
}): Promise<any> {
  return http.post('JOB_LIST', payload)
}
// 获取任务详情
export function getJobInfo(payload: {
  id: number
}): Promise<any> {
  return http.get('JOB_INFO', {
    params: payload,
  })
}

export function getList(): Promise<any> {
  return http.get('JOB_TEST', {})
}

// 任务列表获取版本号下拉数据
export function getVersionList(): Promise<any> {
  return http.get('VERSION_LIST')
}

// // headers
// export function getVersionList(): Promise<any> {
//   return http.get('VERSION_LIST',{
//     headers:''
//   })
// }

// 新建任务
export function postApiJob(payload: {
  jobName: string
  version: string
}): Promise<any> {
  return http.post('JOB_ADD', payload, {
    extractData: false,
  })
}

// 编辑任务
export function postApiJobById(payload: {
  // jobName: string
  // version: string
}): Promise<any> {
  return http.post('JOB_UPDATE', payload)
}

// 删除任务
export function deleteJob(payload: {
  jobId: string
  operatorId:string
}): Promise<any> {
  return http.post('JOB_DELETE', payload, {
    extractData: false,
  })
}
// 更新任务状态
export function updateStatus(payload: {
  jobId: string
  status: number
  operatorId:string
}): Promise<any> {
  return http.post('UPDATE_STATUS', payload, {
    extractData: false,
  })
}
// 群发任务
export function pushgroup(payload: {
  jobid: number
  groupid:number
}): Promise<any> {
  return http.get('PUSH_GROUP', {
    params: payload,
  })
}

// Dashboard
export function StatInfo(payload: {
  type: number
  startTime:string
  endTime:string
}): Promise<any> {
  return http.post('STAT_INFO', payload, {
    extractData: false,
  })
}

// StatBug
export function GetStatBug(payload: {
  type: number
  startTime:string
  endTime:string
}): Promise<any> {
  return http.post('GETSTAT_BUG', payload, {
    extractData: false,
  })
}

// //mocktest
// export function mocktest(): Promise<any> {
//   return http.get('MOCKTEST', {
//     extractData:false
//   })
// }
// // 任务列表
// export function getJobList(): Promise<any> {
//   return http.get('JOB_LIST_MOCK')
// }

// export function getJobInfo(payload: {
//   id: number
// }): Promise<any> {
//   return http.get('JOB_INFO_MOCK', {
//     params: payload,
//   })
// }

// // Dashboard
// export function StatInfo(payload: {
//   type: number
//   startTime:string
//   endTime:string
// }): Promise<any> {
//   return http.post('STAT_INFO_MOCK', payload, {
//     extractData: false,
//   })
// }

// // StatBug
// export function GetStatBug(payload: {
//   type: number
//   startTime:string
//   endTime:string
// }): Promise<any> {
//   return http.post('GETSTAT_BUG_MOCK', payload, {
//     extractData: false,
//   })
// }
