import { ActionTree, MutationTree, Module } from 'vuex'
import { IRootState } from '@xhs/launcher-plugin-store'

import { IUserState, IUserInfo } from '../types'

import { getUserInfo } from '~/services/user'

const stateInit: IUserState = {
  userInfo: {
    name: '前端署',
    userName: 'fe',
    avatar: 'https://img.xiaohongshu.com/avatar/5e88b911294b5100012982d3.jpg@80w_80h_90q_1e_1c_1x.jpg',
    email: 'fe@xiaohongshu.com',
    token: 'string',
    userId: 'string',
  },
}

const mutations: MutationTree<IUserState> = {
  setUserInfo(state, userInfo: IUserInfo) {
    if (userInfo) {
      state.userInfo = userInfo
    } else {
      state.userInfo = null
    }
  },
}

const actions: ActionTree<IUserState, IRootState> = {
  async login({ commit }) {
    try {
      const userInfo = await getUserInfo()
      commit('setUserInfo', userInfo)
    } catch (e) {
      commit('setUserInfo', null)
    }
  },
}

const userModule: Module<IUserState, IRootState> = {
  namespaced: true,
  state: stateInit,
  actions,
  mutations,
}

export default userModule
